import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button } from "@chakra-ui/react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import { AuthWrapper, Password } from "../../components/auth";
import config from "../../config";
import { useValidPassword } from "../../hooks/useAuthHooks";
import { AuthContext } from "../../utils/authContext";

const bgImgUrl = config.Background.change;

export default function ChangePassword() {
  const [error, setError] = useState("");
  const [reset, setReset] = useState(false);

  const {
    password: oldPassword,
    setPassword: setOldPassword,
    passwordIsValid: oldPasswordIsValid,
  } = useValidPassword("");

  const {
    password: newPassword,
    setPassword: setNewPassword,
    passwordIsValid: newPasswordIsValid,
  } = useValidPassword("");

  const isValid =
    !oldPasswordIsValid ||
    oldPassword.length === 0 ||
    !newPasswordIsValid ||
    newPassword.length === 0;

  const navigate = useNavigate();

  const authContext = useContext(AuthContext);

  const changePassword = async () => {
    try {
      await authContext.changePassword(oldPassword, newPassword);
      setReset(true);
    } catch (err: any) {
      setError(err.message);
    }
  };

  const signOut = async () => {
    try {
      await authContext.signOut();
      navigate("/");
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message);
      }
    }
  };

  const updatePassword = (
    <>
      <Box width="80%" m={1}>
        <Password
          label="Stare hasło"
          passwordIsValid={oldPasswordIsValid}
          setPassword={setOldPassword}
        />
      </Box>
      <Box width="80%" m={1}>
        <Password
          label="Nowe hasło"
          passwordIsValid={newPasswordIsValid}
          setPassword={setNewPassword}
        />
      </Box>
      {/* Error */}
      <Box mt={2}>
        <Typography color="error" variant="body2">
          {error}
        </Typography>
      </Box>

      {/* Buttons */}
      <Box mt={2}>
        <Grid container direction="row" justify="center">
          <Box m={1}>
            <Button onClick={() => navigate(-1)}>Wróć</Button>
          </Box>
          <Box m={1}>
            <Button
              disabled={isValid}
              colorScheme="blue"
              onClick={changePassword}
            >
              Zmień hasło
            </Button>
          </Box>
        </Grid>
      </Box>
    </>
  );

  const passwordReset = (
    <>
      <Box m={4}>
        <Button onClick={signOut} colorScheme="blue">
          Zaloguj się
        </Button>
      </Box>
    </>
  );

  const title = reset ? `Zmieniono hasło` : "Zmień hasło";

  return (
    <AuthWrapper bgImgUrl={bgImgUrl} title={title as string}>
      {!reset ? updatePassword : passwordReset}
    </AuthWrapper>
  );
}

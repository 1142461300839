import { Box, Checkbox, Divider, HStack, Stack, Text } from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import _ from "lodash";

import {
  campAgreement,
  insuranceAgreement,
} from "../../../../components/agreements";
import { Col, Row } from "../../../../components/paljs";
import { CartDiscount, OrderItemDiscount } from "../../../../types";

const shirtSizeOptions = [
  { value: "110", label: "110 cm/4 lata" },
  { value: "122", label: "122 cm/6 lat" },
  { value: "134", label: "134 cm/8 lat" },
  { value: "146", label: "146 cm/10 lat" },
  { value: "158", label: "158 cm/ 12 lat" },
  { value: "S", label: "S" },
  { value: "M", label: "M" },
  { value: "L", label: "L" },
  { value: "XL", label: "XL" },
  { value: "XXL", label: "XXL" },
];

type AdditionalInfoProps = {
  agreementUrl?: string;
  location?: string;
  isEditable?: boolean;
  setChurchConsent?: (bool: boolean) => void;
  churchConsent?: boolean;
  setCampConsent?: (bool: boolean) => void;
  campConsent?: boolean;
  setInsuranceConsent?: (bool: boolean) => void;
  insuranceConsent?: boolean;
  setShirtSize?: (size: string) => void;
  shirtSize?: string;
  discount?: CartDiscount | OrderItemDiscount;
};
const AdditionalInfo = ({
  agreementUrl,
  location,
  isEditable,
  setChurchConsent,
  churchConsent,
  setInsuranceConsent,
  insuranceConsent,
  setCampConsent,
  campConsent,
  setShirtSize,
  shirtSize,
}: AdditionalInfoProps) => {
  const ShirtSelect = () => (
    <Select
      menuPortalTarget={document.getElementById("root")}
      menuPosition="fixed"
      size="sm"
      classNamePrefix="multiselect"
      isInvalid={!shirtSize}
      className={"fullOpacity"}
      placeholder="Wybierz opcję"
      isDisabled={!isEditable}
      onChange={(values: any) => {
        setShirtSize && setShirtSize(_.get(values, "value"));
      }}
      value={
        shirtSize
          ? _.find(shirtSizeOptions, ({ value }) => value === shirtSize)
          : null
      }
      options={shirtSizeOptions}
    ></Select>
  );
  const CampAgreementCheckbox = () => (
    <Checkbox
      isChecked={!!campConsent}
      isInvalid={!campConsent}
      onChange={() => {
        setCampConsent && setCampConsent(!campConsent);
      }}
      isDisabled={!isEditable}
    ></Checkbox>
  );
  const InsuranceAgreementCheckbox = () => (
    <Checkbox
      isChecked={!!insuranceConsent}
      isInvalid={!insuranceConsent}
      onChange={() => {
        setInsuranceConsent && setInsuranceConsent(!insuranceConsent);
      }}
      isDisabled={!isEditable}
    ></Checkbox>
  );
  const PositiveChurchConsentCheckbox = () => (
    <Checkbox
      isChecked={
        churchConsent === true || churchConsent === false
          ? churchConsent
          : undefined
      }
      isInvalid={churchConsent === undefined}
      className="checkbox"
      onChange={() => {
        setChurchConsent && setChurchConsent(true);
      }}
      isDisabled={!isEditable}
      mr={5}
    >
      <Text fontSize="sm">Wyrażam zgodę</Text>
    </Checkbox>
  );
  const NegativeChurchConsentCheckbox = () => (
    <Checkbox
      isChecked={
        churchConsent === true || churchConsent === false
          ? !churchConsent
          : undefined
      }
      isInvalid={churchConsent === undefined}
      className="checkbox"
      onChange={() => {
        setChurchConsent && setChurchConsent(false);
      }}
      isDisabled={!isEditable}
    >
      <Text fontSize="sm">Nie wyrażam zgody</Text>
    </Checkbox>
  );

  return (
    <>
      <Row className={isEditable ? "greyBg" : ""}>
        <Col
          breakPoint={{ sm: 12 }}
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: 8,
          }}
        >
          <HStack>
            <Text fontSize="sm" className="norightmargin" mr={3}>
              Rozmiar koszulki <span style={{ color: "red" }}>*</span>
            </Text>
            <Box width="220px" bg={"white"} className={"fullOpacity"}>
              <ShirtSelect />
            </Box>
          </HStack>
        </Col>
        <Col
          breakPoint={{ sm: 12 }}
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: 8,
          }}
        >
          <Stack>
            <HStack>
              <CampAgreementCheckbox />
              <Text fontSize="sm">
                {campAgreement(location || "", agreementUrl || "")}
                <span style={{ color: "red" }}>*</span>
              </Text>
            </HStack>

            <HStack>
              <InsuranceAgreementCheckbox />
              <Text fontSize="sm">
                {insuranceAgreement()}
                <span style={{ color: "red" }}>*</span>
              </Text>
            </HStack>
          </Stack>
        </Col>
        <Col
          breakPoint={{ sm: 12 }}
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Text fontSize="sm">
            Zgoda na udział dziecka w niedzielnej mszy w kościele:
            <span style={{ color: "red" }}>*</span>
          </Text>
          <HStack mt={1} mb={2}>
            <PositiveChurchConsentCheckbox />
            <NegativeChurchConsentCheckbox />
          </HStack>
          <div>
            <small style={{ fontSize: "0.6rem" }}>
              {" "}
              <span style={{ color: "red" }}>*</span> zgody i wybory wymagane
            </small>
          </div>
        </Col>
      </Row>
      <Divider my={3} variant={"dashed"} />
    </>
  );
};

export default AdditionalInfo;

/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import { Box, Button, Flex, Select, Text } from "@chakra-ui/react";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import _ from "lodash";

export const toastContent = (navigate: any) => ({
  title: "Zapis dodany do koszyka!",
  description: (
    <>
      <br />
      <Button
        fontSize={"1.4rem"}
        color="white"
        variant="link"
        leftIcon={<ShoppingCartIcon />}
        onClick={() => navigate("/cart")}
      >
        Kliknij tutaj aby przejść do koszyka
      </Button>
      <br />
    </>
  ),
  status: "success",
  duration: 5000,
  isClosable: true,
  size: "xl",
  containerStyle: {
    fontSize: "1.8rem",
  },
});

export const errorToastContent = () => ({
  title: "Brak miejsc!",
  description: (
    <>
      <br />
      Nie dodano zapisu do koszyka. <br />
      Spróbuj odświeżyć stronę i wybrać inny turnus
      <br />
    </>
  ),
  status: "error",
  duration: 5000,
  isClosable: true,
  size: "xl",
  containerStyle: {
    fontSize: "1.4rem",
  },
});

type FormRowProps = {
  header: string;
  children: any;
};
const FormRow = ({ header, children }: FormRowProps) => {
  return (
    <>
      <Box my={3}>
        <Text as="b">{header}</Text>
      </Box>
      <Flex align="center">
        <Box mr={3}>{children}</Box>
      </Flex>
    </>
  );
};

type SummerSelectProps = {
  isDisabled: boolean;
  value: string;
  setValue: (name: string) => void;
  options: { value: string; label: string }[];
  header: string;
  placeholder: string;
};

export const SummerSelect = ({
  isDisabled,
  value,
  setValue,
  options,
  header,
  placeholder,
}: SummerSelectProps) => {
  return (
    <FormRow header={header}>
      <Select
        size="sm"
        isDisabled={isDisabled}
        placeholder={placeholder}
        value={value || ""}
        onChange={(e) => setValue(e.target.value)}
      >
        {_.map(options, (period) => (
          <option value={period.value} key={period.value}>
            {period.label}
          </option>
        ))}
      </Select>
    </FormRow>
  );
};

import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Box, Button } from "@chakra-ui/react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import { AuthWrapper, Code, Email } from "../../components/auth";
import config from "../../config";
import { useValidCode, useValidEmail } from "../../hooks/useAuthHooks";
import { AuthContext } from "../../utils/authContext";
const bgImgUrl = config.Background.verify;

const useStyles = makeStyles({
  root: {
    height: "100vh",
  },
  hover: {
    "&:hover": { cursor: "pointer" },
  },
});

const VerifyCode: React.FunctionComponent = () => {
  const classes = useStyles();

  const { email, setEmail, emailIsValid } = useValidEmail("");
  const { code, setCode, codeIsValid } = useValidCode("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const isValid =
    !emailIsValid || email.length === 0 || !codeIsValid || code.length === 0;

  const navigate = useNavigate();

  const authContext = useContext(AuthContext);
  const [searchParams] = useSearchParams();

  const sendClicked = async () => {
    try {
      await authContext.verifyCode(email, code);
      navigate("/signin");
    } catch (err) {
      setError("Invalid Code");
    }
  };

  const passwordResetClicked = async () => {
    navigate("/resetpassword");
  };

  useEffect(() => {
    if (searchParams.has("email")) {
      try {
        const credentialsEmail = searchParams.get("email");
        const credentialsCode = searchParams.get("code");

        console.log(searchParams.entries());
        console.log(credentialsEmail, credentialsCode);

        setEmail(credentialsEmail || "");
        setCode(credentialsCode || "");
      } catch (err) {
        setIsLoading(false);
      }
    } else if (isLoading) {
      setIsLoading(false);
    }
  }, []);

  return (
    <AuthWrapper bgImgUrl={bgImgUrl} title="Przepisz kod z wiadomości email">
      <Box width="80%" m={1}>
        <Email emailIsValid={emailIsValid} setEmail={setEmail} />{" "}
      </Box>
      <Box width="80%" m={1}>
        <Code codeIsValid={codeIsValid} setCode={setCode} />
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
        >
          <Box onClick={passwordResetClicked} mt={2}>
            <Typography className={classes.hover} variant="body2">
              Wyślij kod ponownie
            </Typography>
            <Box mt={2}>
              <Typography color="error" variant="body2">
                {error}
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Box>

      <Box mt={2}>
        <Grid container direction="row" justify="center">
          <Box m={1}>
            <Button onClick={() => navigate(-1)}>Powrót</Button>
          </Box>
          <Box m={1}>
            <Button disabled={isValid} colorScheme="blue" onClick={sendClicked}>
              Zatwierdź
            </Button>
          </Box>
        </Grid>
      </Box>
    </AuthWrapper>
  );
};

export default VerifyCode;

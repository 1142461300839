import React from "react";
import ReactDOM from "react-dom/client";
import { GrowthBookProvider } from "@growthbook/growthbook-react";

import App from "./App";
import config from "./config";
import growthbook from "./growthbook";

import "./index.css";

fetch(config.FEATURE_TOGGLE.URL)
  .then((res) => res.json())
  .then(({ features }) => growthbook.setFeatures(features));

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <GrowthBookProvider growthbook={growthbook}>
      <App />
    </GrowthBookProvider>
  </React.StrictMode>
);

/* eslint-disable import/no-anonymous-default-export */
import React, { useState } from "react";
import { NavigateFunction } from "react-router-dom";
import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Radio,
  RadioGroup,
  Select,
  Spacer,
  Stack,
  Text,
  useMediaQuery,
  VStack,
} from "@chakra-ui/react";
import { CardBody, CardFooter } from "@paljs/ui/Card";
import _ from "lodash";

import { Card } from "../../components/paljs";
import {
  selectPeriodToSeeThePrice,
  TooltipWrapper,
} from "../../components/tooltip";
import { GetWinterQuery } from "../../types";

type FormRowProps = {
  header: string;
  children: any;
};
const FormRow = ({ header, children }: FormRowProps) => {
  return (
    <>
      <Box mb={3}>
        <Text as="b">{header}</Text>
      </Box>
      <Flex align="center">
        <Box mr={3}>{children}</Box>
      </Flex>
    </>
  );
};

type RadioOption = {
  label: string;
  value?: string;
};
const RadioSection = (
  label: string,
  options: RadioOption[],
  onChange: (value: string) => void,
  value: string | null,
  isDisabled: boolean
) => {
  const [isBigScreen] = useMediaQuery("(min-width: 1000px)");

  const StackComponent = isBigScreen ? Stack : VStack;
  return (
    <FormRow header={label}>
      <RadioGroup
        isDisabled={isDisabled}
        onChange={onChange}
        value={value || ""}
      >
        <StackComponent alignItems={"flex-start"}>
          {_.map(options, ({ label, value }) => (
            <Radio value={value ?? label} key={label}>
              {label}
            </Radio>
          ))}
        </StackComponent>
      </RadioGroup>
    </FormRow>
  );
};

type Props = {
  navigate: NavigateFunction;
  participants: GetWinterQuery["me"]["children"];
  addToCart: any;
};
export default ({ navigate, addToCart, participants }: Props) => {
  const [isBigScreen] = useMediaQuery("(min-width: 1000px)");

  const [levelValue, setLevelValue] = useState<string | null>(null);
  const [typeValue, setTypeValue] = useState<string | null>(null);
  const [periodValue, setPeriodValue] = useState<string | null>(null);
  const [participantValue, setParticipantValue] = useState<string | null>(null);
  const canAddToBasket =
    levelValue && typeValue && periodValue && participantValue;

  const selectedParticipant = _.find(
    participants,
    ({ id }) => id === participantValue
  );
  const availableProducts = selectedParticipant?.availableProducts;

  const periods = _.filter(availableProducts || [], (product) => {
    const isTurnus = _.startsWith(product.name, "Turnus");
    const hasStock = _.get(product, "stock.available", 0) > 0;
    return isTurnus && hasStock;
  });

  const getCurrentPrice = () => {
    if (periodValue) {
      const selectedPeriod = _.find(periods, ({ sku }) => sku === periodValue);
      if (selectedPeriod) {
        return selectedPeriod.price.value;
      }
    }
    return TooltipWrapper({ label: selectPeriodToSeeThePrice });
  };
  const onSelectParticipant = (id: string) => {
    if (id === "addNew") {
      navigate("/profile/children");
    } else {
      setParticipantValue(id);
    }
  };

  const participantsPlusAddon = [
    ...participants,
    {
      id: "addNew",
      name: ">> Dodaj nowego uczestnika << (przejdź do profili)",
      surname: "",
    },
  ];
  const ParticipantSection = () => {
    return (
      <FormRow header={"Dane uczestnika"}>
        <Select
          size="sm"
          placeholder={"Wybierz uczestnika"}
          value={participantValue || ""}
          onChange={(e) => onSelectParticipant(e.target.value)}
        >
          {_.map(participantsPlusAddon, (participant) => (
            <option value={participant.id} key={participant.id}>
              {participant.name} {participant.surname}
            </option>
          ))}
        </Select>
      </FormRow>
    );
  };

  const PeriodSection = () => {
    return (
      <FormRow header="Turnus">
        <Select
          size="sm"
          isDisabled={!selectedParticipant || _.isEmpty(periods)}
          placeholder={
            selectedParticipant && _.isEmpty(periods)
              ? "Brak wolnych miejsc"
              : "Wybierz turnus"
          }
          value={periodValue || ""}
          onChange={(e) => setPeriodValue(e.target.value)}
        >
          {_.map(periods, (period) => (
            <option value={period.sku} key={period.sku}>
              {period.name}
            </option>
          ))}
        </Select>
      </FormRow>
    );
  };

  const typeOptions = [{ label: "Narty" }, { label: "Snowboard" }];
  const TypeSection = () =>
    RadioSection(
      "Zajęcia",
      typeOptions,
      setTypeValue,
      typeValue,
      !selectedParticipant
    );

  const levelOptions = [
    { label: "Początkujący", value: "1" },
    { label: "Średniozaawansowany", value: "2" },
    { label: "Zaawansowany", value: "3" },
  ];
  const LevelSection = () =>
    RadioSection(
      "Poziom",
      levelOptions,
      setLevelValue,
      levelValue,
      !selectedParticipant
    );

  const clearAllFields = () => {
    setTypeValue(null);
    setLevelValue(null);
  };
  const handleAddToCart = () => {
    addToCart({
      level: levelValue,
      type: typeValue,
      periodId: periodValue,
      participantId: participantValue,
    });
    clearAllFields();
  };

  return (
    <>
      <Card>
        <CardBody>
          <ParticipantSection />
          <Divider my={2} />
          <PeriodSection />
          <Divider my={2} />
          <TypeSection />
          <Divider my={2} />
          <LevelSection />
          <Divider my={2} />
          <Flex direction={isBigScreen ? "row" : "column"}>
            <Box my={2}>
              <Text as="b">Cena (bez zniżek)</Text>
            </Box>
            <Spacer />
            <Text as="b">{getCurrentPrice()}</Text>
          </Flex>
        </CardBody>
        <CardFooter>
          <Center>
            <Button
              colorScheme="blue"
              onClick={handleAddToCart}
              isDisabled={!canAddToBasket}
            >
              Dodaj zapis do koszyka!
            </Button>
          </Center>
        </CardFooter>
      </Card>
    </>
  );
};

import { useLocation } from "react-router-dom";
import { Center } from "@chakra-ui/react";
import _ from "lodash";

import Layout from "../components/layout";
import Loading from "../components/loading";

export default () => {
  const location = useLocation();
  const redirectPath = _.trimStart(_.get(location, "hash", ""), "#");

  if (!_.isEmpty(redirectPath)) {
    window.location.replace(redirectPath);
  }

  return (
    <Layout>
      <Center>
        <Loading />
      </Center>
    </Layout>
  );
};

import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button } from "@chakra-ui/react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import { AuthWrapper, Code, Email, Password } from "../../components/auth";
import config from "../../config";
import {
  useValidCode,
  useValidEmail,
  useValidPassword,
} from "../../hooks/useAuthHooks";
import { AuthContext } from "../../utils/authContext";
const bgImgUrl = config.Background.signIn;

export default function ForgotPassword() {
  const { code, setCode, codeIsValid } = useValidCode("");
  const { password, setPassword, passwordIsValid } = useValidPassword("");
  const { email, setEmail, emailIsValid } = useValidEmail("");
  const [error, setError] = useState("");
  const [reset, setReset] = useState(false);

  const {
    password: passwordConfirm,
    setPassword: setPasswordConfirm,
    passwordIsValid: passwordConfirmIsValid,
  } = useValidPassword("");

  const isValid =
    !codeIsValid ||
    code.length === 0 ||
    !emailIsValid ||
    email.length === 0 ||
    !passwordIsValid ||
    password.length === 0 ||
    !passwordConfirmIsValid ||
    passwordConfirm.length === 0;

  const navigate = useNavigate();

  const authContext = useContext(AuthContext);

  const resetPassword = async () => {
    try {
      await authContext.forgotPassword(email, code, password);
      setReset(true);
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message);
      }
    }
  };

  const updatePassword = (
    <>
      <Box width="80%" m={1}>
        <Code codeIsValid={codeIsValid} setCode={setCode} />
      </Box>
      <Box width="80%" m={1}>
        <Email emailIsValid={emailIsValid} setEmail={setEmail} />
      </Box>
      <Box width="80%" m={1}>
        <Password
          label="Hasło"
          passwordIsValid={passwordIsValid}
          setPassword={setPassword}
        />
      </Box>
      <Box width="80%" m={1}>
        <Password
          label="Powtórz hasło"
          passwordIsValid={passwordConfirmIsValid}
          setPassword={setPasswordConfirm}
        />
      </Box>

      {/* Error */}
      <Box mt={2}>
        <Typography color="error" variant="body2">
          {error}
        </Typography>
      </Box>

      {/* Buttons */}
      <Box mt={2}>
        <Grid container direction="row" justify="center">
          <Box m={1}>
            <Button onClick={() => navigate(-1)}>Wróć</Button>
          </Box>
          <Box m={1}>
            <Button
              disabled={isValid}
              colorScheme="blue"
              onClick={resetPassword}
            >
              Zmień hasło
            </Button>
          </Box>
        </Grid>
      </Box>
    </>
  );

  const passwordReset = (
    <>
      <Typography variant="h5">{`Password Reset`}</Typography>

      <Box m={4}>
        <Button onClick={() => navigate("/signin")} colorScheme="blue">
          Sign In
        </Button>
      </Box>
    </>
  );

  return (
    <AuthWrapper bgImgUrl={bgImgUrl} title="Przypomnienie hasła">
      {!reset ? updatePassword : passwordReset}
    </AuthWrapper>
  );
}

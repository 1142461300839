export const GET_SUMMER_CAMPS_2023_FROM_CHILD = /* GraphQL */ `
  query getSummerCamps2023FromChild {
    me {
      id
      children {
        id
        name
        surname
        funActive: availableProducts(
          category: "Fun Active"
          period: { start: "2024-01-01", end: "2024-12-31" }
        ) {
          ... on IProduct {
            sku
            name
            stock {
              available
            }
            price {
              value
            }
          }
        }
        adventure: availableProducts(
          category: "Adventure"
          period: { start: "2024-01-01", end: "2024-12-31" }
        ) {
          ... on IProduct {
            sku
            name
            stock {
              available
            }
            price {
              value
            }
          }
        }
        sailing: availableProducts(
          category: "Żeglarski"
          period: { start: "2024-01-01", end: "2024-12-31" }
        ) {
          ... on IProduct {
            sku
            name
            stock {
              available
            }
            price {
              value
            }
          }
        }
      }
    }
  }
`;

export const ADD_SUMMER_CAMPS_2023_TO_CART = /* GraphQL */ `
  mutation AddToCart($sku: ID!, $childId: ID!) {
    cartAddItem(sku: $sku, childId: $childId) {
      id
      problems {
        ... on ICartProblem {
          code
          message
        }
        ... on CartProblemCustomData {
          item {
            ... on ICartItem {
              id
              sku
            }
          }
          property
        }
      }
      items {
        ... on CartItemSummerCamp {
          sku
          id
        }
      }
    }
  }
`;

import { FaSwimmer } from "react-icons/fa";
import { IoTennisball } from "react-icons/io5";
import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Icon,
  Link,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Select,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  UnorderedList,
  useDisclosure,
} from "@chakra-ui/react";
import _ from "lodash";

import { CartItem, MembershipType } from "../../types";
import { Col, Row } from "../paljs";
import {
  hasMembershipTooltip,
  hasMissingMembershipTooltip,
  TooltipWrapper,
} from "../tooltip";

export type TCartItemAction = {
  label: string;
  colorScheme: string;
  onClick?: (cartItem: CartItem) => void;
  to?: string;
  newPage?: boolean;
  isDisabled?: boolean;
};

export const CartItemAction = ({
  label,
  colorScheme,
  onClick,
  isDisabled,
  cartItem,
  to,
  newPage,
}: TCartItemAction & { cartItem: CartItem }) => {
  if (onClick) {
    return (
      <Button
        colorScheme={colorScheme}
        isDisabled={isDisabled}
        onClick={() => onClick(cartItem)}
        key={label}
      >
        {label}
      </Button>
    );
  }
  if (to) {
    return (
      <Link target={newPage ? "_blank" : undefined} href={to}>
        <Button colorScheme={colorScheme} isDisabled={isDisabled} key={label}>
          {label}
        </Button>
      </Link>
    );
  }
  return <></>;
};

type CartItemRowProps = {
  label?: string;
  children: any;
};
export const CartItemRow = ({ label, children }: CartItemRowProps) => {
  return (
    <Row>
      <Col breakPoint={{ sm: 12, md: 4 }}>
        <Box mb={3}>
          <Heading size="sm">{label ? label : " "}</Heading>
        </Box>
      </Col>
      <Col breakPoint={{ sm: 12, md: 8 }}>
        {" "}
        <Box mb={3}>{children}</Box>
      </Col>
    </Row>
  );
};

const EntriesPopover = (entries: any) => {
  return (
    <Popover>
      <PopoverTrigger>
        <Button size="xs"> Pełna lista</Button>
      </PopoverTrigger>
      <PopoverContent bg="#F0F0F0">
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader>Pełna lista zajęć:</PopoverHeader>
        <PopoverBody style={{ overflow: "scroll", maxHeight: 200 }}>
          {" "}
          <UnorderedList>
            {_.map(entries, (entry, idx) => (
              <ListItem key={idx}>
                {_.replace(entry, "T", " ")}
                {/* fcuk */}
              </ListItem>
            ))}
          </UnorderedList>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

const TableDescription = () => {
  return (
    <Box fontSize={"0.9rem"} textAlign="center">
      Korzystając z oferty Klub Sportteam zostajesz członkiem naszej
      społeczności.
      <br />
      Możesz wybrać wariant członkostwa Standard lub Premium. <br />
      Warianty różnią się wysokością składki członkowskiej i wynikającymi z nich
      zniżkami na ofertę Sportteam zgodnie z Regulamin Świadczenia Usług <br />
      <Box fontSize={"md"}>
        <b>
          Jeśli wykupisz członkostwo w okresie od 1 stycznia do 31 sierpnia
          danego roku obowiązuje ono do 31 sierpnia danego roku
        </b>
        <b>
          Jeśli wykupisz członkostwo w okresie od 1 września do 31 grudnia
          danego roku obowiązuje ono do 31 sierpnia następnego roku
        </b>
      </Box>
      <br />
      <UnorderedList>
        <ListItem>Standard – opłata członkowska wynosi 50 zł</ListItem>
        <ListItem>Premium – opłata członkowska wynosi 90 zł</ListItem>
      </UnorderedList>
      <br />
    </Box>
  );
};
const TableComponent = () => {
  return (
    <Table variant="simple" size="sm">
      <Thead>
        <Tr>
          <Th>Rodzaj zniżka</Th>
          <Th>PAKIET CZŁONKOWSKI STANDARD</Th>
          <Th>PAKIET CZŁONKOWSKI PREMIUM</Th>
        </Tr>
      </Thead>
      <Tbody>
        <Tr>
          <Td fontSize={"0.7rem"}>
            Zniżka na wyjazdy, obozy i sportowe urodziny
          </Td>
          <Td>30 zł</Td>
          <Td>80 zł</Td>
        </Tr>
        <Tr>
          <Td fontSize={"0.7rem"}>
            Zniżka na udział w zawodach i zakupach sprżętu sportowego
          </Td>
          <Td>5 zł</Td>
          <Td>10 zł</Td>
        </Tr>
      </Tbody>
    </Table>
  );
};

type MembershipSectionProps = {
  isOpen: boolean;
  isLoading: boolean;
  setIsOpen: (isOpen: boolean) => void;
  hasMembership: boolean;
  hasMembershipInCart: boolean;
  membershipType?: MembershipType;
  membershipTypeInCart?: MembershipType;
  onSelectMembership: (type: MembershipType) => void;
};
export const MembershipSection = ({
  isOpen,
  isLoading = false,
  setIsOpen,
  hasMembership,
  hasMembershipInCart,
  membershipType,
  membershipTypeInCart,
  onSelectMembership,
}: MembershipSectionProps) => {
  return (
    <>
      <Box mb={3}>
        <Text as="b">Członkostwo</Text>
      </Box>
      <Flex align="center">
        <Box mr={3}>
          <Select
            size="sm"
            placeholder={
              membershipType || membershipTypeInCart
                ? undefined
                : "Wybierz rodzaj"
            }
            isDisabled={hasMembership}
            onChange={(e) =>
              onSelectMembership(e.target.value as unknown as MembershipType)
            }
            defaultValue={membershipType || membershipTypeInCart}
          >
            <option value={MembershipType.Premium}>Premium - 90 zł</option>
            <option value={MembershipType.Standard}>Standard - 50 zł</option>
          </Select>
        </Box>
        {hasMembership && TooltipWrapper({ label: hasMembershipTooltip })}
        {!hasMembership &&
          !hasMembershipInCart &&
          TooltipWrapper({ label: hasMissingMembershipTooltip, isError: true })}
        <Button size="xs" ml={2} onClick={() => setIsOpen(true)}>
          Szczegóły
        </Button>
        {isLoading && <Spinner size={"md"} ml={2} />}
        <Modal size="xl" isOpen={isOpen} onClose={() => setIsOpen(false)}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Szczegóły</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <TableDescription />
              <Divider my={5} />

              <TableComponent />
            </ModalBody>

            <ModalFooter>
              <Button
                colorScheme="blue"
                mr={3}
                onClick={() => setIsOpen(false)}
              >
                Zamknij
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Flex>
    </>
  );
};

const BundleDetails = (bundle: any) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Button size="xs" ml={2} onClick={onOpen}>
        Szczegóły
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Szczegóły zajęć</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box>
              <CartItemRow label="Sport">{bundle.sport}</CartItemRow>
              <CartItemRow label="Miejsce">{bundle.location}</CartItemRow>
              <CartItemRow label="Rodzaj">{bundle.type}</CartItemRow>
              <CartItemRow label="Dni zajęć">
                {_.size(bundle.dayTimes) === 1 ? (
                  `${bundle.dayTimes[0].day}, ${bundle.dayTimes[0].time}`
                ) : (
                  <UnorderedList>
                    {_.map(bundle.dayTimes, ({ day, time }, idx) => (
                      <ListItem key={idx}>
                        {day}, {time}
                      </ListItem>
                    ))}
                  </UnorderedList>
                )}
              </CartItemRow>
              <CartItemRow label="Ilość zajęć">
                {_.size(bundle.entries)}{" "}
                {_.size(bundle.entries) > 0 && EntriesPopover(bundle.entries)}{" "}
              </CartItemRow>
              {/* <CartItemRow label="Cena jednostkowa">{bundle.unitPrice.value} zł</CartItemRow> */}
              <CartItemRow label="Suma całkowita">
                {bundle.price.value} zł
              </CartItemRow>
            </Box>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Zamknij
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
type OrderItemRowProps = {
  sport?: string;
  type?: string;
  price: string;
  location?: string;
  name?: string;
  bundle?: any;
};
export const OrderItemRow = ({
  sport,
  type,
  price,
  location,
  name,
  bundle,
}: OrderItemRowProps) => {
  const showIcon = sport === "Pływanie" || sport === "Tenis";
  const isSwimming = sport === "Pływanie";

  return (
    <Box mb={3}>
      <Row>
        {/* <Col breakPoint={{ sm: 1 }}>ico</Col> */}
        <Col breakPoint={{ sm: 2 }}>
          <Flex align="center">
            {showIcon && (
              <Icon as={isSwimming ? FaSwimmer : IoTennisball} mr={2} />
            )}
            {sport}
          </Flex>
        </Col>
        <Col breakPoint={{ sm: 2 }}>{type}</Col>
        <Col breakPoint={{ sm: 6 }}>
          {location} {bundle ? BundleDetails(bundle) : null}
          {name ? (
            <Text as="i" color={"gray.500"}>
              ({name})
            </Text>
          ) : null}
        </Col>
        <Col breakPoint={{ sm: 2 }} style={{ textAlign: "right" }}>
          {price} zł
        </Col>
      </Row>
    </Box>
  );
};

import { useEffect } from "react";
import { FaCheck } from "react-icons/fa";
import { Button, ButtonProps } from "@chakra-ui/react";
import { useMutation } from "graphql-hooks";

import { GENERATE_PAYMENT_LINK } from "../../../../data/mutations";
import {
  GeneratePaymentLinkMutation,
  OrderPayment,
  OrderPaymentStatus,
} from "../../../../types";

export default ({
  orderId,
  payment,
  buttonProps,
}: {
  orderId: string;
  payment: OrderPayment;
  buttonProps?: ButtonProps;
}) => {
  const [generatePaymentLink, { loading, data }] =
    useMutation<GeneratePaymentLinkMutation>(GENERATE_PAYMENT_LINK);

  useEffect(() => {
    if (loading === false && data && data.generatePaymentLink.link) {
      // navigate(data.generatePaymentLink.link, { replace: true })
      window.location.replace(data.generatePaymentLink.link);
    }
  }, [loading, data]);

  if (payment.status === OrderPaymentStatus.Paid) {
    return (
      <Button
        {...buttonProps}
        leftIcon={<FaCheck />}
        colorScheme={"green"}
        isDisabled={true}
      >
        {"Opłacone"}
      </Button>
    );
  } else if (payment.link || data?.generatePaymentLink.link) {
    return (
      <Button
        {...buttonProps}
        colorScheme={"blue"}
        onClick={() =>
          generatePaymentLink({ variables: { orderId, paymentId: payment.id } })
        }
      >
        Zapłać
      </Button>
    );
  } else {
    return (
      <Button
        {...buttonProps}
        isLoading={loading}
        colorScheme={"blue"}
        onClick={() =>
          generatePaymentLink({ variables: { orderId, paymentId: payment.id } })
        }
      >
        Zapłać
      </Button>
    );
  }
};

export const GET_ME = /* GraphQL */ `
  query GetMe {
    me {
      id
      name
      surname
      contactInfo {
        email
        phone
      }
      pesel
      address {
        street
        code
        city
      }
      isIncomplete
      consents {
        knowledge
        health
        healthProcessing
        email
        text
        call
      }
      guardians {
        id
        name
        surname
        contactInfo {
          email
          phone
        }
      }
    }
  }
`;

export const GET_ME_ID = /* GraphQL */ `
  query GetMeId {
    me {
      id
    }
  }
`;

export const GET_CHILDREN = /* GraphQL */ `
  query GetChildren {
    me {
      id
      address {
        street
        code
        city
      }
      children {
        id
        name
        surname
        health
        pesel
        canDelete
        address {
          street
          code
          city
        }
        birthday
        isIncomplete
        funActive: availableProducts(
          category: "Fun Active"
          period: { start: "2024-01-01", end: "2024-12-31" }
        ) {
          ... on IProduct {
            sku
            name
            stock {
              available
            }
            price {
              value
            }
          }
        }
        adventure: availableProducts(
          category: "Adventure"
          period: { start: "2024-01-01", end: "2024-12-31" }
        ) {
          ... on IProduct {
            sku
            name
            stock {
              available
            }
            price {
              value
            }
          }
        }
      }
    }
  }
`;

export const GET_WINTER = /* GraphQL */ `
  query GetWinter {
    me {
      id
      activeOrders {
        id
      }
      children {
        id
        name
        surname
        isIncomplete
        canDelete
        availableProducts(category: "Camp") {
          ... on IProduct {
            name
            sku
            price {
              currency
              value
            }
            stock {
              available
              total
            }
          }
        }
      }
    }
  }
`;

export const GET_ORDERS = /* GraphQL */ `
  query GetOrders {
    me {
      id
      name
      surname
      isIncomplete
      consents {
        knowledge
        health
        healthProcessing
        email
        text
        call
      }
      cart {
        discounts {
          code
        }
        discountCodes {
          code
          description
          expirationDate
        }
        paymentOptions {
          id
          name
          description
          price {
            value
          }
          payments {
            name
            dueDate
            price {
              value
            }
            items {
              ...Membership
              ...Activity
              ...WinterCamp
              ...SummerCamp
            }
            bundles {
              ...ActivityBundle
              ...Membership
              ...WinterCamp
              ...SummerCamp
            }
          }
        }
        itemsGroups {
          participant {
            ... on Child {
              __typename
              id
              name
              surname
              membership {
                membershipType: type
              }
            }
            ... on Account {
              __typename
              id
              name
              surname
              membership {
                membershipType: type
              }
            }
          }
          bundles {
            ...ActivityBundle
            ...Membership
            ...WinterCamp
            ...SummerCamp
          }
          problems {
            ...CartProblemFragment
          }
        }
        problems {
          ...CartProblemFragment
        }
        discounts {
          source
          reason
          message
          code
          discountValue {
            __typename
            ... on DiscountPercentage {
              value
            }
            ... on Price {
              value
              currency
            }
          }
        }
      }
    }
  }
  fragment CartProblemFragment on ICartProblem {
    __typename
    code
    description
    message
    source
    ... on CartProblemRequiredProduct {
      availableProducts {
        name
        sku
      }
    }
    ... on CartProblemMissingChildData {
      childId
    }
    ... on CartProblemProductOutOfStock {
      product {
        sku
      }
    }
    #   ...on CartProblemMissingAccountData {
    #   }
    ... on CartProblemProductAgeRestriction {
      minimumBirthDate
      maximumBirthDate
      participantBirthDate
      participantId
    }
    ... on CartProblemCustomData {
      item {
        ... on ICartItem {
          id
          sku
        }
      }
      property
    }
  }
  fragment Activity on CartItemActivity {
    __typename
    sport
    location
    type
    entries
    id
    sku
    customData
    unitPrice {
      value
    }
    price {
      value
    }
    participant {
      ... on Child {
        __typename
        id
        name
        surname
        membership {
          membershipType: type
        }
      }
      ... on Account {
        __typename
        id
        name
        surname
        membership {
          membershipType: type
        }
      }
    }
  }
  fragment Membership on CartItemMembership {
    __typename
    membershipType: type
    start
    end
    id
    sku
    customData
    price {
      value
    }
    period {
      start
      end
      name
    }
    participant {
      ... on Child {
        __typename
        id
        name
        surname
      }
      ... on Account {
        __typename
        id
        name
        surname
      }
    }
  }
  fragment WinterCamp on CartItemWinterCamp {
    __typename
    sport
    sku
    id
    name
    level
    customData
    price {
      value
    }
    participant {
      ... on Child {
        __typename
        id
        name
        surname
        membership {
          membershipType: type
        }
      }
      ... on Account {
        __typename
        id
        name
        surname
        membership {
          membershipType: type
        }
      }
    }
  }
  fragment SummerCamp on CartItemSummerCamp {
    __typename
    sku
    id
    name
    customData
    price {
      value
    }
    profile
    participant {
      ... on Child {
        __typename
        id
        name
        surname
        membership {
          membershipType: type
        }
      }
      ... on Account {
        __typename
        id
        name
        surname
        membership {
          membershipType: type
        }
      }
    }
  }
  fragment ActivityBundle on CartItemActivityBundle {
    __typename
    sport
    location
    type
    entries
    dayTimes {
      day
      time
    }
    price {
      value
    }
    unitPrice {
      value
    }
    period {
      start
      end
      name
    }
    participant {
      ... on Child {
        __typename
        id
        name
        surname
        membership {
          membershipType: type
        }
      }
      ... on Account {
        __typename
        id
        name
        surname
        membership {
          membershipType: type
        }
      }
    }
  }
`;

export const GET_CART_PRICE = GET_ORDERS;

export const GET_PAYMENT_OPTION_ID = /* GraphQL */ `
  query GetPaymentOptionId {
    me {
      id
      cart {
        paymentOptions {
          id
          price {
            value
          }
        }
      }
    }
  }
`;

export const GET_ACTIVE_ORDERS = /* GraphQL */ `
  query GetActiveOrders {
    me {
      id
      activeOrders {
        id
        date
        fullyPaid
        documents {
          id
          name
          description
          link
        }
        price {
          value
        }
        payments {
          id
          name
          dueDate
          status
          link
          price {
            value
          }
        }
        itemsGroups {
          participant {
            ... on Child {
              __typename
              id
              name
              surname
              membership {
                membershipType: type
              }
            }
            ... on Account {
              __typename
              id
              name
              surname
              membership {
                membershipType: type
              }
            }
          }
          bundles {
            __typename
            ... on OrderItemMembership {
              membershipType: type
              price {
                value
              }
              period {
                start
                end
                name
              }
            }
            ... on OrderItemActivityBundle {
              sport
              location
              type
              entries
              dayTimes {
                day
                time
              }
              unitPrice {
                value
              }
              price {
                value
              }
              period {
                start
                end
                name
              }
            }
            ... on OrderItemWinterCamp {
              __typename
              sku
              name
              sport
              level
              price {
                value
              }
              discounts {
                reason
                source
                message
                discountValue {
                  ... on Price {
                    value
                  }
                  ... on DiscountPercentage {
                    percentage: value
                  }
                }
              }
              participant {
                ... on Child {
                  __typename
                  id
                  name
                  surname
                  membership {
                    membershipType: type
                  }
                }
                ... on Account {
                  __typename
                  id
                  name
                  surname
                  membership {
                    membershipType: type
                  }
                }
              }
            }
            ... on OrderItemSummerCamp {
              __typename
              sku
              name
              price {
                value
              }
              profile
              discounts {
                reason
                source
                message
                discountValue {
                  ... on Price {
                    value
                  }
                  ... on DiscountPercentage {
                    percentage: value
                  }
                }
              }
              participant {
                ... on Child {
                  __typename
                  id
                  name
                  surname
                  membership {
                    membershipType: type
                  }
                }
                ... on Account {
                  __typename
                  id
                  name
                  surname
                  membership {
                    membershipType: type
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
export const GET_ARCHIVE_ORDERS = /* GraphQL */ `
  query GetArchiveOrders {
    me {
      id
      archiveOrders {
        id
        date
        fullyPaid
        price {
          value
        }
        documents {
          id
          name
          description
          link
        }
        payments {
          id
          name
          dueDate
          status
          link
          price {
            value
          }
        }
        itemsGroups {
          participant {
            ... on Child {
              __typename
              id
              name
              surname
              membership {
                membershipType: type
              }
            }
            ... on Account {
              __typename
              id
              name
              surname
              membership {
                membershipType: type
              }
            }
          }
          bundles {
            __typename
            ... on OrderItemMembership {
              membershipType: type
              price {
                value
              }
              period {
                start
                end
                name
              }
            }
            ... on OrderItemActivityBundle {
              sport
              location
              type
              entries
              dayTimes {
                day
                time
              }
              unitPrice {
                value
              }
              price {
                value
              }
              period {
                start
                end
                name
              }
            }
            ... on OrderItemWinterCamp {
              __typename
              sku
              name
              sport
              level
              price {
                value
              }
              discounts {
                reason
                source
                message
                discountValue {
                  ... on Price {
                    value
                  }
                  ... on DiscountPercentage {
                    percentage: value
                  }
                }
              }
              participant {
                ... on Child {
                  __typename
                  id
                  name
                  surname
                  membership {
                    membershipType: type
                  }
                }
                ... on Account {
                  __typename
                  id
                  name
                  surname
                  membership {
                    membershipType: type
                  }
                }
              }
            }
            ... on OrderItemSummerCamp {
              __typename
              sku
              name
              price {
                value
              }
              profile
              discounts {
                reason
                source
                message
                discountValue {
                  ... on Price {
                    value
                  }
                  ... on DiscountPercentage {
                    percentage: value
                  }
                }
              }
              participant {
                ... on Child {
                  __typename
                  id
                  name
                  surname
                  membership {
                    membershipType: type
                  }
                }
                ... on Account {
                  __typename
                  id
                  name
                  surname
                  membership {
                    membershipType: type
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_ORDERS_SUMMARY = /* GraphQL */ `
  query GetOrdersSummary {
    me {
      ordersSummary {
        cart
        active
        archive
      }
    }
  }
`;

export const GET_PAYED_ORDERS = `
  query GetPayedOrders {
    id
    payedPrice
    status
    agreementUrl
    items {
      id
      price {
        value
      }
      buyer {
        id
        name
        surname
        email
        phone
      }
      participants {
        id
        name
        surname
      }
      product {
        id
        productType
        location
        classType
        classLevel
        occurrences {
          id
          dateFrom
          dateTo
        }
      }
    }
  }
`;

export const GET_LOCATIONS = "";

export const GET_ALL_ACTIVITIES = /* GraphQL */ `
  query GetAllActivities {
    me {
      id
      allActivities {
        participant {
          ... on Child {
            __typename
            id
            name
            surname
            membership {
              membershipType: type
            }
          }
          ... on Account {
            __typename
            id
            name
            surname
            membership {
              membershipType: type
            }
          }
        }
        bundles {
          __typename
          ... on OrderItemMembership {
            membershipType: type
            price {
              value
            }
          }
          ... on OrderItemActivityBundle {
            sport
            location
            type
            entries
            dayTimes {
              day
              time
            }
            unitPrice {
              value
            }
            price {
              value
            }
          }
        }
      }
    }
  }
`;

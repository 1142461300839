import { Box, Divider, Flex, Text } from "@chakra-ui/react";
import _ from "lodash";

type RowedDescriptionProps = {
  rows: (string | any)[][];
  suffixRow?: string;
  disabledRows?: number[];
};
const RowedDescription = ({
  rows,
  suffixRow,
  disabledRows,
}: RowedDescriptionProps) => {
  return (
    <>
      {_.map(rows, ([boldedText, normalText, rightText], index) => {
        return (
          <div key={index}>
            <Flex justify={"space-between"}>
              <Box mb={3}>
                <div
                  key=""
                  style={{
                    minWidth: 85,
                    display: "inline-block",
                    marginRight: 5,
                  }}
                >
                  <Text
                    color={
                      disabledRows && _.includes(disabledRows, index)
                        ? "gray.300"
                        : undefined
                    }
                  >
                    <b>
                      {boldedText} {_.includes(disabledRows, index)}
                    </b>
                    {normalText}{" "}
                  </Text>
                </div>
              </Box>
              <span style={{ textAlign: "right" }}>{rightText}</span>
            </Flex>
          </div>
        );
      })}
      {suffixRow && (
        <>
          <Divider />
          <Text mt={3}>{suffixRow}</Text>
        </>
      )}
    </>
  );
};

export default RowedDescription;

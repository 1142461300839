import { MdCheckCircle, MdRadioButtonUnchecked } from "react-icons/md";
import { Flex, List, ListIcon, ListItem, Spacer, Text } from "@chakra-ui/react";
import _ from "lodash";

import { Order, OrderPaymentStatus } from "../../../../types";
import PaymentButton from "../payment-button";

export default ({ order }: { order: Order }) => {
  return (
    <>
      <List mt={2} spacing={2}>
        {_.map(order.payments, (payment) => {
          const isPaid = payment.status === OrderPaymentStatus.Paid;
          return (
            <ListItem key={payment.id}>
              <Flex alignItems={"center"}>
                <ListIcon
                  as={isPaid ? MdCheckCircle : MdRadioButtonUnchecked}
                  color={`${isPaid ? "green" : "gray"}.500`}
                />
                {payment.name}
                <Spacer />
                <Flex
                  width={"200px"}
                  justifyContent={"flex-end"}
                  alignItems={"center"}
                >
                  <Text width={"90px"} as={isPaid ? "s" : undefined}>
                    {payment.price.value}&nbsp;zł
                  </Text>
                  <Spacer></Spacer>
                  <PaymentButton
                    orderId={order.id}
                    payment={payment}
                    buttonProps={{ size: "sm" }}
                  ></PaymentButton>
                </Flex>
              </Flex>
            </ListItem>
          );
        })}
      </List>
    </>
  );
};

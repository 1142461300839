import { InfoIcon, WarningIcon } from "@chakra-ui/icons";
import { Center, Tooltip } from "@chakra-ui/react";

export const hasMembershipTooltip =
  "Wykryto aktualny status członka Klubu Sportteam";

export const selectPeriodToSeeThePrice =
  "Wybierz turnus, żeby zobaczyć szacunkową cenę";

export const hasMissingMembershipTooltip = (
  <Center>
    Brak statusu członka Klubu Sportteam!
    <br />
    <br />
    Warunkiem uczestnictwa w zajęciach i wyjazdach organizowanych przaz UKS
    Sportteam jest członkostwo klubowe.
    <br />
    <br />
    Wybierz rodzaj członkostwa z menu po lewej stronie.
  </Center>
);

export const missingAccountData = (
  <Center>
    Wykryto brakujące dane profilowe użytkownika!
    <br />
    Uzupełnij je klikając w przycisk Edytuj
  </Center>
);
type Props = {
  label: any;
  isError?: boolean;
};
export const TooltipWrapper = ({ label, isError }: Props) => {
  return (
    <Tooltip label={label} fontSize="sm" ml={2}>
      {isError ? <WarningIcon color="red.500" /> : <InfoIcon />}
    </Tooltip>
  );
};

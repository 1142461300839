import { FaSkiing, FaSnowboarding, FaTimes } from "react-icons/fa";
import { Box, Button, Flex, Icon, Text, useMediaQuery } from "@chakra-ui/react";
import _ from "lodash";

import { Col, Row } from "../../../../components/paljs";
import { TooltipWrapper } from "../../../../components/tooltip";
import { CartDiscount, OrderItemDiscount } from "../../../../types";

import AdditionalInfo from "./additionalInfo";

type WinterOrderItemRowProps = {
  agreementUrl?: string;
  id?: string;
  sport?: string;
  level?: string;
  price: string;
  location?: string;
  name?: string;
  periodName?: string;
  removeFromCart?: any;
  isEditable?: boolean;
  setChurchConsent?: (bool: boolean) => void;
  churchConsent?: boolean;
  setCampConsent?: (bool: boolean) => void;
  campConsent?: boolean;
  setInsuranceConsent?: (bool: boolean) => void;
  insuranceConsent?: boolean;
  setShirtSize?: (size: string) => void;
  shirtSize?: string;
  discount?: CartDiscount | OrderItemDiscount;
};
const WinterCampRow = ({
  agreementUrl,
  sport,
  level,
  price,
  name,
  periodName,
  location,
  id,
  removeFromCart,
  isEditable,
  setChurchConsent,
  churchConsent,
  setInsuranceConsent,
  insuranceConsent,
  setCampConsent,
  campConsent,
  setShirtSize,
  shirtSize,
  discount,
}: WinterOrderItemRowProps) => {
  const [isBigScreen] = useMediaQuery("(min-width: 1300px)");
  const [periodTitle, periodDatesWithoutFirstParenthesis] = _.split(
    periodName,
    "("
  );
  const periodDates = "(" + periodDatesWithoutFirstParenthesis;
  const showRemoveButton = !!id && !!removeFromCart;

  const showAdditionalInfo =
    setShirtSize &&
    setChurchConsent &&
    setCampConsent &&
    setInsuranceConsent &&
    isEditable;

  return (
    <Box mb={3}>
      <Row>
        <Col breakPoint={{ sm: isBigScreen ? 2 : 1 }}>
          <Flex align="center">
            {<Icon as={sport === "Narty" ? FaSkiing : FaSnowboarding} mr={2} />}
            {isBigScreen && sport}
          </Flex>
        </Col>
        <Col breakPoint={{ sm: 2 }} className="elipsis">
          {level}
        </Col>
        <Col breakPoint={{ sm: isBigScreen ? 4 : 5 }}>
          {location}
          {name ? (
            <Text as="i" color={"gray.500"}>
              ({name})
            </Text>
          ) : null}
        </Col>
        <Col breakPoint={{ sm: 2 }}>
          {periodTitle}
          <br />
          {periodDates}
        </Col>
        <Col breakPoint={{ sm: 2 }} style={{ textAlign: "right" }}>
          {price}&nbsp;zł{" "}
          {showRemoveButton && (
            <Button size="xs" onClick={() => removeFromCart(id)} mr={2}>
              <Icon as={FaTimes} />
            </Button>
          )}
          {!isEditable && discount && (
            <>
              <br />
              <b>zniżka -{discount.discountValue.value} zł</b>{" "}
              {TooltipWrapper({ label: discount.reason })}
            </>
          )}
        </Col>
      </Row>
      {showAdditionalInfo && (
        <AdditionalInfo
          agreementUrl={agreementUrl}
          setCampConsent={setCampConsent}
          campConsent={campConsent}
          setChurchConsent={setChurchConsent}
          churchConsent={churchConsent}
          setInsuranceConsent={setInsuranceConsent}
          insuranceConsent={insuranceConsent}
          setShirtSize={setShirtSize}
          shirtSize={shirtSize}
          isEditable={isEditable}
          discount={discount}
        />
      )}
    </Box>
  );
};

export default WinterCampRow;

export const WinterCampDisclaimer = () => (
  <Box mb={3} fontSize={"0.8rem"} color="grey">
    <Text as="i">
      Cena nie zawiera kosztów karnetu i wypożyczenia sprzętu oraz
      “kieszonkowego” na drobne zakupy.
    </Text>
    <br />
    <Text as="i">
      Środki na karnet, sprzęt i kieszonkowe należy przekazać opiekunowi na
      zbiórce w dniu wyjazdu na zgrupowanie.
    </Text>
  </Box>
);

import PalBox from "@material-ui/core/Box";
import { Card as PalCard } from "@paljs/ui/Card";
import PalCol from "@paljs/ui/Col";
import PalRow from "@paljs/ui/Row";

const Row = PalRow as any;
const Col = PalCol as any;
const Card = PalCard as any;
const Box = PalBox as any;

export { Box, Card, Col, Row };

import { FaSun, FaTimes } from "react-icons/fa";
import {
  Box,
  Button,
  Flex,
  Icon,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import _ from "lodash";

import { Col, Row } from "../../../../components/paljs";
import { TooltipWrapper } from "../../../../components/tooltip";
import { CartDiscount, OrderItemDiscount } from "../../../../types";

import AdditionalInfo from "./additionalInfo";

type WinterOrderItemRowProps = {
  agreementUrl?: string;
  id?: string;
  sport?: string;
  level?: string;
  price: string;
  location?: string;
  name?: string;
  periodName?: string;
  removeFromCart?: any;
  isOutOfStock?: boolean;
  isEditable?: boolean;
  setChurchConsent?: (bool: boolean) => void;
  churchConsent?: boolean;
  setCampConsent?: (bool: boolean) => void;
  campConsent?: boolean;
  setInsuranceConsent?: (bool: boolean) => void;
  insuranceConsent?: boolean;
  setShirtSize?: (size: string) => void;
  shirtSize?: string;
  setDiet?: (diet: string) => void;
  diet?: string;
  setHealthInfo?: (text: string) => void;
  healthInfo?: string;
  setRoommatesInfo?: (text: string) => void;
  roommatesInfo?: string;
  setTransport?: (text: string) => void;
  transport?: string;
  discount?: CartDiscount | OrderItemDiscount;
};
const WinterCampRow = ({
  agreementUrl,
  sport,
  level,
  price,
  name,
  periodName,
  location,
  id,
  removeFromCart,
  isOutOfStock,
  isEditable,
  setChurchConsent,
  churchConsent,
  setInsuranceConsent,
  insuranceConsent,
  setCampConsent,
  campConsent,
  setShirtSize,
  shirtSize,
  setDiet,
  diet,
  setHealthInfo,
  healthInfo,
  setRoommatesInfo,
  roommatesInfo,
  setTransport,
  transport,
  discount,
}: WinterOrderItemRowProps) => {
  const [isBigScreen] = useMediaQuery("(min-width: 1300px)");
  const [periodTitle, periodDatesWithoutFirstParenthesis] = _.split(
    periodName,
    "("
  );
  const periodDates = "(" + periodDatesWithoutFirstParenthesis;
  const showRemoveButton = (!!id && !!removeFromCart) || isOutOfStock;

  const showAdditionalInfo =
    setShirtSize &&
    setChurchConsent &&
    setCampConsent &&
    setInsuranceConsent &&
    isEditable;

  const isAdventure = level === "Adventure";
  return (
    <Box mb={3} padding={2} borderRadius={6}>
      <Row>
        <Col breakPoint={{ sm: isBigScreen ? 2 : 1 }}>
          <Flex align="center">
            {<Icon as={level === "Adventure" ? FaSun : FaSun} mr={2} />}
            {isBigScreen && sport}
          </Flex>
        </Col>
        <Col breakPoint={{ sm: 2 }} className="elipsis">
          {level}
        </Col>
        <Col breakPoint={{ sm: isBigScreen ? 4 : 5 }}>{name ? name : null}</Col>
        <Col breakPoint={{ sm: 2 }}>
          {periodTitle}
          <br />
          {periodDates}
        </Col>
        <Col breakPoint={{ sm: 2 }} style={{ textAlign: "right" }}>
          {price}&nbsp;zł{" "}
          {showRemoveButton && (
            <>
              <Popover isOpen={isOutOfStock} placement="top">
                <PopoverTrigger>
                  <Button
                    size="xs"
                    onClick={() => removeFromCart(id)}
                    mr={2}
                    colorScheme={isOutOfStock ? "red" : undefined}
                  >
                    <Icon as={FaTimes} />
                  </Button>
                </PopoverTrigger>
                <PopoverContent
                  backgroundColor={isOutOfStock ? "pink" : undefined}
                >
                  <PopoverArrow
                    backgroundColor={isOutOfStock ? "pink" : undefined}
                  />
                  <PopoverBody>
                    Brak miejsc. Usuń produkt z koszyka.
                  </PopoverBody>
                </PopoverContent>
              </Popover>
            </>
          )}
          {!isEditable && discount && (
            <>
              <br />
              <b>zniżka -{discount.discountValue.value} zł</b>{" "}
              {TooltipWrapper({ label: discount.reason })}
            </>
          )}
        </Col>
      </Row>
      {showAdditionalInfo && (
        <AdditionalInfo
          location={location}
          isAdventure={isAdventure}
          agreementUrl={agreementUrl}
          setCampConsent={setCampConsent}
          campConsent={campConsent}
          setChurchConsent={setChurchConsent}
          churchConsent={churchConsent}
          setInsuranceConsent={setInsuranceConsent}
          insuranceConsent={insuranceConsent}
          setShirtSize={setShirtSize}
          shirtSize={shirtSize}
          setDiet={setDiet}
          diet={diet}
          setHealthInfo={setHealthInfo}
          healthInfo={healthInfo}
          setRoommatesInfo={setRoommatesInfo}
          roommatesInfo={roommatesInfo}
          setTransport={setTransport}
          transport={transport}
          isEditable={isEditable}
          discount={discount}
        />
      )}
    </Box>
  );
};

export default WinterCampRow;

export const WinterCampDisclaimer = () => (
  <Box mb={3} fontSize={"0.8rem"} color="grey">
    <Text as="i">
      Cena nie zawiera kosztów karnetu i wypożyczenia sprzętu oraz
      “kieszonkowego” na drobne zakupy.
    </Text>
    <br />
    <Text as="i">
      Środki na karnet, sprzęt i kieszonkowe należy przekazać opiekunowi na
      zbiórce w dniu wyjazdu na zgrupowanie.
    </Text>
  </Box>
);

export const GET_SUMMER_CAMPS_2023 = /* GraphQL */ `
  query getSummerCamps2023 {
    adventure2023: products(
      category: "Adventure"
      period: { start: "2024-01-01", end: "2024-12-31" }
    ) {
      ... on IProduct {
        sku
        name
        stock {
          available
        }
      }
    }

    funActive2023: products(
      category: "Fun Active"
      period: { start: "2024-01-01", end: "2024-12-31" }
    ) {
      ... on IProduct {
        sku
        name
        stock {
          available
        }
      }
    }

    sailing2024: products(
      category: "Żeglarski"
      period: { start: "2024-01-01", end: "2024-12-31" }
    ) {
      ... on IProduct {
        sku
        name
        stock {
          available
        }
      }
    }
  }
`;

import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Box, Button } from "@chakra-ui/react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import { AuthWrapper, Email, Password } from "../../components/auth";
import Loading from "../../components/loading";
import config from "../../config";
import { useValidEmail, useValidPassword } from "../../hooks/useAuthHooks";
import { AuthContext } from "../../utils/authContext";
const bgImgUrl = config.Background.signIn;

const useStyles = makeStyles({
  hover: {
    "&:hover": { cursor: "pointer" },
  },
});

const SignIn: React.FunctionComponent = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const auth = useContext(AuthContext);

  const initialEmail = "";
  const initialPassword = "";

  const { email, setEmail, emailIsValid } = useValidEmail(initialEmail);
  const { password, setPassword, passwordIsValid } =
    useValidPassword(initialPassword);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const isValid =
    !emailIsValid ||
    email.length === 0 ||
    !passwordIsValid ||
    password.length === 0;

  const clearFields = () => {
    setEmail(initialEmail);
    setPassword(initialPassword);
  };

  const signInClicked = async (
    customEmail?: string,
    customPassword?: string
  ) => {
    try {
      await auth.signInWithEmail(
        customEmail || email,
        customPassword || password
      );
      navigate("/");
    } catch (err: any) {
      if (err.code === "UserNotConfirmedException") {
        navigate("/verify");
      } else {
        if (err.message === "Incorrect username or password.") {
          setError("Błędny email lub hasło");
          clearFields();
        } else if (err.code === "PasswordResetRequiredException") {
          navigate("/forgotpassword");
        } else if (err.message === "NewPasswordRequired") {
          navigate("/newpassword");
        } else {
          console.log(err.code);
          setError(err.message);
        }
      }
    }
  };

  useEffect(() => {
    if (searchParams.has("credentials")) {
      try {
        const credentials = JSON.parse(
          atob(searchParams.get("credentials") as string)
        );

        if (isLoading) {
          signInClicked(credentials.email, credentials.password);
        }
      } catch (err) {
        setIsLoading(false);
      }
    } else if (isLoading) {
      setIsLoading(false);
    }
  }, []);

  const passwordResetClicked = async () => {
    navigate("/requestcode");
  };

  return (
    <AuthWrapper bgImgUrl={bgImgUrl} title="Zaloguj się do panelu Sportteam!">
      {isLoading && <Loading />}
      <Box width="80%" m={1}>
        <Email
          emailIsValid={emailIsValid}
          setEmail={setEmail}
          value={email}
          enterPressed={() => signInClicked()}
        />
      </Box>
      <Box width="80%" m={1}>
        <Password
          label="Hasło"
          passwordIsValid={passwordIsValid}
          setPassword={setPassword}
          value={password}
          enterPressed={() => signInClicked()}
        />
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Box onClick={passwordResetClicked} mt={2}>
            <Typography className={classes.hover} variant="body2">
              Nie pamiętasz hasła?
            </Typography>
          </Box>
        </Grid>
      </Box>
      <Box mt={2}>
        <Typography color="error" variant="body2">
          {error}
        </Typography>
      </Box>
      <Box mt={2}>
        <Grid container direction="row" justifyContent="center">
          <Box m={1}>
            <Button
              colorScheme="blue"
              disabled={isValid}
              onClick={() => signInClicked()}
              type="submit"
            >
              Zaloguj
            </Button>
          </Box>
        </Grid>
      </Box>
      <Box mt={2}>
        <Button onClick={() => navigate("/signup")}>Utwórz nowe konto</Button>
      </Box>
      {/* <Box mt={2}>
        <Grid container direction="row" justifyContent="center">
          <Box m={1}>
            <Button color="primary"  onClick={() => auth.signInWithGoogle()}>
              Zaloguj z Google
            </Button>
          </Box>
        </Grid>
      </Box> */}
    </AuthWrapper>
  );
};

export default SignIn;

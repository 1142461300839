import { useState } from "react";
import { Button, HStack, Stack } from "@chakra-ui/react";

import { RenderEmailAndPhone, RenderNameSurname } from "../../components/form";
import { validateOnlyLetters, validatePhoneNumber } from "../../utils/helpers";

type AddGuardianButtonProps = {
  isDisabled: boolean;
  addGuardian: () => void;
};
export const AddGuardianButton = ({
  addGuardian,
  isDisabled,
}: AddGuardianButtonProps) => {
  return (
    <Button
      bg={"gray.400"}
      color={"white"}
      _hover={{
        bg: "gray.500",
      }}
      isDisabled={isDisabled}
      onClick={() => {
        addGuardian();
      }}
    >
      Dodaj osobę kontaktową
    </Button>
  );
};

type GuardianComponentProps = {
  guardian: any;
  setGuardian: (guardian: any) => void;
  removeGuardian: (guardian: any) => void;
  isLoading: boolean;
};

const Guardian = ({
  guardian,
  setGuardian,
  removeGuardian,
  isLoading,
}: GuardianComponentProps) => {
  const saved = isLoading;
  const isPlaceholder = guardian.id === "addGuardianPlaceholder";
  const canDelete = true;

  const [name, setName] = useState(guardian.name);
  const hasName = !!name || name !== "";
  const isNameValid = validateOnlyLetters(name || "");

  const [surname, setSurname] = useState(guardian.surname);
  const hasSurname = !!surname || surname !== "";
  const isSurnameValid = validateOnlyLetters(surname || "");

  const [email, setEmail] = useState(guardian.contactInfo.email);
  const hasEmail = !!email || email !== "";
  const isEmailValid = true;

  const [phone, setPhone] = useState(guardian.contactInfo.phone);
  const hasPhone = !!phone || phone !== "";
  const isPhoneValid = validatePhoneNumber(phone);

  const hasValuesFilled = hasName && hasSurname && hasPhone && hasEmail;

  const isDisabled =
    !hasValuesFilled ||
    !isNameValid ||
    !isSurnameValid ||
    !isPhoneValid ||
    !isEmailValid;

  return (
    <Stack spacing={4}>
      <HStack>
        {RenderNameSurname({
          name,
          surname,
          setName,
          setSurname,
          saved,
          isNameValid,
          isSurnameValid,
        })}
      </HStack>
      <HStack>
        {RenderEmailAndPhone({
          email,
          setEmail,
          phone,
          setPhone,
          saved,
          isEmailDisabled: false,
          isPhoneValid,
          isEmailValid,
        })}
      </HStack>
      <HStack spacing={10}>
        <Button
          bg={"blue.400"}
          color={"white"}
          _hover={{
            bg: "blue.500",
          }}
          isDisabled={saved || isDisabled}
          onClick={() => {
            const newguardian = {
              ...guardian,
              ...{ name },
              ...{ surname },
              contactInfo: {
                ...{ email },
                ...{ phone },
              },
              isGuardianStepDone: true,
            };
            setGuardian(newguardian);
          }}
        >
          Zapisz
        </Button>
        {removeGuardian && !isPlaceholder && (
          <Button
            colorScheme="red"
            isDisabled={saved || isDisabled || !canDelete}
            onClick={() => {
              removeGuardian(guardian);
            }}
          >
            Usuń profil opiekuna
          </Button>
        )}
        {removeGuardian && isPlaceholder && (
          <Button
            colorScheme="red"
            isDisabled={!isPlaceholder}
            onClick={() => {
              removeGuardian(guardian);
            }}
          >
            Usuń tymczasowy profil opiekuna
          </Button>
        )}
      </HStack>
    </Stack>
  );
};
export default Guardian;

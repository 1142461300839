import React from "react";
import { Flex } from "@chakra-ui/react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import _ from "lodash";

import Heading from "../heading";
import Logo from "../logo";

export const Email: React.FunctionComponent<{
  emailIsValid: boolean;
  setEmail: (_: string) => void;
  value?: string;
  enterPressed?: () => any;
}> = ({ value, emailIsValid, setEmail, enterPressed }) => {
  return (
    <TextField
      onKeyDown={({ key }) =>
        key === "Enter" && enterPressed ? enterPressed() : undefined
      }
      value={value}
      fullWidth
      variant="outlined"
      label={emailIsValid ? "Email" : "Nieprawidłowy Email"}
      error={!emailIsValid}
      onChange={(evt: React.ChangeEvent<HTMLTextAreaElement>) => {
        setEmail(_.replace(evt.target.value, " ", ""));
      }}
    />
  );
};

export const Password: React.FunctionComponent<{
  value?: string;
  label: string;
  passwordIsValid: boolean;
  setPassword: (_: string) => void;
  enterPressed?: () => any;
}> = ({ label, passwordIsValid, setPassword, value, enterPressed }) => {
  return (
    <TextField
      onKeyDown={({ key }) =>
        key === "Enter" && enterPressed ? enterPressed() : undefined
      }
      value={value}
      fullWidth
      type="password"
      variant="outlined"
      label={passwordIsValid ? label : "Minimum 8 znaków"}
      error={!passwordIsValid}
      onChange={(evt: React.ChangeEvent<HTMLTextAreaElement>) => {
        setPassword(evt.target.value);
      }}
    />
  );
};

export const Username: React.FunctionComponent<{
  usernameIsValid: boolean;
  setUsername: (_: string) => void;
  enterPressed?: () => any;
}> = ({ usernameIsValid, setUsername, enterPressed }) => {
  return (
    <TextField
      onKeyDown={({ key }) =>
        key === "Enter" && enterPressed ? enterPressed() : undefined
      }
      fullWidth
      variant="outlined"
      label={usernameIsValid ? "Nazwa Użytkownika" : "Minimum 8 znaków"}
      error={!usernameIsValid}
      onChange={(evt: React.ChangeEvent<HTMLTextAreaElement>) => {
        setUsername(evt.target.value);
      }}
    />
  );
};

export const Code: React.FunctionComponent<{
  codeIsValid: boolean;
  setCode: (_: string) => void;
  enterPressed?: () => any;
}> = ({ codeIsValid, setCode, enterPressed }) => {
  return (
    <TextField
      onKeyDown={({ key }) =>
        key === "Enter" && enterPressed ? enterPressed() : undefined
      }
      fullWidth
      variant="outlined"
      label={codeIsValid ? "Kod z wiadomości email" : "Minimum 6 znaków"}
      error={!codeIsValid}
      onChange={(evt: React.ChangeEvent<HTMLTextAreaElement>) => {
        setCode(evt.target.value);
      }}
    />
  );
};

const useStyles = makeStyles({
  root: {
    height: "100vh",
  },
  hover: {
    "&:hover": { cursor: "pointer" },
  },
});

type AuthProps = {
  bgImgUrl: string;
  title: string;
  children: any;
};

export const AuthWrapper: React.FC<AuthProps> = ({
  bgImgUrl,
  title,
  children,
}) => {
  const classes = useStyles();

  return (
    <>
      <Flex
        w={"full"}
        h={"100vh"}
        backgroundImage={`url(${bgImgUrl})`}
        backgroundSize={"cover"}
        backgroundPosition={"center center"}
      >
        <Grid
          className={classes.root}
          container
          direction="row"
          justify="center"
          alignItems="center"
        >
          <Grid
            xs={11}
            sm={6}
            lg={4}
            container
            direction="row"
            justify="center"
            alignItems="center"
            item
          >
            <Paper style={{ width: "100%", padding: 16 }}>
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
              >
                <br />
                <Logo />
                <br />
                <Heading>{title}</Heading>
                <>{children}</>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Flex>
    </>
  );
};

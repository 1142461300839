import { useState } from "react";
import { FaQuestion } from "react-icons/fa";
import { RiVipCrownLine } from "react-icons/ri";
import {
  Box,
  Button,
  Divider,
  Flex,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useMediaQuery,
} from "@chakra-ui/react";
import _ from "lodash";

import { Col, Row } from "../../../../components/paljs";
import { MembershipType } from "../../../../types";

const TableDescription = () => {
  return (
    <Box fontSize={"0.9rem"} textAlign="center">
      Korzystając z oferty zajęć UKS Sportteam zostajesz członkiem Klubu.
      <br />
      Możesz wybrać wariant członkostwa Standard lub Premium. <br />
      Warianty różnią się wysokością składki członkowskiej i zniżkami na ofertę
      Sportteam. <br />
      <Box fontSize={"md"}>
        <b>Składka jest jednorazowa na cały rok szkolny!</b>
      </Box>
    </Box>
  );
};

const TableComponent = () => {
  return (
    <Table variant="simple" size="sm">
      <Thead>
        <Tr>
          <Th>Zniżka</Th>
          <Th>Pakiet STANDARD</Th>
          <Th>Pakiet PREMIUM</Th>
        </Tr>
      </Thead>
      <Tbody>
        <Tr>
          <Td fontSize={"0.7rem"}>Imprezy urodzinowe i obozy Sporteam</Td>
          <Td>-30 zł</Td>
          <Td>-80 zł</Td>
        </Tr>
        <Tr>
          <Td fontSize={"0.7rem"}>
            Klubowe stroje sportowe, sprzęt sportowy i rekreacyjny (zakupy
            powyżej 100 zł.)
          </Td>
          <Td>-5 zł</Td>
          <Td>-20 zł</Td>
        </Tr>
        <Tr>
          <Td fontSize={"0.7rem"}>Udział w zawodach tenisowych i pływackich</Td>
          <Td>-5 zł</Td>
          <Td>-10 zł</Td>
        </Tr>
      </Tbody>
    </Table>
  );
};
export default ({
  type,
  price,
  name,
}: {
  type?: MembershipType;
  price: number;
  name?: string;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isBigScreen] = useMediaQuery("(min-width: 1300px)");
  return (
    <Box mb={3}>
      <Row>
        <Col breakPoint={{ sm: isBigScreen ? 2 : 1 }}>
          <Flex align="center">
            {<Icon as={RiVipCrownLine} mr={2} />} Członkostwo
          </Flex>
        </Col>
        <Col breakPoint={{ sm: 2 }} className="elipsis">
          {_.capitalize(type)}
        </Col>

        <Col breakPoint={{ sm: isBigScreen ? 6 : 7 }}>
          <Button size="xs" onClick={() => setIsOpen(true)} mr={2}>
            {isBigScreen ? "Szczegóły" : <Icon as={FaQuestion} />}
          </Button>
          {name ? (
            <Text as="i" color={"gray.500"}>
              ({name})
            </Text>
          ) : null}
        </Col>

        <Col breakPoint={{ sm: 2 }} style={{ textAlign: "right" }}>
          {price}&nbsp;zł
        </Col>
      </Row>

      <Modal size="xl" isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Szczegóły</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <TableDescription />
            <Divider my={5} />

            <TableComponent />
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={() => setIsOpen(false)}>
              Zamknij
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

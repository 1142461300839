/* eslint-disable import/no-anonymous-default-export */
import { Box, Text } from "@chakra-ui/react";
import _ from "lodash";

import {
  OrderItemActivityBundle,
  OrderItemGroup,
  OrderItemMembership,
  OrderItemSummerCamp,
  OrderItemWinterCamp,
} from "../../../../types";
import OrderItemActivityRow from "../order-item-activity-row";
import OrderItemMembershipRow from "../order-item-membership-row";
import OrderItemSummerCampRow from "../order-item-summer-camp-row";
import OrderItemWinterCampRow from "../order-item-winter-camp-row";

const levelOptions = [
  { label: "Początkujący", value: "1" },
  { label: "Średniozaawansowany", value: "2" },
  { label: "Zaawansowany", value: "3" },
];

const getWinterLevelName = (level: string): string => {
  const option = _.find(levelOptions, (option) => option.value == level);
  return option ? option.label : "błąd";
};

export default ({ itemGroup }: { itemGroup: OrderItemGroup }) => {
  // const [membershipsBundles, activitiesBundles] = _.partition(
  //   itemGroup.bundles,
  //   (bundle) => bundle.__typename === 'OrderItemMembership'
  // )
  const [
    membershipsBundles,
    activitiesBundles,
    winterCampBundles,
    summerCampBundles,
  ] = _.reduce(
    itemGroup.bundles,
    (accumulator, bundle) => {
      if (bundle.__typename === "OrderItemMembership")
        accumulator[0].push(bundle);
      if (bundle.__typename === "OrderItemActivityBundle")
        accumulator[1].push(bundle);
      if (bundle.__typename === "OrderItemWinterCamp")
        accumulator[2].push(bundle);
      if (bundle.__typename === "OrderItemSummerCamp")
        accumulator[3].push(bundle);
      return accumulator;
    },
    [[], [], [], []] as [
      OrderItemMembership[],
      OrderItemActivityBundle[],
      OrderItemWinterCamp[],
      OrderItemSummerCamp[]
    ]
  );

  return (
    <>
      <Box mb={3}>
        <Text
          as={"b"}
        >{`${itemGroup.participant?.name} ${itemGroup.participant?.surname}`}</Text>
      </Box>
      {_.map(membershipsBundles, (bundle: any, idx) => (
        <Box key={idx}>
          <OrderItemMembershipRow
            type={bundle.membershipType}
            price={bundle.price.value}
          />
        </Box>
      ))}
      {_.map(activitiesBundles, (bundle: any, idx) => (
        <Box key={idx}>
          <OrderItemActivityRow
            sport={bundle.sport}
            type={bundle.type}
            price={_.get(bundle, "price.value", null)}
            location={bundle.location}
            name={
              bundle.participant
                ? `${bundle.participant.name} ${bundle.participant.surname}`
                : undefined
            }
            bundle={bundle} //fcuk
          />
        </Box>
      ))}
      {_.map(winterCampBundles, (bundle: any, idx) => (
        <Box key={idx}>
          <OrderItemWinterCampRow
            sport={bundle.sport}
            level={getWinterLevelName(bundle.level)}
            price={_.get(bundle, "price.value", null)}
            discount={_.first(bundle.discounts)}
            name={
              bundle.participant
                ? `${bundle.participant.name} ${bundle.participant.surname}`
                : undefined
            }
            periodName={bundle.name || "błąd?"}
            location="Zgrupowanie SKI & SNB 2023 "
          />
        </Box>
      ))}
      {_.map(summerCampBundles, (bundle: any, idx) => (
        <Box key={idx}>
          <OrderItemSummerCampRow
            sport={"Camp 2024"}
            level={bundle.profile}
            price={_.get(
              bundle,
              "price.value.total",
              _.get(bundle, "price.value", null)
            )}
            discount={_.first(bundle.discounts)}
            name={
              bundle.participant
                ? `${bundle.participant.name} ${bundle.participant.surname}`
                : undefined
            }
            periodName={bundle.name || "błąd?"}
            location="Star-Dadaj "
          />
        </Box>
      ))}
    </>
  );
};

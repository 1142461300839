import { useNavigate } from "react-router-dom";
import {
  Button,
  Center,
  ListItem,
  Text,
  UnorderedList,
  useBreakpointValue,
  useToast,
} from "@chakra-ui/react";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { CardBody } from "@paljs/ui/Card";
import { useMutation, useQuery } from "graphql-hooks";
import _ from "lodash";

import { GenericErrorAlert } from "../../components/alerts";
import Layout from "../../components/layout";
import Loading from "../../components/loading";
import { Card, Col, Row } from "../../components/paljs";
import {
  ADD_WINTER_CAMP_CART_ITEM,
  REMOVE_CART_ITEM,
  UPDATE_CHILD,
} from "../../data/mutations";
import { GET_WINTER } from "../../data/queries";
import { GetWinterQuery, MutationAddWinterCampCartItemArgs } from "../../types";

import AddWinterParticipant from "./winterParticipantCard";

const header = "⛷️ Zgrupowanie SKI&SNB Sportteam 2023 🏂";

const Description = () => {
  const lineHeight = 1.5;
  return (
    <>
      <Text lineHeight={lineHeight}>
        Zgrupowanie SKI&SNB Sportteam 2023 to zimowy obóz sportowy
        narciarsko-snowboardowy dla klubowiczów UKS Sportteam.
      </Text>
      <br />

      <Text lineHeight={lineHeight}>
        Zgrupowanie odbędzie się w dwóch turnusach:
      </Text>
      <UnorderedList>
        <ListItem>12.02.2023 - 18.02.2023</ListItem>
        <ListItem>18.02.2023 - 24.02.2023</ListItem>
      </UnorderedList>
      <br />
      <Text lineHeight={lineHeight}>
        Uczestnicy zgrupowania będą nabywali umiejętności lub doskonalili swój
        poziom w jeździe na nartach lub snowboardzie.
      </Text>
      <br />

      <Text lineHeight={lineHeight}>
        Zajęcia będą odbywały się w grupach o poziomie dopasowanym do
        umiejętności uczestników.
      </Text>
      <br />
      <Text>Zapraszamy do zapisów!</Text>
    </>
  );
};
export default () => {
  const navigate = useNavigate();
  const toast = useToast();

  const { loading, data, error } = useQuery<GetWinterQuery>(GET_WINTER, {
    refetchAfterMutations: [UPDATE_CHILD, REMOVE_CART_ITEM],
    useCache: false,
  });
  const [addWinterCampToCart] = useMutation<MutationAddWinterCampCartItemArgs>(
    ADD_WINTER_CAMP_CART_ITEM
  );

  type ItemInfo = {
    participantId: string;
    periodId: string;
    type: string;
    level: string;
  };
  const addToCart = (itemInfo: ItemInfo) => {
    addWinterCampToCart({
      variables: {
        sku: itemInfo.periodId,
        childId: itemInfo.participantId,
        input: {
          level: itemInfo.level,
          sport: itemInfo.type,
        },
      },
    });
    toast({
      title: "Zapis dodany do koszyka!",
      description: (
        <>
          <br />
          <Button
            fontSize={"1.4rem"}
            color="white"
            variant="link"
            leftIcon={<ShoppingCartIcon />}
            onClick={() => navigate("/cart")}
          >
            Kliknij tutaj aby przejść do koszyka
          </Button>
          <br />
        </>
      ),
      status: "success",
      duration: 5000,
      isClosable: true,
      size: "xl",
      containerStyle: {
        fontSize: "1.8rem",
      },
    });
  };

  const colBreakPoint = { sm: 12, lg: 6, xxxl: 6 };
  return (
    <Layout>
      <Center>
        <Row>
          <Col breakPoint={colBreakPoint}>
            <Card>
              <CardBody>
                <Text
                  fontWeight={700}
                  lineHeight={1.2}
                  fontSize={useBreakpointValue({ base: "3xl", md: "5xl" })}
                  pb={6}
                >
                  {header}
                </Text>
                <Description />
              </CardBody>
            </Card>
          </Col>
          <Col breakPoint={colBreakPoint}>
            {loading || error || !data || _.isEmpty(data) ? (
              <Card>
                <CardBody>
                  {loading && <Loading />}
                  {error && <GenericErrorAlert />}
                </CardBody>
              </Card>
            ) : (
              <AddWinterParticipant
                navigate={navigate}
                addToCart={addToCart}
                participants={data.me.children}
              />
            )}
          </Col>
        </Row>
      </Center>
    </Layout>
  );
};

import _ from "lodash";

import RowedDescription from "../../components/rowedDescription";

const disabledRows = (rows: any) =>
  _.map(rows, (row, index) =>
    row[2] === "Brak miejsc" ? index : null
  ) as unknown as number[];

export const summer2023FunActive = (rows: any) => ({
  header: "LETNIE CAMPY 2024",
  subheader: RowedDescription({
    rows: [
      ["Camp Fun Active", "(dzieci 6-12 lat)"],
      ["Zakwaterowanie", " Ośrodek Star-Dadaj nad jeziorem Dadaj. (domki)"],
    ],
    suffixRow:
      "Program zajęć oraz atrakcje dostosowane do wieku i możliwości dzieci.",
  }),
  body: RowedDescription({
    rows,
    disabledRows: disabledRows(rows),
  }),
  path: "/summer2024/funActive",
  images: ["/images/funActive.jpeg"],
  accent: "Warning",
});

export const summer2023Adventure = (rows: any) => {
  return {
    header: "LETNIE CAMPY 2024",
    subheader: RowedDescription({
      rows: [
        ["Camp Sport & Adventure", "(młodzież 12-18 lat)"],
        [
          "Zakwaterowanie",
          "Ośrodek Star-Dadaj nad jeziorem Dadaj. (nowoczesne namioty przy plaży Sportteam)",
        ],
      ],
      suffixRow:
        "Program zajęć oraz atrakcje przygotowane specjalnie dla nastolatków.",
    }),
    body: RowedDescription({
      rows,
      disabledRows: disabledRows(rows),
    }),
    path: "/summer2024/adventure",
    images: ["/images/adventure.jpeg"],
    accent: "Success",
  };
};

export const summer2024Sailing = (rows: any) => {
  return {
    header: "LETNIE CAMPY 2024",
    subheader: RowedDescription({
      rows: [
        ["Camp Żeglarski", "(młodzież 14-18 lat)"],
        ["Zakwaterowanie", "Gościniec pod Dębem nad Jeziorem Dadaj. (pokoje)"],
      ],
      suffixRow:
        "Program zajęć - kurs na patent żeglarza jachtowego oraz dla chętnych kurs sternika motorowodnego.",
    }),
    body: RowedDescription({
      rows,
      disabledRows: disabledRows(rows),
    }),
    path: "/summer2024/sailing",
    images: ["/images/sailing.jpeg"],
    accent: "Success",
  };
};

export const winter2023 = {
  header: "Zimowy obóz narciarsko-snowboardowy 2023",
  body: "Zapisy na Zimowisko 2023 już otwarte!",
  path: "/winter",
  images: ["/images/zima.jpg"],
  accent: "Info",
};

export const sem_2_2022_2023 = {
  header: "Semester 2 2022/2023",
  body: "W koszyku znajdziesz wybrane dla Ciebie zapisy na zajęcia w semestrze 2.",
  customButtonLabel: "Przejdź do koszyka",
  path: "/cart",
  images: ["/images/tenis.jpeg", "/images/plywanie.jpeg"],
  accent: "Info",
  isDisabled: true,
};

export const sem_1_2023_2024 = {
  header: "Semester 1 2023/2024",
  body: "W koszyku znajdziesz wybrane dla Ciebie zapisy na zajęcia w semestrze 1.",
  customButtonLabel: "Przejdź do koszyka",
  path: "/cart",
  images: ["/images/tenis.jpeg", "/images/plywanie.jpeg"],
  accent: "Info",
  isDisabled: true,
};

export const sem_2_2023_2024 = {
  header: "Semester 2 2023/2024",
  body: "W koszyku znajdziesz wybrane dla Ciebie zapisy na zajęcia w semestrze 2.",
  customButtonLabel: "Przejdź do koszyka",
  path: "/cart",
  images: ["/images/tenis.jpeg", "/images/plywanie.jpeg"],
  accent: "Info",
  isDisabled: true,
};

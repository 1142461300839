export const UPDATE_ME = /* GraphQL */ `
  mutation UpdateMe($input: AccountUpdateInput!) {
    updateAccount(input: $input) {
      id
      name
      surname
      contactInfo {
        email
        phone
      }
      pesel
      address {
        street
        code
        city
      }
      isIncomplete
      consents {
        knowledge
        health
        healthProcessing
        email
        text
        call
      }
    }
  }
`;
export const UPDATE_CHILD = /* GraphQL */ `
  mutation UpdateChild(
    $accountId: ID!
    $childId: ID!
    $input: AccountChildUpdateInput!
  ) {
    updateChild(accountId: $accountId, childId: $childId, input: $input) {
      id
      children {
        id
        name
        surname
        health
        pesel
        address {
          street
          code
          city
        }
        birthday
        isIncomplete
      }
    }
  }
`;

export const ADD_CHILD = /* GraphQL */ `
  mutation AddChild($input: AccountChildUpdateInput!) {
    addChild(input: $input) {
      id
      children {
        id
        name
        surname
        health
        pesel
        address {
          street
          code
          city
        }
        birthday
        isIncomplete
      }
    }
  }
`;

export const REMOVE_CHILD = /* GraphQL */ `
  mutation RemoveChild($childId: ID!) {
    removeChild(childId: $childId) {
      id
      children {
        id
        name
        surname
        health
        pesel
        address {
          street
          code
          city
        }
        birthday
        isIncomplete
      }
    }
  }
`;

export const UPDATE_GUARDIAN = /* GraphQL */ `
  mutation UpdateGuardian(
    $guardianId: ID!
    $input: AccountGuardianUpdateInput!
  ) {
    updateGuardian(guardianId: $guardianId, input: $input) {
      id
      guardians {
        id
        name
        surname
        contactInfo {
          email
          phone
        }
      }
    }
  }
`;

export const ADD_GUARDIAN = /* GraphQL */ `
  mutation AddGuardian($input: AccountGuardianUpdateInput!) {
    addGuardian(input: $input) {
      id
      guardians {
        id
        name
        surname
        contactInfo {
          email
          phone
        }
      }
    }
  }
`;

export const REMOVE_GUARDIAN = /* GraphQL */ `
  mutation RemoveGuardian($guardianId: ID!) {
    removeGuardian(guardianId: $guardianId) {
      id
      guardians {
        id
        name
        surname
        contactInfo {
          email
          phone
        }
      }
    }
  }
`;

export const ADD_MEMBERSHIP_ITEM = /* GraphQL */ `
  mutation AddMembership(
    $accountId: ID!
    $childId: ID
    $input: CartItemMembershipInput!
  ) {
    addMembershipCartItem(
      accountId: $accountId
      childId: $childId
      input: $input
    ) {
      id
      paymentOptions {
        id
      }
      items {
        ... on CartItemMembership {
          price {
            value
          }
          participant {
            ... on Account {
              name
            }
            ... on Child {
              name
            }
          }
        }
      }
    }
  }
`;
export const PLACE_ORDER = /* GraphQL */ `
  mutation PlaceOrder($input: OrderPlacementInput!) {
    placeOrder(input: $input) {
      id
      payments {
        id
        dueDate
        link
        status
        price {
          value
        }
      }
    }
  }
`;

export const UPDATE_CUSTOM_DATA = /* GraphQL */ `
  mutation UpdateCustomData($itemId: ID!, $data: JSONObject!) {
    cartItemUpdateCustomData(input: [{ itemId: $itemId, data: $data }]) {
      id
      paymentOptions {
        id
        price {
          value
        }
      }
    }
  }
`;

export const GENERATE_PAYMENT_LINK = /* GraphQL */ `
  mutation GeneratePaymentLink($orderId: ID!, $paymentId: ID!) {
    generatePaymentLink(orderId: $orderId, paymentId: $paymentId) {
      link
    }
  }
`;

export const ADD_WINTER_CAMP_CART_ITEM = `
  mutation AddCampItem($sku: ID!, $childId: ID, $input: WinterCampInput!) {
    addWinterCampCartItem(sku: $sku, childId: $childId, input: $input) {
      items {
        ...on CartItemWinterCamp {
          id
          name
        }
        __typename
      }
    }
  }
`;
export const REMOVE_CART_ITEM = `
mutation RemoveFromCart($itemId: ID!){
  removeFromCart(itemIds: [$itemId]) {
    items {
      __typename
    }
  }
}`;

export const ADD_DISCOUNT_CODE = /* GraphQL */ `
  mutation cartAddDiscountCode($code: String!) {
    cartAddDiscountCode(code: $code) {
      id
    }
  }
`;

export const REMOVE_DISCOUNT_CODE = /* GraphQL */ `
  mutation cartRemoveDiscountCode($code: String!) {
    cartRemoveDiscountCode(code: $code) {
      id
    }
  }
`;

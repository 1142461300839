import { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  Textarea,
  VStack,
} from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import _ from "lodash";

import {
  campAgreement,
  insuranceAgreement,
} from "../../../../components/agreements";
import { Col, Row } from "../../../../components/paljs";
import { CartDiscount, OrderItemDiscount } from "../../../../types";

type SelectOption = {
  value: string;
  label: string;
};
type ShirtSelectProps = {
  options: SelectOption[];
};
const hoodieSizeOptions = [
  { value: "S", label: "S" },
  { value: "M", label: "M" },
  { value: "L", label: "L" },
  { value: "XL", label: "XL" },
  { value: "XXL", label: "XXL" },
];

const shirtSizeOptions = [
  { value: "110", label: "110 cm/4 lata" },
  { value: "122", label: "122 cm/6 lat" },
  { value: "134", label: "134 cm/8 lat" },
  { value: "146", label: "146 cm/10 lat" },
  { value: "158", label: "158 cm/ 12 lat" },
  ...hoodieSizeOptions,
];

const dietOptions = [
  { value: null, label: "Brak" },
  { value: "glutenFree", label: "Bezglutenowa" },
  { value: "dairyFree", label: "Bezmleczna" },
  { value: "glutenFreeAndDairyFree", label: "Bezglutenowa i Bezmleczna" },
  { value: "vegetarian", label: "Wegetariańska" },
];

const transportOptions = [
  { value: "busDouble", label: "Dojazd i powrót autokarem (dopłata 285zł)" },
  {
    value: "busThereOnly",
    label: "Dojazd autokarem, powrót własny (dopłata 170zł)",
  },
  {
    value: "busReturnOnly",
    label: "Powrót autokarem, dojazd własny (dopłata 170zł)",
  },
  { value: "noBus", label: "Dojazd i powrót własny" },
];

const colStyle = {
  display: "flex",
  alignItems: "center",
  marginBottom: 8,
};

type AdditionalInfoProps = {
  agreementUrl?: string;
  location?: string;
  isEditable?: boolean;
  isAdventure: boolean;
  setChurchConsent?: (bool: boolean) => void;
  churchConsent?: boolean;
  setCampConsent?: (bool: boolean) => void;
  campConsent?: boolean;
  setInsuranceConsent?: (bool: boolean) => void;
  insuranceConsent?: boolean;
  setShirtSize?: (size: string) => void;
  shirtSize?: string;
  setDiet?: (diet: string) => void;
  diet?: string;
  setHealthInfo?: (text: string) => void;
  healthInfo?: string;
  setRoommatesInfo?: (text: string) => void;
  roommatesInfo?: string;
  setTransport?: (text: string) => void;
  transport?: string;
  discount?: CartDiscount | OrderItemDiscount;
};
const AdditionalInfo = ({
  agreementUrl,
  location,
  isEditable,
  isAdventure,
  setChurchConsent,
  churchConsent,
  setInsuranceConsent,
  insuranceConsent,
  setCampConsent,
  campConsent,
  setShirtSize,
  shirtSize,
  setDiet,
  diet,
  setHealthInfo,
  healthInfo,
  setRoommatesInfo,
  roommatesInfo,
  setTransport,
  transport,
}: AdditionalInfoProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isTextModalOpen, setIsTextModalOpen] = useState(false);
  const [textRoommatesInput, setTextRoommatesInput] = useState(roommatesInfo);

  const [isHealthModalOpen, setIsHealthModalOpen] = useState(false);
  const [textHealthInput, setTextHealthInput] = useState(healthInfo);

  const StackComponent = VStack;

  const ShirtSelect = ({ options }: ShirtSelectProps) => (
    <Select
      menuPortalTarget={document.getElementById("root")}
      menuPosition="fixed"
      size="sm"
      classNamePrefix="multiselect"
      isInvalid={!shirtSize}
      className={"fullOpacity"}
      placeholder="Wybierz opcję"
      isDisabled={!isEditable}
      onChange={(values: any) => {
        setShirtSize && setShirtSize(_.get(values, "value"));
      }}
      value={
        shirtSize ? _.find(options, ({ value }) => value === shirtSize) : null
      }
      options={options}
    ></Select>
  );

  const DietSelect = () => (
    <Select
      menuPortalTarget={document.getElementById("root")}
      menuPosition="fixed"
      size="sm"
      classNamePrefix="multiselect"
      className={"fullOpacity"}
      placeholder="Wybierz opcję"
      isDisabled={!isEditable}
      onChange={(values: any) => {
        setDiet && setDiet(_.get(values, "value"));
      }}
      value={
        diet
          ? _.find(dietOptions, ({ value }) => value === diet)
          : dietOptions[0]
      }
      options={dietOptions}
    ></Select>
  );

  const CampAgreementCheckbox = () => (
    <Checkbox
      isChecked={!!campConsent}
      isInvalid={!campConsent}
      onChange={() => {
        setCampConsent && setCampConsent(!campConsent);
      }}
      isDisabled={!isEditable}
    ></Checkbox>
  );
  const InsuranceAgreementCheckbox = () => (
    <Checkbox
      isChecked={!!insuranceConsent}
      isInvalid={!insuranceConsent}
      onChange={() => {
        setInsuranceConsent && setInsuranceConsent(!insuranceConsent);
      }}
      isDisabled={!isEditable}
    ></Checkbox>
  );
  const PositiveChurchConsentCheckbox = () => (
    <Checkbox
      isChecked={
        churchConsent === true || churchConsent === false
          ? churchConsent
          : undefined
      }
      isInvalid={churchConsent === null}
      className="checkbox"
      onChange={() => {
        setChurchConsent && setChurchConsent(true);
      }}
      isDisabled={!isEditable}
      mr={5}
    >
      <Text fontSize="sm">Wyrażam zgodę</Text>
    </Checkbox>
  );
  const NegativeChurchConsentCheckbox = () => (
    <Checkbox
      isChecked={
        churchConsent === true || churchConsent === false
          ? !churchConsent
          : undefined
      }
      isInvalid={churchConsent === null}
      className="checkbox"
      onChange={() => {
        setChurchConsent && setChurchConsent(false);
      }}
      isDisabled={!isEditable}
    >
      <Text fontSize="sm">Nie wyrażam zgody</Text>
    </Checkbox>
  );

  type TransportCheckboxProps = {
    value: string;
  };
  const TransportCheckbox = ({ value }: TransportCheckboxProps) => (
    <Checkbox
      isChecked={transport === value}
      isInvalid={!transport}
      onChange={() => {
        setTransport && setTransport(value);
      }}
      isDisabled={!isEditable}
    ></Checkbox>
  );

  return (
    <>
      <Row className={isEditable ? "greyBg" : ""}>
        <Col breakPoint={{ sm: 12 }} style={colStyle}>
          <Stack>
            <HStack>
              <CampAgreementCheckbox />
              <Text fontSize="sm">
                {campAgreement(
                  "Warunkami Uczestnictwa Letnie Obozy 2024",
                  agreementUrl || "",
                  true
                )}
                <span style={{ color: "red" }}>*</span>
              </Text>
            </HStack>

            <HStack>
              <InsuranceAgreementCheckbox />
              <Text fontSize="sm">
                {insuranceAgreement()}
                <span style={{ color: "red" }}>*</span>
              </Text>
            </HStack>
          </Stack>
        </Col>

        <Divider mb={3} />

        <Col breakPoint={{ sm: 12 }} style={colStyle}>
          <Stack>
            <Box>
              Transport <span style={{ color: "red" }}>*</span>
            </Box>
            {_.map(transportOptions, ({ label, value }) => (
              <HStack key={value}>
                <TransportCheckbox value={value} />
                <Text fontSize="sm">{label}</Text>
              </HStack>
            ))}
          </Stack>
        </Col>

        <Divider mb={3} />

        <Col breakPoint={{ sm: 12 }} style={colStyle}>
          <StackComponent alignItems={"flex-start"}>
            <Text fontSize="sm" className="norightmargin" mr={3}>
              Rozmiar {isAdventure ? "bluzy" : "koszulki"}{" "}
              <span style={{ color: "red" }}>*</span>
            </Text>
            <Box width="220px" bg={"white"} className={"fullOpacity"}>
              <ShirtSelect
                options={isAdventure ? hoodieSizeOptions : shirtSizeOptions}
              />

              <Modal size="xl" isOpen={isOpen} onClose={() => setIsOpen(false)}>
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader>Szczegóły</ModalHeader>
                  <ModalCloseButton />
                  <ModalBody>
                    <Image
                      src={`images/${isAdventure ? "hoodie" : "shirt"}.jpeg`}
                      alt="clothes"
                      loading="lazy"
                      objectFit="cover"
                    />
                  </ModalBody>

                  <ModalFooter>
                    <Button
                      colorScheme="blue"
                      mr={3}
                      onClick={() => setIsOpen(false)}
                    >
                      Zamknij
                    </Button>
                  </ModalFooter>
                </ModalContent>
              </Modal>
            </Box>
            <Button size="xs" onClick={() => setIsOpen(true)} mr={2}>
              Rozmiarówka
            </Button>
          </StackComponent>
        </Col>
        <Divider mb={3} />

        <Col
          breakPoint={{ sm: 12 }}
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Text fontSize="sm">
            Zgoda na udział dziecka w niedzielnej mszy w kościele:
            <span style={{ color: "red" }}>*</span>
          </Text>
          <HStack mt={1} mb={2}>
            <PositiveChurchConsentCheckbox />
            <NegativeChurchConsentCheckbox />
          </HStack>
          <div>
            <small style={{ fontSize: "0.6rem" }}>
              {" "}
              <span style={{ color: "red" }}>*</span> zgody i wybory wymagane
            </small>
          </div>
        </Col>

        <Divider mb={3} />

        <Col breakPoint={{ sm: 12 }} style={colStyle}>
          <StackComponent alignItems={"flex-start"}>
            <Text fontSize="sm" className="norightmargin" mr={3}>
              Dieta specjalna płatna (200 zł/turnus)
            </Text>
            <Box width="220px" bg={"white"} className={"fullOpacity"}>
              <DietSelect />
            </Box>
          </StackComponent>
        </Col>
        <Col breakPoint={{ sm: 12 }} style={colStyle}>
          <small style={{ fontSize: "0.6rem" }}>
            W przypadku konieczności stosowania innej diety prosimy o kontakt z
            biurem Klub Sportteam celem potwierdzenia jej dostępności w
            obiekcie.
          </small>
        </Col>

        <Divider mb={3} />

        <Col breakPoint={{ sm: 12 }} style={colStyle}>
          <Stack flex={1} flexGrow={1}>
            <Text fontSize="sm" className="norightmargin" mr={3}>
              Informacje o stanie zdrowia
            </Text>
            <Box
              onClick={() => setIsHealthModalOpen(true)}
              border="solid 1px #e2e8f0"
              borderRadius={3}
              cursor="pointer"
              p={2}
            >
              <Text
                fontSize="sm"
                className="norightmargin"
                mr={3}
                mb={2}
                color={healthInfo ? "black" : "blackAlpha.600"}
              >
                {healthInfo || "Pole opcjonalne. Miejsce na informacje"}
              </Text>
              <Button
                size="sm"
                colorScheme="gray"
                variant="solid"
                onClick={() => setIsHealthModalOpen(true)}
                mr={2}
              >
                {healthInfo ? " Edytuj" : "Dodaj"}
              </Button>
            </Box>

            <Modal
              size="xl"
              isOpen={isHealthModalOpen}
              onClose={() => {
                setIsHealthModalOpen(false);
              }}
            >
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Szczegóły</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <Textarea
                    placeholder="Miejsce na informacje"
                    onChange={(e) => {
                      setTextHealthInput(e.target.value);
                    }}
                    value={textHealthInput}
                    size="sm"
                  />
                </ModalBody>

                <ModalFooter>
                  <Button
                    colorScheme="blue"
                    mr={3}
                    onClick={() => {
                      setIsHealthModalOpen(false);
                      setHealthInfo && setHealthInfo(textHealthInput as string);
                    }}
                  >
                    Zapisz
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          </Stack>
        </Col>

        <Divider mb={3} />

        <Col breakPoint={{ sm: 12 }} style={colStyle}>
          <Stack flex={1} flexGrow={1}>
            <Text fontSize="sm" className="norightmargin" mr={3}>
              Preferencje dotyczące współlokatorów
            </Text>
            <Box
              onClick={() => setIsTextModalOpen(true)}
              border="solid 1px #e2e8f0"
              borderRadius={3}
              cursor="pointer"
              p={2}
            >
              <Text
                fontSize="sm"
                className="norightmargin"
                mr={3}
                mb={2}
                color={roommatesInfo ? "black" : "blackAlpha.600"}
              >
                {roommatesInfo ||
                  "Pole opcjonalne. Jeśli na Camp jadą znajomi uczestnika w miarę możliwości logistycznych postaramy się ulokować ich razem w pokoju"}
              </Text>

              <Button
                size="sm"
                colorScheme="gray"
                variant="solid"
                onClick={() => setIsTextModalOpen(true)}
                mr={2}
              >
                {roommatesInfo ? " Edytuj" : "Dodaj"}
              </Button>
            </Box>

            <Modal
              size="xl"
              isOpen={isTextModalOpen}
              onClose={() => {
                setIsTextModalOpen(false);
              }}
            >
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Szczegóły</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <Textarea
                    placeholder="Jeśli na Camp jadą znajomi uczestnika w miarę możliwości logistycznych postaramy się ulokować ich razem w pokoju"
                    onChange={(e) => {
                      setTextRoommatesInput(e.target.value);
                    }}
                    value={textRoommatesInput}
                    size="sm"
                  />
                </ModalBody>

                <ModalFooter>
                  <Button
                    colorScheme="blue"
                    mr={3}
                    onClick={() => {
                      setIsTextModalOpen(false);
                      setRoommatesInfo &&
                        setRoommatesInfo(textRoommatesInput as string);
                    }}
                  >
                    Zapisz
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          </Stack>
        </Col>
      </Row>
      <Divider my={3} variant={"dashed"} />
    </>
  );
};

export default AdditionalInfo;

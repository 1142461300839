import { FcDownload } from "react-icons/fc";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Heading,
  Icon,
  Link,
  List,
  ListItem,
  Spacer,
  Text,
} from "@chakra-ui/react";
import { CardBody, CardFooter, CardHeader } from "@paljs/ui/Card";
import _ from "lodash";

import {
  activitiesTermsURL,
  privacyTermsURL,
  serviceTermsURL,
} from "../../../../components/agreements";
import { Card } from "../../../../components/paljs";
import { Order, OrderDocument } from "../../../../types";
import ClientSection from "../client-section";
import PaymentAccordion from "../payment-accordion";

const agreementsList = [
  {
    id: "0",
    link: serviceTermsURL,
    name: "Regulamin Świadczenia Usług",
    description: "Regulamin świadczenia usług Klub Sportteam Sp. z o.o.",
  },
  {
    id: "2",
    link: privacyTermsURL,
    name: "Polityka Prywatności",
    description: "Polityka Prywatności Klub Sportteam Sp. z o.o.",
  },
] as OrderDocument[];

const activityAgreementList = [
  {
    id: "1",
    link: activitiesTermsURL,
    name: "Regulamin Zajęć",
    description: "Regulamin zajęć Klub Sportteam Sp. z o.o.",
  },
] as OrderDocument[];

export default ({ order }: { order: Order }) => {
  const isArchival = order.fullyPaid;
  const documents = [
    ...order.documents,
    ..._.differenceBy(
      agreementsList,
      order.documents,
      (document) => document.description
    ),
    ...(order.itemsGroups
      .flatMap((group) => group.bundles.map((bundle) => bundle.__typename))
      .includes("OrderItemActivityBundle")
      ? _.differenceBy(
          activityAgreementList,
          order.documents,
          (document) => document.description
        )
      : []),
  ];

  return (
    <Card accent={isArchival ? "Success" : "Info"}>
      <CardHeader>
        <Heading size={"md"}>Zamówienie nr: {order.id}</Heading>
        <Text>z dnia {order.date}</Text>
      </CardHeader>
      <CardBody>
        {_.map(order.itemsGroups, (itemGroup, index) => (
          <ClientSection itemGroup={itemGroup} key={index}></ClientSection>
        ))}
      </CardBody>
      <CardFooter>
        {!isArchival && (
          <>
            <PaymentAccordion order={order}></PaymentAccordion>
          </>
        )}

        <Accordion allowToggle marginY={4}>
          <AccordionItem pb={0}>
            <h2>
              <AccordionButton paddingX={0} paddingY={"sm"}>
                <Box flex="1" textAlign="left">
                  Pliki do pobrania
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel paddingX={2}>
              <List mt={2} spacing={2}>
                {documents.map((orderDocument) => {
                  return (
                    <ListItem key={orderDocument.id}>
                      <Link
                        href={orderDocument.link}
                        isExternal={!!orderDocument.__typename}
                      >
                        <Flex alignItems={"center"}>
                          <Icon as={FcDownload} mr={2} />
                          {orderDocument.description || orderDocument.name}
                        </Flex>
                      </Link>
                    </ListItem>
                  );
                })}
              </List>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>

        {isArchival && (
          <Flex>
            <Box mb={3}>
              <Text as="b">Cena (bez zniżek)</Text>
            </Box>
            <Spacer />
            <Text as="b">{order.price.value} zł</Text>
          </Flex>
        )}
      </CardFooter>
    </Card>
  );
};

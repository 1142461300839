import CookieConsent, { getCookieConsentValue } from "react-cookie-consent";
import { Button, Link } from "@chakra-ui/react";

type CookieProps = {
  onAccept: () => void;
};
const CookieBanner = (props: CookieProps) => {
  const hasConsent = getCookieConsentValue();
  if (hasConsent) {
    props.onAccept();
    return null;
  }

  return (
    <>
      <CookieConsent
        debug
        enableDeclineButton
        flipButtons
        disableButtonStyles
        buttonText="Zgadzam się"
        ButtonComponent={(props: any) => (
          <Button {...props} colorScheme="blue" m={5} />
        )}
        declineButtonText="Wyłącz cookies"
        containerClasses="cookieConsent"
        contentClasses="cookieContent"
        onAccept={props.onAccept}
      >
        <h1>
          <b>Ta strona korzysta z plików cookie</b>
        </h1>
        <small>
          Ta strona internetowa wykorzystuje pliki cookie w celu poprawy jakości
          życia naszych gości. Niektóre z tych plików cookie są technicznie
          obowiązkowe, aby zagwarantować pewne funkcje strony internetowej.
          Ponadto, używamy niektórych plików cookie do zbierania informacji na
          temat zachowań użytkowników na tej stronie internetowej oraz do
          ulepszania naszej strony internetowej na podstawie tych informacji.
          <Link
            target={"_blank"}
            href={"https://sportteam.pl/kontakt/polityka-prywatnosci"}
          >
            <Button colorScheme={"blackAlpha"} size={"xs"} mx={3}>
              Polityka Prywatności
            </Button>
          </Link>
        </small>
      </CookieConsent>
    </>
  );
};

export default CookieBanner;

import { FormControl, FormLabel, HStack, Input } from "@chakra-ui/react";

import { Account } from "../../types";
import { getPostalCodeFromInput } from "../../utils/helpers";

type FormNameAndSurname = Pick<Account, "name" | "surname"> & {
  setName: (value: string) => void;
  setSurname: (value: string) => void;
  saved: boolean;
  isNameValid: boolean;
  isSurnameValid: boolean;
};
const RenderNameSurname = ({
  name,
  surname,
  setName,
  setSurname,
  saved,
  isNameValid,
  isSurnameValid,
}: FormNameAndSurname) => (
  <>
    <FormControl id="name">
      <FormLabel>Imię</FormLabel>
      <Input
        type="text"
        bg={"white"}
        isInvalid={!isNameValid}
        isDisabled={saved}
        onChange={(e) => setName(e.target.value)}
        value={name || undefined}
        autoComplete="given-name"
      />
    </FormControl>
    <FormControl id="surname">
      <FormLabel>Nazwisko</FormLabel>
      <Input
        type="text"
        bg={"white"}
        isInvalid={!isSurnameValid}
        isDisabled={saved}
        onChange={(e) => setSurname(e.target.value)}
        value={surname || undefined}
        autoComplete="family-name"
      />
    </FormControl>
  </>
);

type FormEmailAndPhone = {
  email: string;
  phone: string;
  setEmail: (value: string) => void;
  setPhone: (value: string) => void;
  saved: boolean;
  isEmailValid: boolean;
  isPhoneValid: boolean;
  isEmailDisabled: boolean;
};
const RenderEmailAndPhone = ({
  email,
  setEmail,
  phone,
  setPhone,
  saved,
  isEmailValid,
  isPhoneValid,
  isEmailDisabled,
}: FormEmailAndPhone) => (
  <>
    <FormControl id="email">
      <FormLabel>Email</FormLabel>
      <Input
        type="text"
        bg={"white"}
        isInvalid={!isEmailValid}
        isDisabled={isEmailDisabled || saved}
        onChange={(e) => setEmail(e.target.value)}
        value={email}
      />
    </FormControl>
    <FormControl id="phone">
      <FormLabel>Numer telefonu</FormLabel>
      <HStack>
        <Input
          type="text"
          bg={"white"}
          className="phonecode"
          value="+48"
          isDisabled={true}
          minWidth="70px"
          maxWidth="70px"
        />
        <Input
          type="text"
          bg={"white"}
          isDisabled={saved}
          isInvalid={!isPhoneValid}
          onChange={(e) => setPhone(e.target.value)}
          value={phone}
        />
      </HStack>
    </FormControl>
  </>
);

type FormAddress = {
  address_street?: string;
  address_code?: string;
  address_city?: string;
  setAddress_street: (value: string) => void;
  setAddress_code: (value: string) => void;
  setAddress_city: (value: string) => void;
  saved: boolean;
  isAddressStreetValid: boolean;
  isAddressCodeValid: boolean;
  isAddressCityValid: boolean;
};

const RenderAddress = ({
  address_street,
  address_code,
  address_city,
  setAddress_street,
  setAddress_code,
  setAddress_city,
  saved,
  isAddressStreetValid,
  isAddressCodeValid,
  isAddressCityValid,
}: FormAddress) => (
  <>
    <FormControl id="address_street">
      <FormLabel>Ulica i numer</FormLabel>
      <Input
        type="text"
        bg={"white"}
        isDisabled={saved}
        onChange={(e) => setAddress_street(e.target.value)}
        isInvalid={!isAddressStreetValid}
        value={address_street}
        autoComplete="street-address"
      />
    </FormControl>
    <HStack className="formStack">
      <FormControl id="address_code">
        <FormLabel>Kod pocztowy</FormLabel>
        <Input
          type="text"
          bg={"white"}
          isDisabled={saved}
          isInvalid={!isAddressCodeValid}
          onChange={(e) => {
            setAddress_code(getPostalCodeFromInput(e.target.value));
          }}
          {...(address_code ? { value: address_code } : {})}
          value={address_code}
          autoComplete="postal-code"
        />
      </FormControl>
      <FormControl id="address_city">
        <FormLabel>Miasto</FormLabel>
        <Input
          type="text"
          bg={"white"}
          isDisabled={saved}
          isInvalid={!isAddressCityValid}
          onChange={(e) => {
            setAddress_city(e.target.value);
          }}
          value={address_city}
          autoComplete="address-level2"
        />
      </FormControl>
    </HStack>
  </>
);

export { RenderAddress, RenderEmailAndPhone, RenderNameSurname };

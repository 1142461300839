import React, { useContext, useEffect, useState } from "react";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth/lib/types";
import { Auth } from "aws-amplify";

import * as cognito from "./cognito";

export enum AuthStatus {
  Loading,
  SignedIn,
  SignedOut,
}

export interface IAuth {
  sessionInfo?: {
    username?: string;
    email?: string;
    sub?: string;
    accessToken?: string;
    refreshToken?: string;
  };
  attrInfo?: any;
  authStatus?: AuthStatus;
  signInWithEmail?: any;
  signUpWithEmail?: any;
  signOut?: any;
  verifyCode?: any;
  getSession?: any;
  sendCode?: any;
  forgotPassword?: any;
  changePassword?: any;
  setNewPassword?: any;
  getAttributes?: any;
  setAttribute?: any;
  refreshSession?: any;
  signInWithGoogle?: any;
}

const defaultState: IAuth = {
  sessionInfo: {},
  authStatus: AuthStatus.Loading,
};

type Props = {
  children?: React.ReactNode;
};

export const AuthContext = React.createContext(defaultState);

export const AuthIsSignedIn = ({ children }: Props) => {
  const { authStatus }: IAuth = useContext(AuthContext);

  return <>{authStatus === AuthStatus.SignedIn ? children : null}</>;
};

export const AuthIsNotSignedIn = ({ children }: Props) => {
  const { authStatus }: IAuth = useContext(AuthContext);

  return <>{authStatus === AuthStatus.SignedOut ? children : null}</>;
};

const AuthProvider = ({ children }: Props) => {
  const [authStatus, setAuthStatus] = useState(AuthStatus.Loading);
  const [sessionInfo, setSessionInfo] = useState({});
  const [attrInfo, setAttrInfo] = useState([]);

  useEffect(() => {
    async function getSessionInfo() {
      // setAuthStatus(AuthStatus.SignedIn)

      try {
        const session: any = await getSession();
        setSessionInfo({
          accessToken: session.accessToken.jwtToken,
          refreshToken: session.refreshToken.token,
        });
        window.localStorage.setItem(
          "accessToken",
          `${session.accessToken.jwtToken}`
        );
        window.localStorage.setItem(
          "refreshToken",
          `${session.refreshToken.token}`
        );
        await setAttribute({
          Name: "website",
          Value: "https://panel.sportteam.pl",
        });
        const attr: any = await getAttributes();
        setAttrInfo(attr);
        setAuthStatus(AuthStatus.SignedIn);
      } catch (err) {
        cognito.signOut();
        setAuthStatus(AuthStatus.SignedOut);
      }
    }
    getSessionInfo();
  }, [setAuthStatus, authStatus]);

  if (authStatus === AuthStatus.Loading) {
    return null;
  }

  async function signInWithEmail(username: string, password: string) {
    try {
      await cognito.signInWithEmail(username, password);
      setAuthStatus(AuthStatus.Loading);
    } catch (err) {
      setAuthStatus(AuthStatus.SignedOut);
      throw err;
    }
  }

  async function signUpWithEmail(
    username: string,
    email: string,
    password: string
  ) {
    await cognito.signUpUserWithEmail(username, email, password);
  }

  async function signInWithGoogle() {
    await Auth.federatedSignIn({
      provider: CognitoHostedUIIdentityProvider.Google,
    });
  }

  function signOut() {
    cognito.signOut();
    setAuthStatus(AuthStatus.SignedOut);
  }

  async function verifyCode(username: string, code: string) {
    await cognito.verifyCode(username, code);
  }

  async function getSession() {
    const session = await cognito.getSession();
    return session;
  }

  async function getAttributes() {
    const attr = await cognito.getAttributes();
    return attr;
  }

  async function setAttribute(attr: any) {
    const res = await cognito.setAttribute(attr);
    return res;
  }

  async function sendCode(username: string) {
    await cognito.sendCode(username);
  }

  async function forgotPassword(
    username: string,
    code: string,
    password: string
  ) {
    await cognito.forgotPassword(username, code, password);
  }

  async function changePassword(oldPassword: string, newPassword: string) {
    await cognito.changePassword(oldPassword, newPassword);
  }
  async function setNewPassword(newPassword: string) {
    await cognito.setNewPassword(newPassword);
  }

  const state: IAuth = {
    authStatus,
    sessionInfo,
    attrInfo,
    signUpWithEmail,
    signInWithEmail,
    signOut,
    verifyCode,
    getSession,
    sendCode,
    forgotPassword,
    changePassword,
    getAttributes,
    setAttribute,
    setNewPassword,
    signInWithGoogle,
  };

  return <AuthContext.Provider value={state}>{children}</AuthContext.Provider>;
};

export default AuthProvider;

import { useMemo } from "react";
import { getCookieConsentValue } from "react-cookie-consent";
import * as Sentry from "@sentry/react";
import { GraphQLClient } from "graphql-hooks";
import memCache from "graphql-hooks-memcache";
import _ from "lodash";

import config from "../config";

let graphQLClient: GraphQLClient;

interface InitialState {
  token?: string;
}
function createClient(initialState: InitialState) {
  const token = _.get(initialState, "token");
  return new GraphQLClient({
    ssrMode: typeof window === "undefined",
    url: config.API_URL,
    headers: token != null ? { Authorization: `Bearer ${token}` } : {},
    cache: memCache({ initialState }),
    onError: ({ result, operation }) => {
      const hasConsent = getCookieConsentValue();
      if (hasConsent) {
        Sentry.captureException(result.error, {
          extra: {
            result,
            operation,
          },
        });
      } else {
        Sentry.captureException(new Error("untracked GQL error"));
      }
      if (result.error?.httpError?.status === 401) {
        window.location.replace("/signin");
      }
    },
  });
}

export function initializeGraphQL(initialState = {}) {
  const _graphQLClient = graphQLClient ?? createClient(initialState);

  if (initialState && graphQLClient) {
    const cachedState = _.get(
      graphQLClient,
      "cache.getInitialState",
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      () => {}
    ) as unknown as () => object;

    graphQLClient.cache = memCache({
      initialState: Object.assign(cachedState(), initialState),
    });
  }
  if (typeof window === "undefined") return _graphQLClient;
  if (!graphQLClient) graphQLClient = _graphQLClient;
  return _graphQLClient;
}

export function useGraphQLClient(initialState: any) {
  const store = useMemo(() => initializeGraphQL(initialState), [initialState]);
  return store;
}

import React, { useContext } from "react";
import { getCookieConsentValue } from "react-cookie-consent";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import { CartProvider } from "react-use-cart";
import { ChakraProvider } from "@chakra-ui/react";
import { useFeature } from "@growthbook/growthbook-react";
import CssBaseline from "@material-ui/core/CssBaseline";
import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@material-ui/core/styles";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { ClientContext } from "graphql-hooks";
import _ from "lodash";

import CookieBanner from "./components/cookiesConsent";
import { useGraphQLClient } from "./data/graphql-client";
import Activities from "./routes/activities";
import ChangePassword from "./routes/auth/changePassword";
import ForgotPassword from "./routes/auth/forgotPassword";
import NewPassword from "./routes/auth/newPassword";
import RequestCode from "./routes/auth/requestCode";
import SignIn from "./routes/auth/signIn";
import Signout from "./routes/auth/signOut";
import SignUp from "./routes/auth/signUp";
import VerifyCode from "./routes/auth/verify";
import Active from "./routes/cart/active";
import Archive from "./routes/cart/archive";
import Cart from "./routes/cart/cart";
import Home from "./routes/home";
import Children from "./routes/profile/children";
import Parent from "./routes/profile/parent";
import Redirect from "./routes/redirect";
import Summer2023 from "./routes/summer2023";
import Winter2023 from "./routes/winter2023";
import AuthProvider, {
  AuthContext,
  AuthIsNotSignedIn,
  AuthIsSignedIn,
} from "./utils/authContext";
import config from "./config";
import { GrowthBookEnricher } from "./growthbook";

import "./App.css";
import VerifyEmailChange from "./routes/auth/verifyEmailChange";

declare global {
  interface Window {
    dataLayer: any;
  }
}

Sentry.init({
  dsn: "https://bafb42d380604be9a152c7b3984fad53@o274315.ingest.sentry.io/6626988",
  integrations: [new BrowserTracing()],
  environment: config.ENVIRONMENT,
  release: config.SENTRY_RELEASE,
  tracesSampleRate: config.ENVIRONMENT === "production" ? 0.2 : 1.0,
  beforeSend: (event) => {
    const hasConsent = getCookieConsentValue();
    if (hasConsent) {
      return event;
    }
    delete event.breadcrumbs;
    delete event.request;
    return event;
  },
});

const gotCookieConsent = () => {
  if (config.ENVIRONMENT === "production") {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push("js", new Date());
    window.dataLayer.push("config", "G-5KP27J4HNJ");
  }
};

const UnknownUserRoutes: React.FunctionComponent = () => (
  <Router>
    <Routes>
      <Route path="/signin" element={<SignIn />} />
      <Route path="/signup" element={<SignUp />} />
      <Route path="/verify" element={<VerifyCode />} />
      <Route path="/verify-email-change" element={<VerifyEmailChange />} />
      <Route path="/requestcode" element={<RequestCode />} />
      <Route path="/forgotpassword" element={<ForgotPassword />} />
      <Route path="/newpassword" element={<NewPassword />} />
      <Route path="*" element={<Navigate to="/signin" />} />
    </Routes>
  </Router>
);

const KnownUserRoutes: React.FunctionComponent = () => {
  const { enable_camps, show_summer_camp } = config.FEATURE_TOGGLE;

  const auth = useContext(AuthContext);
  const token = _.get(auth, "sessionInfo.accessToken");
  const { on: showWinter2023Camp } = useFeature(enable_camps);
  const { on: showSummerCamp } = useFeature(show_summer_camp);

  const graphQLClient = useGraphQLClient({ token });
  return (
    <ClientContext.Provider value={graphQLClient}>
      <CartProvider>
        <Router>
          {(() => {
            return (
              <Routes>
                <Route path="/changepassword" element={<ChangePassword />} />
                <Route path="/signout" element={<Signout />} />

                <Route path="/profile/children" element={<Children />} />
                <Route path="/profile/parent" element={<Parent />} />
                <Route path="/profile" element={<Parent />} />
                <Route path="/activities" element={<Activities />} />

                <Route
                  path="/winter2023"
                  element={showWinter2023Camp ? <Winter2023 /> : <Home />}
                />
                <Route
                  path="/summer2024/:profile"
                  element={showSummerCamp ? <Summer2023 /> : <Home />}
                />

                <Route path="/cart" element={<Cart />} />
                <Route path="/active/*" element={<Active />} />
                <Route path="/archive/*" element={<Archive />} />

                <Route path="/redirect/*" element={<Redirect />} />

                <Route path="*" element={<Home />} />
              </Routes>
            );
          })()}
        </Router>
      </CartProvider>
      <GrowthBookEnricher />
    </ClientContext.Provider>
  );
};

let lightTheme = createTheme({
  palette: {
    type: "light",
  },
});
lightTheme = responsiveFontSizes(lightTheme);

const App: React.FunctionComponent = () => {
  return (
    <ChakraProvider>
      <ThemeProvider theme={lightTheme}>
        <CssBaseline />
        <AuthProvider>
          <AuthIsSignedIn>
            <KnownUserRoutes />
          </AuthIsSignedIn>
          <AuthIsNotSignedIn>
            <UnknownUserRoutes />
          </AuthIsNotSignedIn>
        </AuthProvider>
        <CookieBanner
          onAccept={() => {
            gotCookieConsent();
          }}
        />
      </ThemeProvider>
    </ChakraProvider>
  );
};

export default App;

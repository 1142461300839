import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Box, Button } from "@chakra-ui/react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import { AuthWrapper, Code, Email, Password } from "../../components/auth";
import config from "../../config";
import {
  useValidCode,
  useValidEmail,
  useValidPassword,
} from "../../hooks/useAuthHooks";
import { AuthContext } from "../../utils/authContext";
const bgImgUrl = config.Background.verify;

const useStyles = makeStyles({
  root: {
    height: "100vh",
  },
  hover: {
    "&:hover": { cursor: "pointer" },
  },
});

const VerifyEmailChange: React.FunctionComponent = () => {
  const classes = useStyles();

  const { email, setEmail, emailIsValid } = useValidEmail("");
  const { password, setPassword, passwordIsValid } = useValidPassword("");
  const { code, setCode, codeIsValid } = useValidCode("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const isValid =
    !emailIsValid ||
    email.length === 0 ||
    !codeIsValid ||
    code.length === 0 ||
    !passwordIsValid ||
    password.length === 0;

  const navigate = useNavigate();

  const authContext = useContext(AuthContext);
  const [searchParams] = useSearchParams();

  const clearFields = () => {
    setEmail("");
    setPassword("");
    setCode("");
  };

  const sendClicked = async (
    customEmail?: string,
    customPassword?: string,
    customCode?: string
  ) => {
    try {
      await authContext.signInWithEmail(
        customEmail || email,
        customPassword || password
      );
      await authContext.verifyCode(email, customCode || code);

      navigate("/");
    } catch (err: any) {
      if (err.code === "UserNotConfirmedException") {
        navigate("/verify");
      } else {
        if (err.message === "Incorrect username or password.") {
          setError("Błędny email lub hasło");
          clearFields();
        } else if (err.code === "PasswordResetRequiredException") {
          navigate("/forgotpassword");
        } else if (err.message === "NewPasswordRequired") {
          navigate("/newpassword");
        } else {
          setError("Invalid code");
        }
      }
    }
  };

  useEffect(() => {
    if (searchParams.has("credentials")) {
      try {
        const credentials = JSON.parse(
          atob(searchParams.get("credentials") as string)
        );

        if (isLoading) {
          sendClicked(
            credentials.email,
            credentials.password,
            credentials.code
          );
        }
      } catch (err) {
        setIsLoading(false);
      }
    } else if (isLoading) {
      setIsLoading(false);
    }
  }, []);

  const passwordResetClicked = async () => {
    navigate("/resetpassword");
  };

  return (
    <AuthWrapper bgImgUrl={bgImgUrl} title="Potwierdź zmianę e-mail">
      <Box width="80%" m={1}>
        <Email emailIsValid={emailIsValid} setEmail={setEmail} />{" "}
      </Box>
      <Box width="80%" m={1}>
        <Password
          label="Hasło"
          passwordIsValid={passwordIsValid}
          setPassword={setPassword}
          value={password}
        />
      </Box>
      <Box width="80%" m={1}>
        <Code codeIsValid={codeIsValid} setCode={setCode} />
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
        >
          <Box onClick={passwordResetClicked} mt={2}>
            <Typography className={classes.hover} variant="body2">
              Wyślij kod ponownie
            </Typography>
            <Box mt={2}>
              <Typography color="error" variant="body2">
                {error}
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Box>

      <Box mt={2}>
        <Grid container direction="row" justify="center">
          <Box m={1}>
            <Button onClick={() => navigate(-1)}>Powrót</Button>
          </Box>
          <Box m={1}>
            <Button
              disabled={isValid}
              colorScheme="blue"
              onClick={() => sendClicked()}
            >
              Zatwierdź
            </Button>
          </Box>
        </Grid>
      </Box>
    </AuthWrapper>
  );
};

export default VerifyEmailChange;

import React from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Avatar,
  Box,
  Button,
  Center,
  SkeletonCircle,
  SkeletonText,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { Actions, ActionType } from "@paljs/ui/Actions";
import { breakpointDown } from "@paljs/ui/breakpoints";
import ContextMenu from "@paljs/ui/ContextMenu";
import { LayoutHeader } from "@paljs/ui/Layout";
import { useQuery } from "graphql-hooks";
import _ from "lodash";
import styled from "styled-components";

import { UPDATE_ME } from "../../data/mutations";
import { GET_ME } from "../../data/queries";
import { GetMeQuery } from "../../types/graphql";
import Logo from "../logo";

const LH = LayoutHeader as any;

const HeaderStyle = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  ${breakpointDown("sm")`
    .right{
      display: none;
    }
  `}
  .right > div {
    height: auto;
    display: flex;
    align-content: center;
  }
  .logo {
    font-size: 1.25rem;
    white-space: nowrap;
    text-decoration: none;
  }
  .left {
    display: flex;
    align-items: center;
    .github {
      font-size: 18px;
      margin-right: 5px;
    }
  }
`;

interface HeaderProps {
  toggleSidebar: () => void;
}

const Header: React.FC<HeaderProps> = (props) => {
  const [isLargerThan760] = useMediaQuery("(min-width: 768px)");

  let name = "";
  let shortName = "";
  const { data } = useQuery<GetMeQuery>(GET_ME, {
    refetchAfterMutations: UPDATE_ME,
  });

  if (data) {
    name = `${data.me.name} ${data.me.surname}`;
    shortName = `${_.get(data, "me.name.0")}. ${data.me.surname}`;
  }

  const navigate = useNavigate();

  const Cart = () => (
    <Box>
      <Center>
        <Button
          colorScheme="red"
          size={isLargerThan760 ? "md" : "sm"}
          leftIcon={<ShoppingCartIcon />}
          onClick={() => navigate("/cart")}
        >
          Koszyk
        </Button>
      </Center>
    </Box>
  );

  const ProfileMenu = () => (
    <ContextMenu
      style={{ cursor: "pointer" }}
      placement="bottom"
      currentPath={""}
      items={[
        {
          title: "Zmień hasło",
          icon: { name: "settings-2-outline" },
          link: { to: "/changepassword" },
        },
        {
          title: "Wyloguj",
          icon: { name: "lock-outline" },
          link: { to: "/signout" },
        },
      ]}
      Link={Link}
    >
      <Box>
        <Center>
          {data ? (
            <Avatar name={name} mr={5} size={isLargerThan760 ? "md" : "sm"} />
          ) : (
            <SkeletonCircle mr={5} size={isLargerThan760 ? "12" : "8"} />
          )}
          {data ? (
            <Text fontSize={isLargerThan760 ? "md" : "xs"}>
              {" "}
              {isLargerThan760 ? name : shortName}
            </Text>
          ) : (
            <SkeletonText noOfLines={1}>???</SkeletonText>
          )}
        </Center>
      </Box>
    </ContextMenu>
  );

  const leftActionsWeb = [
    {
      icon: { name: "menu-outline" },
      url: {
        onClick: props.toggleSidebar,
      },
    },
    {
      content: <Logo />,
    },
  ];

  const rightActionsWeb = [
    {
      content: Cart(),
    },
    {
      content: ProfileMenu(),
    },
  ];

  const leftActionsMobile = [
    {
      icon: { name: "menu-outline" },
      url: {
        onClick: props.toggleSidebar,
      },
    },
    ...rightActionsWeb,
  ];

  const bigScreenHeader = () => (
    <>
      <Actions size="Medium" actions={leftActionsWeb as ActionType[]} />
      <Actions size="Medium" className="right" actions={rightActionsWeb} />
    </>
  );

  const smallScreenHeader = () => (
    <>
      <Actions
        size="Small"
        actions={leftActionsMobile as ActionType[]}
        className="smallActions"
      />
    </>
  );
  return (
    <LH fixed>
      <HeaderStyle>
        {isLargerThan760 ? bigScreenHeader() : smallScreenHeader()}
      </HeaderStyle>
    </LH>
  );
};
export default Header;

import {
  Calendar as BigBoiCalendar,
  dateFnsLocalizer,
  Event,
} from "react-big-calendar";
import { FaRegSadCry, FaSwimmer } from "react-icons/fa";
import { IoTennisball } from "react-icons/io5";
import { Box, Icon, Tooltip } from "@chakra-ui/react";
import { CardBody } from "@paljs/ui/Card";
import ColorHash from "color-hash";
import * as DateFns from "date-fns";
import format from "date-fns/format";
import getDay from "date-fns/getDay";
import PL from "date-fns/locale/pl";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import { useQuery } from "graphql-hooks";
import _ from "lodash";
import moment from "moment-mini";

import { GenericErrorAlert } from "../../components/alerts";
import EmptyState from "../../components/empty-state";
import Layout from "../../components/layout";
import Loading from "../../components/loading";
import { Card, Col, Row } from "../../components/paljs";
import { GET_ALL_ACTIVITIES } from "../../data/queries";
import { GetAllActivitiesQuery } from "../../types";

import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";

moment.locale("pl-PL");

const colorHash = new ColorHash();

export default function Home() {
  const locales = {
    PL: PL,
  };
  const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek: (x: any) =>
      startOfWeek(x, {
        weekStartsOn: 1,
      }),
    getDay,
    locales,
  });

  const { data, loading, error } =
    useQuery<GetAllActivitiesQuery>(GET_ALL_ACTIVITIES);

  type ExtendedEvent = Event & {
    sport: string;
    location: string;
    type: string;
  };

  const getEventsFromData = (
    allActivities: GetAllActivitiesQuery["me"]["allActivities"]
  ): ExtendedEvent[] => {
    return _.flatMapDeep(allActivities, (activityGroup) => {
      return activityGroup.bundles.map((bundle) => {
        if (bundle.__typename !== "OrderItemActivityBundle") return [];
        const events: ExtendedEvent[] = bundle.entries.map((entry) => {
          const startDate = DateFns.parse(
            entry,
            "dd-MM-yyyy'T'HH:mm",
            Date.now()
          );
          return {
            ...bundle,
            start: startDate,
            end: DateFns.add(startDate, { hours: 1 }),
            title: `${bundle.location}`,
            resource: activityGroup.participant,
          };
        });
        return events;
      });
    });
  };

  const EventComponent = ({ event }: { event: ExtendedEvent }) => {
    return (
      <>
        <Tooltip
          label={`${event.sport} ${DateFns.format(
            event.start as Date,
            "HH:mm"
          )}`}
        >
          <div>
            <Icon
              as={event.sport === "Pływanie" ? FaSwimmer : IoTennisball}
              mr={2}
            />
            {event.title}
          </div>
        </Tooltip>
      </>
    );
  };

  return (
    <>
      <Layout>
        <Row>
          <Col breakPoint={{ sm: 12, md: 12, lg: 12 }}>
            <Box mb={5}>
              <Card size="Giant">
                <CardBody>
                  {loading || error ? (
                    <>
                      <Card>
                        <CardBody>
                          {loading && <Loading />}
                          {error && <GenericErrorAlert />}
                        </CardBody>
                      </Card>
                    </>
                  ) : null}
                  {!loading && _.isEmpty(data?.me.allActivities) && (
                    <EmptyState
                      header={"Brak wykupionych zajęć"}
                      icon={<Icon boxSize="10em" as={FaRegSadCry}></Icon>}
                      text="Skontaktuj się z koordynator w celu wprowadzenia nowego zamówienia"
                    />
                  )}
                  {!loading && data && !_.isEmpty(data.me.allActivities) && (
                    <BigBoiCalendar
                      // min={new Date(2017, 10, 0, 10, 0, 0)}
                      // max={new Date(2017, 10, 0, 22, 0, 0)}
                      components={{ event: EventComponent }}
                      defaultView="month"
                      views={{ month: true }}
                      events={getEventsFromData(data.me.allActivities)}
                      resources={_.map(
                        data.me.allActivities,
                        (activity) => activity.participant
                      )}
                      resourceTitleAccessor={(resource) =>
                        `${resource.name} ${resource.surname}`
                      }
                      eventPropGetter={(event) => {
                        return {
                          style: {
                            backgroundColor: colorHash.hex(event.resource?.id),
                          },
                        };
                      }}
                      localizer={localizer}
                      selectable={false}
                      style={{ height: "600px" }}
                    />
                  )}
                </CardBody>
              </Card>
            </Box>
          </Col>
        </Row>
      </Layout>
    </>
  );
}

import { useState } from "react";
import { Box, Flex, Heading, useMediaQuery } from "@chakra-ui/react";
import { CardBody } from "@paljs/ui/Card";
import { useMutation, useQuery } from "graphql-hooks";
import _ from "lodash";

import {
  GenericErrorAlert,
  MissingProfileDataAlert,
} from "../../components/alerts";
import Layout from "../../components/layout";
import Loading from "../../components/loading";
import { Card, Col, Row } from "../../components/paljs";
import {
  ADD_GUARDIAN,
  PLACE_ORDER,
  REMOVE_GUARDIAN,
  UPDATE_GUARDIAN,
  UPDATE_ME,
} from "../../data/mutations";
import { GET_ME } from "../../data/queries";
import GuardianForm, { AddGuardianButton } from "../../parts/guardian";
import ParentForm from "../../parts/parent";
import { Account, GetMeQuery, Guardian } from "../../types";

const Parent = () => {
  const [isLargerThan760] = useMediaQuery("(min-width: 760px)");
  const [showPlaceholderForm, setShowPlaceholderForm] = useState(false);

  const [updateUser, { loading: updatingAccount }] = useMutation(UPDATE_ME);
  const { loading, data, error } = useQuery<GetMeQuery>(GET_ME, {
    refetchAfterMutations: [
      UPDATE_ME,
      PLACE_ORDER,
      UPDATE_GUARDIAN,
      REMOVE_GUARDIAN,
      ADD_GUARDIAN,
    ],
  });

  const [updateGuardian, { loading: guardianUpdateLoading }] =
    useMutation(UPDATE_GUARDIAN);
  const [removeGuardian, { loading: guardianRemoveLoading }] =
    useMutation(REMOVE_GUARDIAN);
  const [addGuardian, { loading: guardianAddLoading }] =
    useMutation(ADD_GUARDIAN);

  const anyLoading =
    loading ||
    guardianUpdateLoading ||
    guardianRemoveLoading ||
    guardianAddLoading;

  const addGuardianPlaceholder = {
    id: "addGuardianPlaceholder",
    isIncomplete: true,
    isPlaceholder: true,
    contactInfo: {},
  };

  const account = data?.me;

  const setParent = (parent: Partial<Account>) => {
    updateUser({ variables: { input: parent } });
  };

  const setGuardian = (guardian: Omit<Guardian, "isIncomplete">) => {
    const omitProps = [
      "id",
      "isIncomplete",
      "isPlaceholder",
      "canDelete",
      "isGuardianStepDone",
    ];
    const input = _.omit(guardian, omitProps);
    if (guardian.id === addGuardianPlaceholder.id) {
      addGuardian({
        variables: {
          input,
        },
      });
      setShowPlaceholderForm(false);
    } else {
      updateGuardian({
        variables: {
          input,
          guardianId: guardian.id,
        },
      });
    }
  };

  const handleRemoveGuardian = (guardian: Guardian) => {
    if (guardian.id === addGuardianPlaceholder.id) {
      setShowPlaceholderForm(false);
    } else {
      removeGuardian({ variables: { guardianId: guardian.id } });
    }
  };

  const AddGuardianCardBody = () => {
    return (
      <Flex justify={"center"} align="center" minH={200}>
        <AddGuardianButton
          addGuardian={() => setShowPlaceholderForm(true)}
          isDisabled={false}
        />
      </Flex>
    );
  };

  const queryGuardians = _.get(account, "guardians", []);

  const guardiansList = [...queryGuardians];
  if (_.size(queryGuardians) < 3) {
    guardiansList.push(addGuardianPlaceholder as unknown as Guardian);
  }

  return (
    <Layout>
      <Row>
        <Col breakPoint={{ sm: 12, md: 9, lg: 6 }}>
          <Box mb={5}>
            <Heading
              fontSize={isLargerThan760 ? "2xl" : "xl"}
              color={"gray.700"}
            >
              Dane opiekuna
            </Heading>
          </Box>
          <Card>
            <CardBody>
              {anyLoading && <Loading />}
              {error && <GenericErrorAlert />}
              {account && (
                <>
                  {account.isIncomplete && (
                    <MissingProfileDataAlert parent={true} simple={true} />
                  )}
                  <ParentForm
                    parent={account}
                    setParent={setParent}
                    isLoading={updatingAccount}
                  />
                </>
              )}
            </CardBody>
          </Card>
        </Col>

        <Col breakPoint={{ sm: 12, md: 6 }}>
          <Box mb={5}>
            <Heading
              fontSize={isLargerThan760 ? "2xl" : "xl"}
              color={"gray.700"}
            >
              Dodatkowa osoba kontaktowa
            </Heading>
          </Box>
          {_.map(guardiansList, (guardian) => {
            return (
              <Col key={guardian.id}>
                <Card>
                  <CardBody>
                    {guardian.id === addGuardianPlaceholder.id &&
                    !showPlaceholderForm ? (
                      <AddGuardianCardBody />
                    ) : (
                      <>
                        <GuardianForm
                          guardian={guardian}
                          setGuardian={setGuardian}
                          removeGuardian={handleRemoveGuardian}
                          isLoading={
                            guardianUpdateLoading ||
                            guardianRemoveLoading ||
                            guardianAddLoading
                          }
                        />
                      </>
                    )}
                  </CardBody>
                </Card>
              </Col>
            );
          })}
        </Col>
      </Row>
    </Layout>
  );
};
export default Parent;

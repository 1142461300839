import { useEffect } from "react";
import { FcCheckmark } from "react-icons/fc";
import { useMatch } from "react-router-dom";
import { Box, Center, Icon, useDisclosure } from "@chakra-ui/react";
import { CardBody } from "@paljs/ui/Card";
import { useQuery } from "graphql-hooks";
import _ from "lodash";

import { GenericErrorAlert } from "../../components/alerts";
import EmptyState from "../../components/empty-state";
import Layout from "../../components/layout";
import Loading from "../../components/loading";
import SuccessBuyModal from "../../components/modals/successBuy";
import { Card, Col } from "../../components/paljs";
import {
  ADD_MEMBERSHIP_ITEM,
  PLACE_ORDER,
  UPDATE_CHILD,
  UPDATE_ME,
} from "../../data/mutations";
import { GET_ACTIVE_ORDERS } from "../../data/queries";
import useTimeout from "../../hooks/useTimeout";
import { GetActiveOrdersQuery, Order } from "../../types";

import OrderCard from "./components/order-card";

const Active = () => {
  const { isOpen, onClose } = useDisclosure();

  const { loading, data, error, refetch } = useQuery<GetActiveOrdersQuery>(
    GET_ACTIVE_ORDERS,
    {
      refetchAfterMutations: [
        UPDATE_ME,
        UPDATE_CHILD,
        ADD_MEMBERSHIP_ITEM,
        PLACE_ORDER,
      ],
      useCache: false,
    }
  );

  const matchPath = useMatch({
    path: "/active/:orderId/:paymentId",
    caseSensitive: false,
    end: false,
  });

  useTimeout(
    () => {
      if (data?.me.activeOrders && matchPath && matchPath.params.orderId) {
        console.log("Yay", matchPath.params.orderId);
      }
    },
    5,
    [data?.me.activeOrders]
  );

  let retry = 0;
  const retryLimit = 5;
  useEffect(() => {
    function refetchData(again: boolean) {
      if (!loading && _.isEmpty(data) && again && retry < retryLimit) {
        setTimeout(() => {
          console.log("refetch");
          retry = retry++;
          refetch();
          refetchData(true);
        }, 1000);
      }
      return;
    }
    refetchData(true);
  }, []);

  return (
    <Layout>
      <Center>
        <Col breakPoint={{ sm: 12, lg: 12, xl: 10, xxl: 9, xxxl: 9 }}>
          <>
            {loading || error ? (
              <>
                <Card>
                  <CardBody>
                    {loading && <Loading />}
                    {error && <GenericErrorAlert />}
                  </CardBody>
                </Card>
              </>
            ) : null}
            {data?.me.activeOrders && (
              <>
                {!loading && _.isEmpty(data.me.activeOrders) && (
                  <EmptyState
                    header={"Brak nieopłaconych zamówień"}
                    icon={<Icon boxSize="10em" as={FcCheckmark}></Icon>}
                    text="Skontaktuj się z koordynator w celu wprowadzenia nowego zamówienia"
                  />
                )}
                {_.map(data.me.activeOrders, (order, index) => {
                  return (
                    <Box key={index}>
                      <OrderCard
                        order={order as Order}
                        key={order.id}
                      ></OrderCard>
                    </Box>
                  );
                })}
              </>
            )}
          </>
        </Col>
      </Center>
      <SuccessBuyModal onClose={onClose} isOpen={isOpen} />
    </Layout>
  );
};

export default Active;

import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button } from "@chakra-ui/react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import { AuthWrapper, Email, Password } from "../../components/auth";
import config from "../../config";
import { useValidEmail, useValidPassword } from "../../hooks/useAuthHooks";
import { AuthContext } from "../../utils/authContext";
const bgImgUrl = config.Background.signUp;

const SignUp: React.FunctionComponent = () => {
  const { email, setEmail, emailIsValid } = useValidEmail("");
  const { password, setPassword, passwordIsValid } = useValidPassword("");
  const [error, setError] = useState("");
  const [created, setCreated] = useState(false);

  const {
    password: passwordConfirm,
    setPassword: setPasswordConfirm,
    passwordIsValid: passwordConfirmIsValid,
  } = useValidPassword("");

  const isValid =
    !emailIsValid ||
    email.length === 0 ||
    !passwordIsValid ||
    password.length === 0 ||
    !passwordConfirmIsValid ||
    passwordConfirm.length === 0;

  const navigate = useNavigate();

  const authContext = useContext(AuthContext);

  const signInClicked = async () => {
    try {
      await authContext.signUpWithEmail(email, email, password);
      setCreated(true);
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message);
      }
    }
  };

  const signUp = (
    <>
      <Box width="80%" m={1}>
        <Email emailIsValid={emailIsValid} setEmail={setEmail} />
      </Box>
      <Box width="80%" m={1}>
        <Password
          label="Hasło"
          passwordIsValid={passwordIsValid}
          setPassword={setPassword}
        />
      </Box>
      <Box width="80%" m={1}>
        <Password
          label="Powtórz hasło"
          passwordIsValid={passwordConfirmIsValid}
          setPassword={setPasswordConfirm}
        />
      </Box>
      <Box mt={2}>
        <Typography color="error" variant="body2">
          {error}
        </Typography>
      </Box>

      {/* Buttons */}
      <Box mt={2}>
        <Grid container direction="row" justify="center">
          <Box m={1}>
            <Button onClick={() => navigate(-1)}>Wróć</Button>
          </Box>
          <Box m={1}>
            <Button
              disabled={isValid}
              colorScheme="blue"
              onClick={signInClicked}
            >
              Zarejestruj
            </Button>
          </Box>
        </Grid>
      </Box>
    </>
  );

  const accountCreated = (
    <>
      <br />
      <div
        style={{
          textAlign: "center",
        }}
      >
        Przesłano kod weryfikujący na email: <b>{email}</b>
      </div>
      <Box m={4}>
        <Button onClick={() => navigate("/verify")} colorScheme="blue">
          Dostałam/łem kod
        </Button>
      </Box>
    </>
  );

  return (
    <AuthWrapper
      bgImgUrl={bgImgUrl}
      title={!created ? "Utwórz konto" : "Utworzono konto w systemie"}
    >
      {!created ? signUp : accountCreated}
    </AuthWrapper>
  );
};

export default SignUp;

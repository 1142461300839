import { useState } from "react";
import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Stack,
} from "@chakra-ui/react";
import _ from "lodash";

import Agreements from "../../components/agreements";
import {
  RenderAddress,
  RenderEmailAndPhone,
  RenderNameSurname,
} from "../../components/form";
import { Account } from "../../types";
import {
  validateOnlyLetters,
  validatePesel,
  validatePhoneNumber,
  validatePostalCode,
} from "../../utils/helpers";

type ParentComponentProps = {
  parent: Partial<Account>;
  setParent: (parent: Partial<Account>) => void;
  isLoading: boolean;
};
const Parent = ({ parent, setParent, isLoading }: ParentComponentProps) => {
  // const [saved, setSaved] = useState(false)
  const saved = isLoading;

  const [name, setName] = useState(parent.name);
  const hasName = !!name || name !== "";
  const isNameValid = validateOnlyLetters(name || "");

  const [surname, setSurname] = useState(parent.surname);
  const hasSurname = !!surname || surname !== "";
  const isSurnameValid = validateOnlyLetters(surname || "");

  const [email, setEmail] = useState(parent.contactInfo?.email);
  const hasEmail = !!email || email !== "";
  const isEmailValid = true;

  const [phone, setPhone] = useState(parent.contactInfo?.phone);
  const hasPhone = !!phone || phone !== "";
  const isPhoneValid = validatePhoneNumber(phone);

  const [pesel, setPesel] = useState(parent.pesel);
  const hasPesel = !!pesel || pesel !== "";
  const isPeselValid = validatePesel(pesel || "");

  const [address_street, setAddress_street] = useState(
    parent.address?.street || undefined
  );
  const hasAddressStreet = !_.isEmpty(address_street);
  const isAddressStreetValid = hasAddressStreet;

  const [address_code, setAddress_code] = useState(
    parent.address?.code || undefined
  );
  const hasAddressCode = !!address_code || address_code !== "";
  const isAddressCodeValid = address_code
    ? validatePostalCode(address_code)
    : false;

  const [address_city, setAddress_city] = useState(
    parent.address?.city || undefined
  );
  const hasAddressCity = !!address_city || address_city !== "";
  const isAddressCityValid = address_city
    ? validateOnlyLetters(address_city)
    : false;

  const [consents, setConsents] = useState(parent.consents);
  const [hasRequiredConsents, setHasRequiredConsents] = useState(false);

  const hasValuesFilled =
    hasName &&
    hasSurname &&
    hasPhone &&
    hasEmail &&
    hasPesel &&
    hasAddressCity &&
    hasAddressCode &&
    hasAddressStreet &&
    hasRequiredConsents;

  const isDisabled =
    !hasValuesFilled ||
    !isNameValid ||
    !isSurnameValid ||
    !isPeselValid ||
    !isPhoneValid ||
    !isEmailValid ||
    !isAddressCodeValid ||
    !isAddressCityValid ||
    !hasRequiredConsents;

  return (
    <Stack spacing={4}>
      <HStack>
        {RenderNameSurname({
          name,
          surname,
          setName,
          setSurname,
          saved,
          isNameValid,
          isSurnameValid,
        })}
      </HStack>
      <HStack>
        {RenderEmailAndPhone({
          email,
          setEmail,
          phone,
          setPhone,
          saved,
          isEmailDisabled: true,
          isPhoneValid,
          isEmailValid,
        })}
      </HStack>

      <FormControl id="pesel">
        <FormLabel>Pesel</FormLabel>
        <Input
          type="number"
          bg={"white"}
          isDisabled={saved}
          isInvalid={!isPeselValid}
          onChange={(e) => setPesel(e.target.value)}
          defaultValue={parent.pesel || undefined}
        />
      </FormControl>

      <Stack>
        {RenderAddress({
          address_street,
          address_code,
          address_city,
          setAddress_street,
          setAddress_code,
          setAddress_city,
          saved,
          isAddressStreetValid,
          isAddressCodeValid,
          isAddressCityValid,
        })}
      </Stack>

      <FormControl id="consents">
        <FormLabel>Zgody i regulaminy</FormLabel>
        <Agreements
          hideSelectAll={true}
          hideNonEditable={false}
          requiredConsents={[
            "health",
            "healthProcessing",
            "knowledge",
            "insurance",
          ]}
          setHasRequiredConsents={setHasRequiredConsents}
          setConsents={setConsents}
          consents={consents || {}}
        />
      </FormControl>
      <Stack spacing={10}>
        <Button
          bg={"blue.400"}
          color={"white"}
          _hover={{
            bg: "blue.500",
          }}
          isDisabled={saved || isDisabled}
          onClick={() => {
            const newParent: Partial<Account> = {
              address: {
                city: address_city,
                code: address_code,
                street: address_street,
              } as Account["address"],
              contactInfo: {
                email: email,
                phone: phone,
              },
              name,
              surname,
              pesel,
              consents,
            };
            setParent(newParent);
            // setSaved(true)
          }}
        >
          Zapisz
        </Button>
      </Stack>
    </Stack>
  );
};
export default Parent;

import React from "react";
import { Heading, useMediaQuery } from "@chakra-ui/react";

const H1 = ({ children }: any) => {
  const [isLargerThan760] = useMediaQuery("(min-width: 760px)");

  return (
    <Heading
      fontSize={isLargerThan760 ? "2xl" : "xl"}
      padding={5}
      color={"gray.700"}
      textAlign="center"
    >
      {children}
    </Heading>
  );
};

export default H1;

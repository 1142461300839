import React from "react";
import { FcAssistant } from "react-icons/fc";
import { Box, Center, Heading, Icon, Stack, Text } from "@chakra-ui/react";

const EmptyState = ({
  header,
  text,
  icon,
}: {
  header: string;
  text?: string;
  icon?: React.ReactNode;
}) => {
  return (
    <Box
      backgroundColor="white"
      ml={0}
      mr={0}
      borderRadius={8}
      boxShadow="0px 4px 10px rgba(0, 0, 0, 0.05)"
    >
      <Box
        backgroundColor="gray.50"
        borderTopLeftRadius={8}
        borderTopRightRadius={8}
        borderBottom="1px solid"
        borderBottomColor="gray.200"
        height="40px"
      />
      <Stack
        justifyContent="center"
        alignItems="center"
        spacing={2}
        p={16}
        borderRadius={8}
      >
        <Heading size="lg">{header}</Heading>
        {icon && (
          <>
            <Center>{icon}</Center>
          </>
        )}
        {text && <Text>{text}</Text>}
      </Stack>
    </Box>
  );
};

export const EmptyBasket = () => {
  return (
    <EmptyState
      header={"Pusty koszyk"}
      icon={<Icon boxSize="10em" as={FcAssistant}></Icon>}
      text="Dodaj zapisy do koszyka albo skontaktuj się z koordynator w celu wprowadzenia nowego zamówienia"
    />
  );
};

export default EmptyState;

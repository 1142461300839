import { Center, Divider, Text, useBreakpointValue } from "@chakra-ui/react";
import { Image } from "@chakra-ui/react";

import RowedDescription from "../../components/rowedDescription";

const Description = () => {
  return (
    <>
      <Text
        fontWeight={700}
        lineHeight={1.2}
        fontSize={useBreakpointValue({ base: "3xl", md: "5xl" })}
        pb={6}
      >
        Letnie Campy 2024
      </Text>

      {/* <AspectRatio height={358} ratio={4 / 3}> */}

      {/* </AspectRatio> */}

      <Text mt={3}>
        Letnie Campy, to wyjazdy wypełnione atrakcjami i aktywnościami w
        sprawdzonym i komfortowym kompleksie rekreacyjno-sportowym Star-Dadaj na
        mazurach.
      </Text>

      <Divider my={3} />

      <Text my={5}>
        <b>Campy odbędą się w pięciu turnusach:</b>
      </Text>
      {RowedDescription({
        rows: [
          ["0 Turnus", "29.06 - 09.07.2024 (Camp żeglarski)"],
          ["I Turnus", "03.07 – 11.07.2024 (Fun Active i Sport & Adventure)"],
          ["II Turnus", "11.07 – 19.07.2024 (Fun Active i Sport & Adventure)"],
          ["III Turnus", "19.07 – 27.07.2024 (Fun Active i Sport & Adventure)"],
          ["IV Turnus", "27.07 – 04.08.2024 (Fun Active i Sport & Adventure)"],
        ],
      })}

      <Divider />
      <Center>
        <Text my={5}>
          <b>Zapraszamy do zapisów!</b>
        </Text>
      </Center>
      <Image
        src={"/images/summer2024.jpeg"}
        alt="summercamp"
        objectFit="cover"
      />
    </>
  );
};

export default Description;

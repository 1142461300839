import { useState } from "react";
import {
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Stack,
} from "@chakra-ui/react";
import _ from "lodash";
import moment from "moment-mini";

import { TooltipWrapper } from "../../components/tooltip";
import { Account, Child } from "../../types";
import { currentDate } from "../../utils/calendar";
import {
  decodePesel,
  getPostalCodeFromInput,
  validateOnlyLetters,
  validatePesel,
  validatePostalCode,
} from "../../utils/helpers";

type AddChildButtonProps = {
  isDisabled: boolean;
  addChild: () => void;
};
export const AddChildButton = ({
  addChild,
  isDisabled,
}: AddChildButtonProps) => {
  return (
    <Button
      colorScheme="green"
      isDisabled={isDisabled}
      onClick={() => {
        addChild();
      }}
    >
      Dodaj profil kolejnego dziecka
    </Button>
  );
};

type Props = {
  child: Child;
  parentAddress?: Account["address"];
  setChild: (child: Child) => void;
  removeChild?: (child: Child) => void;
  addChild?: () => void;
  isLoading: boolean;
};
export default function JoinOurTeam({
  child,
  setChild,
  removeChild,
  addChild,
  isLoading,
  parentAddress,
}: Props) {
  // const [saved, setSaved] = useState(false)
  const saved = isLoading;
  const isPlaceholder = child.id === "addChildPlaceholder";

  const canDelete = _.get(child, "canDelete", false);

  const [name, setChild_name] = useState(child.name);
  const hasChild_Name = !!name || name !== "";
  const isChildNameValid = validateOnlyLetters(name || "");

  const [surname, setChild_surname] = useState(child.surname);
  const hasChild_Surname = !!surname || surname !== "";
  const isChildSurnameValid = validateOnlyLetters(surname || "");

  const minDate = moment(new Date(currentDate()))
    .subtract(18, "years")
    .format("YYYY-MM-DD");
  const maxDate = moment(new Date(currentDate())).format("YYYY-MM-DD");

  const [birthday, setChild_birthday] = useState(child.birthday);
  const birthdayMoment = moment(birthday, "DD-MM-YYYY");

  const hasChild_Birthday = !!birthday || birthday !== "";
  let isChildBirthdayValid = hasChild_Birthday;
  if (!!birthday && birthday !== "") {
    isChildBirthdayValid =
      moment(birthdayMoment).isAfter(minDate) &&
      moment(birthdayMoment).isBefore(maxDate);
  }

  const [pesel, setChild_pesel] = useState(child.pesel);
  const hasChild_Pesel = !!pesel || pesel !== "";
  const isChildPeselValid = validatePesel(pesel || "");

  const [street, setChild_street] = useState(child.address?.street);
  const hasChild_AddressStreet = !_.isEmpty(street);

  const [code, setChild_code] = useState(child.address?.code);
  const hasChild_AddressCode = !!code || code !== "";
  const isChildAddressCodeValid = code
    ? validatePostalCode(code)
    : child.address?.code
    ? validatePostalCode(child.address?.code)
    : false;

  const [city, setChild_city] = useState(child.address?.city);
  const hasChild_AddressCity = !!city || city !== "";
  const isChildAddressCityValid = city
    ? validateOnlyLetters(city)
    : child.address?.city
    ? validateOnlyLetters(child.address?.city)
    : false;

  const [health, setChild_health] = useState(child.health);

  const hasValuesFilled =
    hasChild_Name &&
    hasChild_Surname &&
    hasChild_Birthday &&
    hasChild_Pesel &&
    hasChild_AddressCity &&
    hasChild_AddressCode &&
    hasChild_AddressStreet;
  const isDisabled =
    !hasValuesFilled ||
    !isChildNameValid ||
    !isChildSurnameValid ||
    !isChildBirthdayValid ||
    !isChildPeselValid ||
    !isChildAddressCodeValid ||
    !isChildAddressCityValid;

  const [sameAdress, setSameAddress] = useState(false);

  const setAddress = (shouldSetSameAddress: boolean) => {
    setSameAddress(shouldSetSameAddress);
    if (shouldSetSameAddress) {
      setChild_street(parentAddress?.street);
      setChild_code(parentAddress?.code);
      setChild_city(parentAddress?.city);
    } else {
      setChild_street(child.address?.street);
      setChild_code(child.address?.code);
      setChild_city(child.address?.city);
    }
  };

  return (
    <Stack spacing={4}>
      <HStack>
        <FormControl id="name">
          <FormLabel>Imię</FormLabel>
          <Input
            type="text"
            bg={"white"}
            isInvalid={!isChildNameValid}
            isDisabled={saved}
            onChange={(e) => setChild_name(e.target.value)}
            defaultValue={child.name || undefined}
          />
        </FormControl>
        <FormControl id="surname">
          <FormLabel>Nazwisko</FormLabel>
          <Input
            type="text"
            bg={"white"}
            isInvalid={!isChildSurnameValid}
            isDisabled={saved}
            onChange={(e) => setChild_surname(e.target.value)}
            defaultValue={child.surname || undefined}
          />
        </FormControl>
      </HStack>
      <HStack>
        <FormControl id="birthday">
          <FormLabel>Data urodzin</FormLabel>
          <Input
            type="date"
            bg={"white"}
            isInvalid={!isChildBirthdayValid}
            isDisabled={true}
            onChange={(e) =>
              setChild_birthday(moment(e.target.value).format("DD-MM-YYYY"))
            }
            value={moment(birthday, "DD-MM-YYYY").format("YYYY-MM-DD")}
            // defaultValue={
            //   initialChild_birthday ? moment(initialChild_birthday, 'DD-MM-YYYY').format('YYYY-MM-DD') : undefined
            // }
            min={minDate}
            max={maxDate}
          />
        </FormControl>
        <FormControl id="pesel">
          <FormLabel>Pesel</FormLabel>
          <Input
            type="number"
            bg={"white"}
            isDisabled={saved}
            isInvalid={!isChildPeselValid}
            onChange={(e) => {
              if (_.size(e.target.value) > 10) {
                const bday = moment(decodePesel(e.target.value)).format(
                  "DD-MM-YYYY"
                );
                setChild_birthday(bday);
              }
              setChild_pesel(e.target.value);
            }}
            defaultValue={child.pesel || undefined}
          />
        </FormControl>
      </HStack>

      {!_.isEmpty(parentAddress) && (
        <Stack
          direction={{ base: "column", sm: "row" }}
          align={"start"}
          justify={"space-between"}
        >
          <Checkbox
            isChecked={sameAdress}
            onChange={() => setAddress(!sameAdress)}
            isDisabled={saved}
          >
            Adres jest taki sam jak opiekuna
          </Checkbox>
        </Stack>
      )}

      <FormControl id="street">
        <FormLabel>Ulica i numer</FormLabel>
        <Input
          type="text"
          bg={"white"}
          onChange={(e) => setChild_street(e.target.value)}
          defaultValue={
            sameAdress
              ? child.address?.street || undefined
              : child.address?.street || undefined
          }
          value={street || undefined}
          isDisabled={saved || sameAdress}
          isInvalid={!hasChild_AddressStreet}
        />
      </FormControl>
      <HStack className="formStack">
        <FormControl id="code">
          <FormLabel>Kod pocztowy</FormLabel>
          <Input
            type="text"
            bg={"white"}
            defaultValue={
              sameAdress
                ? child.address?.code || undefined
                : child.address?.code || undefined
            }
            value={code || undefined}
            isInvalid={!isChildAddressCodeValid}
            onChange={(e) => {
              setChild_code(getPostalCodeFromInput(e.target.value));
            }}
            isDisabled={saved || sameAdress}
          />
        </FormControl>
        <FormControl id="city">
          <FormLabel>Miasto</FormLabel>
          <Input
            type="text"
            bg={"white"}
            onChange={(e) => {
              setChild_city(e.target.value);
            }}
            defaultValue={
              sameAdress
                ? child.address?.city || undefined
                : child.address?.city || undefined
            }
            value={city || undefined}
            isInvalid={!isChildAddressCityValid}
            isDisabled={saved || sameAdress}
          />
        </FormControl>
      </HStack>
      <FormControl id="string">
        <FormLabel>Dodatkowe informacje nt. zdrowia</FormLabel>
        <Input
          type="text"
          bg={"white"}
          isDisabled={saved}
          onChange={(e) => {
            setChild_health(
              _.isEmpty(e.target.value) ? undefined : e.target.value
            );
          }}
          defaultValue={child.health || undefined}
        />
      </FormControl>

      <HStack spacing={5}>
        <Button
          bg={"blue.400"}
          color={"white"}
          _hover={{
            bg: "blue.500",
          }}
          isDisabled={isDisabled}
          onClick={() => {
            const newOrder: Child = {
              ...child,
              ...(name ? { name } : {}),
              ...(surname ? { surname } : {}),
              ...(birthday ? { birthday } : {}),
              ...(pesel ? { pesel } : {}),
              address: {
                ...child.address,
                ...(city ? { city } : {}),
                ...(code ? { code } : {}),
                ...(street ? { street } : {}),
              } as Child["address"],
              health,
              // isChildStepDone: true,
            };
            setChild(newOrder);
            // setSaved(true)
          }}
        >
          Zapisz
        </Button>
        {removeChild && !isPlaceholder && (
          <Button
            colorScheme="red"
            isDisabled={saved || isDisabled || !canDelete}
            onClick={() => {
              removeChild(child);
            }}
          >
            Usuń profil dziecka
          </Button>
        )}
        {!canDelete &&
          TooltipWrapper({
            label:
              "Dziecko którego dane chcesz usunąć posiada aktywne zamówienia. W razie pytań lub wątpliwości skontaktuj się z biurem Sportteam",
            isError: true,
          })}

        {removeChild && isPlaceholder && (
          <Button
            colorScheme="red"
            isDisabled={!isPlaceholder}
            onClick={() => {
              removeChild(child);
            }}
          >
            Usuń tymczasowy profil dziecka
          </Button>
        )}
        {addChild && <AddChildButton isDisabled={false} addChild={addChild} />}
      </HStack>
    </Stack>
  );
}

import { Center } from "@chakra-ui/react";
import { CardBody } from "@paljs/ui/Card";

import Layout from "../../components/layout";
import { Card, Col, Row } from "../../components/paljs";

import Description from "./description";
import Form from "./form";

const Summer = () => {
  const colBreakPoint = { sm: 12, lg: 6, xxxl: 6 };
  return (
    <Layout>
      <Center>
        <Row>
          <Col breakPoint={colBreakPoint}>
            <Card>
              <CardBody>
                <Description />
              </CardBody>
            </Card>
          </Col>
          <Col breakPoint={colBreakPoint}>
            <Card>
              <CardBody>
                <Form />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Center>
    </Layout>
  );
};

export default Summer;

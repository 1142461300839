import { useNavigate } from "react-router-dom";
import Alert from "@paljs/ui/Alert";
import { Button } from "@paljs/ui/Button";
const AlertAny = Alert as any;

const MissingProfileDataAlert = ({
  parent,
  simple,
}: {
  parent: boolean;
  simple?: boolean;
}) => {
  const navigate = useNavigate();

  const subheader = !simple ? (
    <>
      <br />
      Dane należy uzupełnić w sekcji profili.
    </>
  ) : null;

  return (
    <AlertAny status="Warning">
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        Brak wymaganych danych osobowych {parent ? "opiekuna" : "dzieci"}!{" "}
        {subheader}
      </div>
      {!simple && (
        <>
          <br />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              status="Control"
              type="button"
              shape="SemiRound"
              onClick={() => {
                navigate(`/profile/${parent ? "parent" : "children"}`);
              }}
            >
              Przejdź do profilu {parent ? "opiekuna" : "dzieci"}
            </Button>
          </div>
        </>
      )}
    </AlertAny>
  );
};

const EmptyBasketAlert = () => {
  return (
    <AlertAny outline="Control">
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        Pusty koszyk
      </div>
    </AlertAny>
  );
};

const PreviousStepNotCompleteAlert = () => {
  return (
    <AlertAny outline="Control">
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        Aby wyświetlić zawartość, uzupełnij poprzednie kroki formularza
      </div>
    </AlertAny>
  );
};

const GenericErrorAlert = () => {
  return (
    <AlertAny status="Danger">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          textAlign: "center",
        }}
      >
        <div>Wystąpił błąd.</div>
        <div>Odśwież strone i spróbuj ponownie.</div>
        <br />
        <div>
          Jeżeli problem bedzie się powtarzać skontaktuj się z koorynatorem
          Sportteam.
        </div>
      </div>
    </AlertAny>
  );
};

export {
  EmptyBasketAlert,
  GenericErrorAlert,
  MissingProfileDataAlert,
  PreviousStepNotCompleteAlert,
};

import { useEffect } from "react";
import { GrowthBook } from "@growthbook/growthbook-react";
import { useQuery } from "graphql-hooks";
import _ from "lodash";

import { GET_ME_ID } from "./data/queries";

const growthbook = new GrowthBook();

const UNKNOWN_ID = "unknown";

const GrowthBookEnricher = () => {
  const { data } = useQuery<any>(GET_ME_ID, {
    useCache: true,
  });

  const meId = _.get(data, "me.id", UNKNOWN_ID);

  useEffect(() => {
    if (meId !== UNKNOWN_ID) {
      console.log("growthbook id attrib set");
      growthbook.setAttributes({
        id: meId,
      });
    }
  }, [meId]);

  return <></>;
};

export { GrowthBookEnricher };
export default growthbook;

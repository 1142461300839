const appConfig = {
  isProd: process.env.REACT_APP_ENVIRONMENT === "production",
  AWS: {
    region: process.env.REACT_APP_AWS_REGION as string,
    poolId: process.env.REACT_APP_AWS_POOL_ID as string,
    clientId: process.env.REACT_APP_AWS_CLIENT_ID as string,
    identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID as string,
  },
  API_URL: process.env.REACT_APP_GRAPHQL_API as string,
  ENVIRONMENT: process.env.REACT_APP_ENVIRONMENT as string,
  SENTRY_RELEASE: process.env.REACT_APP_SENTRY_RELEASE as string,
  FEATURE_TOGGLE: {
    URL: process.env.REACT_APP_FEATURE_TOGGLE_URL as string,
    enable_camps: "camp-process",
    show_summer_camp: "show-summer-camp",
  },
  Background: {
    signIn:
      "https://images.unsplash.com/photo-1606092195730-5d7b9af1efc5?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2370&q=80",
    signUp:
      "https://images.unsplash.com/photo-1606092195730-5d7b9af1efc5?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2370&q=80",
    verify:
      "https://images.unsplash.com/photo-1606092195730-5d7b9af1efc5?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2370&q=80",
    forgot:
      "https://images.unsplash.com/photo-1606092195730-5d7b9af1efc5?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2370&q=80",
    change:
      "https://images.unsplash.com/photo-1606092195730-5d7b9af1efc5?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2370&q=80",
  },
};

export default appConfig;

import { useState } from "react";

import Modal from "../components/modals";
import Promo2022 from "../parts/offers";

export default function Home() {
  const [isOpen, setIsOpen] = useState(false);
  const onClose = () => {
    console.log("user seen message");
    setIsOpen(false);
  };

  return (
    <>
      <Modal title="Witaj!" isOpen={isOpen} onClose={onClose}>
        <div>Z dniem 01.01.2023 wprowadziliśmy zmiany w regulaminach zajęć</div>
        <br />
        <div>Cale copy inne plz</div>
        <br />

        <div>
          Przejdź do Profilu Opiekuna, albo do koszyka przy skladaniu
          zamowienia, aby się z nimi zapoznać i zaktualizować wyrazić zgodę.
        </div>
      </Modal>

      <Promo2022 />
    </>
  );
}

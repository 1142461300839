import { useState } from "react";
import { Flex, Heading, useMediaQuery } from "@chakra-ui/react";
import { CardBody } from "@paljs/ui/Card";
import { useMutation, useQuery } from "graphql-hooks";
import _ from "lodash";

import {
  GenericErrorAlert,
  MissingProfileDataAlert,
} from "../../components/alerts";
import Layout from "../../components/layout";
import Loading from "../../components/loading";
import { Card, Col, Row } from "../../components/paljs";
import { ADD_CHILD, REMOVE_CHILD, UPDATE_CHILD } from "../../data/mutations";
import { GET_CHILDREN } from "../../data/queries";
import ChildForm, { AddChildButton } from "../../parts/child";
import { Child, GetChildrenQuery } from "../../types";

const ChildC = () => {
  const [isLargerThan760] = useMediaQuery("(min-width: 760px)");
  const [showPlaceholderForm, setShowPlaceholderForm] = useState(false);

  const { loading, data, error } = useQuery<GetChildrenQuery>(GET_CHILDREN, {
    refetchAfterMutations: [UPDATE_CHILD, REMOVE_CHILD, ADD_CHILD],
    useCache: false,
  });
  const [updateChild, { loading: childUpdateLoading }] =
    useMutation(UPDATE_CHILD);
  const [removeChild, { loading: childRemoveLoading }] =
    useMutation(REMOVE_CHILD);
  const [addChild, { loading: childAddLoading }] = useMutation(ADD_CHILD);

  const anyLoading =
    loading || childUpdateLoading || childRemoveLoading || childAddLoading;

  const addChildPlaceholder = {
    id: "addChildPlaceholder",
    isIncomplete: true,
    isPlaceholder: true,
    canDelete: true,
  };

  const setChild = (child: Omit<Child, "isIncomplete">) => {
    const omitProps = [
      "id",
      "isIncomplete",
      "isPlaceholder",
      "canDelete",
      "adventure",
      "funActive",
    ];
    if (child.id === addChildPlaceholder.id) {
      addChild({
        variables: {
          input: {
            ..._.omit(child, omitProps),
          },
        },
      });
      setShowPlaceholderForm(false);
    } else {
      updateChild({
        variables: {
          input: {
            ..._.omit(child, omitProps),
          },
          accountId: data?.me.id,
          childId: child.id,
        },
      });
    }
  };
  const handleRemoveChild = (child: Child) => {
    if (child.id === addChildPlaceholder.id) {
      setShowPlaceholderForm(false);
    } else {
      removeChild({ variables: { childId: child.id } });
    }
  };

  const queryChildren = data?.me.children || [];
  const childrenList = [
    ..._.sortBy(queryChildren, "name"),
    addChildPlaceholder,
  ];

  const AddChildCardBody = () => {
    return (
      <Flex justify={"center"} align="center" minH={300}>
        <AddChildButton
          addChild={() => setShowPlaceholderForm(true)}
          isDisabled={false}
        />
      </Flex>
    );
  };

  return (
    <Layout>
      <Row>
        <Col breakPoint={{ sm: 12 }}>
          <Heading
            fontSize={isLargerThan760 ? "2xl" : "xl"}
            padding={0}
            color={"gray.700"}
          >
            <Flex justifyContent={"space-between"} alignItems={"center"}>
              Dane dzieci
            </Flex>
          </Heading>
          <br />
        </Col>
      </Row>
      {anyLoading && <Loading />}
      {error && <GenericErrorAlert />}
      <Row>
        {data &&
          _.map(childrenList, (child) => {
            return (
              <Col breakPoint={{ sm: 12, md: 6, xxl: 4 }} key={child.id}>
                <Card>
                  <CardBody>
                    {child.id === addChildPlaceholder.id &&
                    !showPlaceholderForm ? (
                      <AddChildCardBody />
                    ) : (
                      <>
                        {child.isIncomplete && (
                          <MissingProfileDataAlert
                            parent={false}
                            simple={true}
                          />
                        )}
                        <ChildForm
                          child={child as Child}
                          setChild={setChild}
                          parentAddress={data.me.address}
                          removeChild={handleRemoveChild}
                          isLoading={
                            childUpdateLoading ||
                            childRemoveLoading ||
                            childAddLoading
                          }
                        />
                      </>
                    )}
                  </CardBody>
                </Card>
              </Col>
            );
          })}
      </Row>
    </Layout>
  );
};
export default ChildC;

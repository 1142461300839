import { createTheme } from "@paljs/theme";
import { type DefaultTheme } from "styled-components";

const shared: Partial<DefaultTheme> = {
  sidebarHeaderGap: "2rem",
  fontFamilyPrimary: `-apple-system,BlinkMacSystemFont,
          "Segoe UI",Roboto,"Helvetica Neue",
          Arial,sans-serif,"Apple Color Emoji",
          "Segoe UI Emoji","Segoe UI Symbol"`,
};

export default function themeService() {
  return createTheme("default", { dir: "ltr", ...shared });
}

import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  HStack,
  Link,
  useMediaQuery,
} from "@chakra-ui/react";
import _ from "lodash";

import type { AccountConsents } from "../../types";

export const serviceTermsURL = "/pdfs/RegulaminUslug.pdf";
export const activitiesTermsURL = "/pdfs/RegulaminZajec.pdf";
export const tripWinter2022TermsURL = "/pdfs/RegulaminZima.pdf";
export const tripSummer2023TermsURL = "/pdfs/RegulaminLato.pdf";
export const privacyTermsURL = "/pdfs/PolitykaPrywatnosci.pdf";
export const insuranceTermsURL = "/pdfs/Insurance.pdf";

export const hasAllRequiredConsents = (consents: AccountConsents): boolean => {
  return _.every(_.pick(consents, requiredConsents)) || false;
};
export const hasMissingConsents = (consents: AccountConsents): boolean => {
  return Object.values(consents).some(
    (value) => value === undefined || value === null
  );
};

export const requiredConsents = [
  "health",
  "healthProcessing",
  "knowledge",
  "insurance",
];

const knowledgeAgreement = () => {
  return (
    <span>
      Potwierdzam, że zapoznałem się z{" "}
      <Link
        target="_blank"
        href={serviceTermsURL}
        textDecor="underline"
        isExternal
      >
        Regulaminem Serwisu
      </Link>{" "}
      oraz{" "}
      <Link
        target="_blank"
        href={privacyTermsURL}
        textDecor="underline"
        isExternal
      >
        Polityką Prywatności
      </Link>
      .
    </span>
  );
};
const healthAgreement =
  "Potwierdzam że zgłoszony uczestnik/uczestnicy nie mają przeciwwskazań zdrowotnych do udziału w zajęciach i aktywnościach organizowanych przez Klub Sportteam Sp. z o.o.";
const healthProcessingAgreement =
  "Wyrażam zgodę na przetwarzanie podanych danych osobowych i danych dotyczących stanu zdrowia w celu zapewnienia prawidłowej opieki w ramach usług świadczonych przez Klub Sportteam Sp. z o.o.";

const communicationAgreement =
  "Wyrażam zgodę na otrzymywanie informacji dotyczących oferty zajęć sportowych, wyjazdów oraz innych produktów i usług oferowanych przez Klub Sportteam Sp. z o.o. w następującej formie:";

export const insuranceAgreement = () => {
  return (
    <span>
      Oświadczam, że otrzymałem{" "}
      <Link target="_blank" href={insuranceTermsURL}>
        Ogólne Warunki Ubezpieczenia NNW
      </Link>
      , ustalonych uchwałą Zarządu Powszechnego Zakładu Ubezpieczeń Spółki
      Akcyjnej nr UZ/319/2020 z dnia 9 listopada 2020 r., przed przystąpieniem
      do umowy oraz przed wyrażeniem zgody na finansowanie kosztu składki
      ubezpieczeniowej
    </span>
  );
};

export const campAgreement = (name: string, url: string, full?: boolean) => {
  return (
    <span>
      Potwierdzam że zapoznałem się z{" "}
      <Link target="_blank" href={url}>
        {full ? name : `Regulaminem Wyjazdu - ${name}`}
      </Link>
    </span>
  );
};

export const itemAgreement = (name: string, url: string) => {
  return (
    <span>
      Potwierdzam że zapoznałem się z{" "}
      <Link target="_blank" href={url}>
        Regulaminem Zajęć
        {/* Regulaminem Zajęć - {`${name}`} */}
      </Link>
    </span>
  );
};

const communicationMethods = {
  email: "wiadomość e-mail",
  text: "wiadomość SMS/MMS",
  call: "połączenia telefoniczne",
};

type Consents = {
  knowledge?: boolean;
  health?: boolean;
  healthProcessing?: boolean;
  email?: boolean;
  text?: boolean;
  call?: boolean;
};
type Props = {
  setHasRequiredConsents?: (has: boolean) => void;
  setConsents: (consents: Consents) => void;
  consents: Consents;
  requiredConsents: string[];
  hideNonEditable?: boolean;
  hideSelectAll?: boolean;
};

export default function ActivityStep({
  setHasRequiredConsents = _.identity,
  setConsents,
  consents: initialConsents,
  requiredConsents,
  hideNonEditable = true,
  hideSelectAll = false,
}: Props) {
  const [isLargerThan760] = useMediaQuery("(min-width: 760px)");

  const [knowledge, setKnowledge] = useState(
    initialConsents.knowledge || false
  );
  const [health, setHealth] = useState(initialConsents.health || false);
  const [healthProcessing, setHealthProcessing] = useState(
    initialConsents.healthProcessing || false
  );
  const [email, setEmail] = useState(initialConsents.email || false);
  const [text, setText] = useState(initialConsents.text || false);
  const [call, setCall] = useState(initialConsents.call || false);

  // const hasRequiredAgreementsFilled =
  //   knowledge && health && healthProcessing;

  const hasAllOptionalsValuesFilled = email && text && call;
  const hasSomeOptionalsValuesMissing = !email || !text || !call;
  const allOptionalValuesMissing = !email && !text && !call;
  const allConsentsGiven =
    knowledge && health && healthProcessing && email && text && call;

  const isCheckboxDisabled = false;
  const allProperties: Array<keyof Consents> = [
    "knowledge",
    "health",
    "healthProcessing",
    "email",
    "text",
    "call",
  ];
  const editableProperties = allProperties;

  const displayableProperties = allProperties.filter(
    (propertyName) =>
      !hideNonEditable || editableProperties.includes(propertyName)
  );
  const temporaryConsentsObject = {
    knowledge,
    health,
    healthProcessing,
    email,
    text,
    call,
  };
  const allEditableConsentsGiven = _.every(
    _.pick(temporaryConsentsObject, editableProperties)
  );

  useEffect(() => {
    const consentsObject = {
      knowledge,
      health,
      healthProcessing,
      email,
      text,
      call,
    };
    setHasRequiredConsents(_.every(_.pick(consentsObject, requiredConsents)));
    setConsents(consentsObject);
  }, [knowledge, health, healthProcessing, email, text, call]);

  const Agreement = (text: any, options: any, boxOptions?: any) => {
    return (
      <Checkbox colorScheme="blue" {...options}>
        <Box
          pb={2}
          pl={2}
          {...boxOptions}
          fontSize={isLargerThan760 ? "0.8rem" : "0.7rem"}
        >
          {text}
          {options.isRequired ? <span style={{ color: "red" }}>*</span> : null}
        </Box>
      </Checkbox>
    );
  };

  const CommunicationMethods = () => (
    <>
      {Agreement(communicationMethods.email, {
        isDisabled: isCheckboxDisabled,
        isChecked: email,
        onChange: () => setEmail(!email),
        pl: 0,
        pr: 9,
      })}
      {Agreement(communicationMethods.text, {
        isDisabled: isCheckboxDisabled,
        isChecked: text,
        onChange: () => setText(!text),
        pl: 0,
        pr: 9,
      })}
      {Agreement(communicationMethods.call, {
        isDisabled: isCheckboxDisabled,
        isChecked: call,
        onChange: () => setCall(!call),
        pl: 0,
        pr: 9,
      })}
    </>
  );

  // const NewAgreeementsAlert = () => {
  //   return (
  //     <Alert status="warning" my={3}>
  //       <AlertIcon />
  //       <Stack>
  //         <AlertTitle>Szanowni Państwo</AlertTitle>
  //         <AlertDescription>
  //           Od 01.12.2022 Sportteam działa w nowej formule organizacyjnej jako
  //           Klub Sportteam Sport&Adventure Sp.z o.o.
  //           <br />W zawiązku z tą zmianą prosimy o zapoznanie się z dokumentami
  //           oraz ponowne wyrażenie zgód prawnych i marketingowych.
  //           <br /> Niezmienna pozostaje jakość naszej pracy i zaangażowanie w
  //           organizowanie dla Was zajęć i wyjazdów!
  //         </AlertDescription>
  //         <AlertDescription>Pozdrawiamy - Klub Sportteam</AlertDescription>
  //       </Stack>
  //     </Alert>
  //   );
  // };

  const AgreementsPart = () => {
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        {/* {NewAgreeementsAlert()} */}
        {!hideSelectAll && (
          <div>
            <Button
              colorScheme="blue"
              my={3}
              isDisabled={
                isCheckboxDisabled ||
                allConsentsGiven ||
                allEditableConsentsGiven
              }
              onClick={() => {
                if (editableProperties.includes("knowledge"))
                  setKnowledge(true);
                if (editableProperties.includes("health")) setHealth(true);
                if (editableProperties.includes("healthProcessing"))
                  setHealthProcessing(true);
                if (editableProperties.includes("email")) setEmail(true);
                if (editableProperties.includes("text")) setText(true);
                if (editableProperties.includes("call")) setCall(true);
              }}
            >
              Zaznacz wszystkie zgody
            </Button>
          </div>
        )}
        {displayableProperties.includes("knowledge") &&
          Agreement(knowledgeAgreement(), {
            isRequired: true,
            isDisabled:
              isCheckboxDisabled || !editableProperties.includes("knowledge"),
            isChecked: knowledge,
            onChange: () => setKnowledge(!knowledge),
          })}
        {displayableProperties.includes("health") &&
          Agreement(healthAgreement, {
            isRequired: true,
            isDisabled:
              isCheckboxDisabled || !editableProperties.includes("health"),
            isChecked: health,
            onChange: () => setHealth(!health),
          })}
        {displayableProperties.includes("healthProcessing") &&
          Agreement(healthProcessingAgreement, {
            isRequired: true,
            isDisabled:
              isCheckboxDisabled ||
              !editableProperties.includes("healthProcessing"),
            isChecked: healthProcessing,
            onChange: () => setHealthProcessing(!healthProcessing),
          })}
        {(displayableProperties.includes("call") ||
          displayableProperties.includes("email") ||
          displayableProperties.includes("text")) &&
          Agreement(communicationAgreement, {
            isDisabled: isCheckboxDisabled,
            isChecked: email && text && call,
            isIndeterminate:
              !hasAllOptionalsValuesFilled &&
              hasSomeOptionalsValuesMissing &&
              !allOptionalValuesMissing,

            onChange: () => {
              if (!email || !text || !call) {
                setEmail(true);
                setText(true);
                setCall(true);
              } else {
                setEmail(false);
                setText(false);
                setCall(false);
              }
            },
          })}
        {isLargerThan760 ? (
          <HStack spacing={1} pl={8}>
            {(displayableProperties.includes("call") ||
              displayableProperties.includes("email") ||
              displayableProperties.includes("text")) &&
              CommunicationMethods()}
          </HStack>
        ) : (
          (displayableProperties.includes("call") ||
            displayableProperties.includes("email") ||
            displayableProperties.includes("text")) &&
          CommunicationMethods()
        )}
        <div>
          <small style={{ fontSize: "0.6rem" }}>
            {" "}
            <span style={{ color: "red" }}>*</span> zgody wymagane
          </small>
        </div>
      </div>
    );
  };

  return AgreementsPart();
}

import { useState } from "react";
import { FaTimes } from "react-icons/fa";
import { Box, Heading, Icon, useMediaQuery } from "@chakra-ui/react";
import { Button, Input, Text } from "@chakra-ui/react";
import { CardBody } from "@paljs/ui/Card";
import { useMutation } from "graphql-hooks";

import { Col, Row } from "../../components/paljs";
import { Card } from "../../components/paljs";
import { ADD_DISCOUNT_CODE, REMOVE_DISCOUNT_CODE } from "../../data/mutations";
import {
  MutationCartAddDiscountCodeArgs,
  MutationCartRemoveDiscountCodeArgs,
} from "../../types";

const DISCOUNT_LIMIT = 50;

type DiscountCardProps = {
  currentCode: string | undefined;
};
const DiscountCard = ({ currentCode }: DiscountCardProps) => {
  const [isBigScreen] = useMediaQuery("(min-width: 1300px)");
  const hasDiscountCode = currentCode !== undefined;

  const [discountCode, setDiscountCode] = useState(currentCode || "");
  const [isDisabled, setIsDisabled] = useState(currentCode !== undefined);

  const [addDiscount] =
    useMutation<MutationCartAddDiscountCodeArgs>(ADD_DISCOUNT_CODE);
  const [removeDiscount] =
    useMutation<MutationCartRemoveDiscountCodeArgs>(REMOVE_DISCOUNT_CODE);

  const handleDiscountAdd = () => {
    setIsDisabled(true);
    addDiscount({ variables: { code: discountCode } });
  };

  const handleDiscountRemove = () => {
    setIsDisabled(false);
    setDiscountCode("");
    if (hasDiscountCode) {
      removeDiscount({ variables: { code: currentCode } });
    }
  };

  const noTempValue = discountCode === "";
  const canRemove = !noTempValue || hasDiscountCode;
  return (
    <Card>
      <CardBody>
        <Row>
          <Col
            breakPoint={{ sm: 12, md: 3 }}
            style={{ marginTop: "auto", marginBottom: "auto" }}
          >
            <Heading
              fontSize={isBigScreen ? "xl" : "lg"}
              padding={0}
              color={"gray.700"}
              m={2}
            >
              Kod rabatowy
            </Heading>
          </Col>
          <Col
            breakPoint={{ sm: 12, md: 3 }}
            style={{ marginTop: "auto", marginBottom: "auto" }}
          >
            <Input
              m={2}
              type="text"
              bg={"white"}
              isDisabled={isDisabled}
              onChange={(e) =>
                setDiscountCode(e.target.value.substring(0, DISCOUNT_LIMIT))
              }
              value={discountCode}
            />
          </Col>

          <Col
            breakPoint={{ sm: 12, md: 3 }}
            style={{ marginTop: "auto", marginBottom: "auto" }}
          >
            <Box>
              <Button
                m={2}
                isDisabled={noTempValue}
                onClick={handleDiscountAdd}
                size="sm"
              >
                Zastosuj kod
              </Button>
              <Button
                onClick={handleDiscountRemove}
                m={2}
                size="sm"
                isDisabled={!canRemove}
              >
                <Icon as={FaTimes} />
              </Button>
            </Box>
          </Col>

          <Col
            breakPoint={{ sm: 12, md: 3 }}
            style={{
              textAlign: "right",
              marginTop: "auto",
              marginBottom: "auto",
            }}
          >
            <Text fontSize="sm" m={2}>
              Kodu można użyć tylko przy <u>płatności jednorazowej</u>
            </Text>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default DiscountCard;

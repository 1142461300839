import React from "react";
import { Carousel } from "react-responsive-carousel";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Center,
  Heading as HeadingC,
  useMediaQuery,
} from "@chakra-ui/react";
import { AspectRatio, Image, Skeleton, Stack } from "@chakra-ui/react";
import { useFeature } from "@growthbook/growthbook-react";
import { CardBody, CardFooter, CardHeader } from "@paljs/ui/Card";
import { useQuery } from "graphql-hooks";
import _ from "lodash";

import Layout from "../../components/layout";
import { Card, Col, Row } from "../../components/paljs";
import config from "../../config";
import { splitTurnusName } from "../../utils/helpers";

import {
  sem_2_2023_2024,
  summer2023Adventure,
  summer2023FunActive,
  summer2024Sailing,
  winter2023,
} from "./copy";
import { GET_SUMMER_CAMPS_2023 } from "./data";

import "react-responsive-carousel/lib/styles/carousel.min.css";

type PromoCard = {
  header: string;
  subheader?: React.Component;
  body: string;
  path: string;
  images: string[];
  accent: string;
  customButtonLabel?: string;
};

export default function Offers() {
  const { enable_camps, show_summer_camp } = config.FEATURE_TOGGLE;
  const [isLargerThan760] = useMediaQuery("(min-width: 760px)");
  const navigate = useNavigate();

  const summerCamps = useQuery(GET_SUMMER_CAMPS_2023, {
    useCache: false,
  });

  const adventure2023 = _.get(summerCamps, "data.adventure2023", []);
  const funActive2023 = _.get(summerCamps, "data.funActive2023", []);
  const sailing2024 = _.get(summerCamps, "data.sailing2024", []);

  const parsedAdventure2023DescriptionRows = _.map(
    adventure2023,
    splitTurnusName
  );
  const parsedFunActive2023DescriptionRows = _.map(
    funActive2023,
    splitTurnusName
  );
  const parsedSailing2024DescriptionRows = _.map(sailing2024, splitTurnusName);

  const summer2023FunActiveOption = summer2023FunActive(
    _.reverse(parsedFunActive2023DescriptionRows)
  );
  const summer2023AdventureOption = summer2023Adventure(
    _.reverse(parsedAdventure2023DescriptionRows)
  );
  const summer2024SailingOption = summer2024Sailing(
    _.reverse(parsedSailing2024DescriptionRows)
  );

  const { on: showWinterCamp } = useFeature(enable_camps);
  const { on: showSummerCamp } = useFeature(show_summer_camp);

  const options = _.reject(
    [
      showSummerCamp ? summer2023FunActiveOption : {},
      showSummerCamp ? summer2023AdventureOption : {},
      showSummerCamp ? summer2024SailingOption : {},
      showWinterCamp ? winter2023 : {},
      sem_2_2023_2024,
    ],
    _.isEmpty
  ) as PromoCard[];

  const LoadingSkeleton = () => (
    <Stack>
      <Skeleton height="20px" />
      <Skeleton height="20px" />
      <Skeleton height="20px" />
      <Skeleton height="20px" />
      <Skeleton height="20px" />
    </Stack>
  );
  return (
    <Layout>
      <Center>
        <Col breakPoint={{ sm: 12 }}>
          <Row>
            <Col breakPoint={{ sm: 12, md: 6 }}>
              <Box mb={5} justifyContent="center">
                <HeadingC
                  fontSize={isLargerThan760 ? "2xl" : "xl"}
                  padding={0}
                  color={"gray.700"}
                >
                  Witaj w panelu Sportteam!
                </HeadingC>
              </Box>
            </Col>
          </Row>

          <Row>
            {_.map(options, (option, idx) => {
              return (
                <Col breakPoint={{ sm: 12, md: 6, xl: 4 }} key={idx}>
                  <Box
                    onClick={() => navigate(option.path as string)}
                    style={{ cursor: "pointer" }}
                    className="grow"
                    m={3}
                  >
                    <Card accent={option.accent}>
                      <Box minH={220}>
                        <CardHeader>
                          <h4>{option.header}</h4>
                        </CardHeader>
                        {option.subheader && (
                          <CardBody>
                            <>{option.subheader}</>
                          </CardBody>
                        )}
                      </Box>
                      {_.size(option.images) === 1 ? (
                        <AspectRatio ratio={4 / 3}>
                          <Image
                            src={option.images[0]}
                            alt="sportteam logo"
                            objectFit="cover"
                          />
                        </AspectRatio>
                      ) : (
                        <Carousel
                          showArrows={false}
                          showStatus={false}
                          showIndicators={false}
                          showThumbs={false}
                          autoPlay={true}
                          interval={3000}
                          infiniteLoop={true}
                        >
                          {_.map(option.images, (path) => (
                            <img src={path} key={path} alt={option.header} />
                          ))}
                        </Carousel>
                      )}
                      <Box minH={180}>
                        <CardBody>
                          {!summerCamps.loading ? (
                            option.body
                          ) : (
                            <LoadingSkeleton />
                          )}
                        </CardBody>
                      </Box>
                      <CardFooter>
                        <Center>
                          <Button
                            onClick={() => navigate(option.path as string)}
                          >
                            {option.customButtonLabel
                              ? option.customButtonLabel
                              : "Przejdź do zapisów"}
                          </Button>
                        </Center>
                      </CardFooter>
                    </Card>
                  </Box>
                </Col>
              );
            })}
          </Row>
        </Col>
      </Center>
    </Layout>
  );
}

import { Heading } from "@chakra-ui/react";

import BasicUsage from "./index";

type Props = {
  isOpen: any;
  onClose: any;
};

function SuccessBuyModal({ isOpen, onClose }: Props) {
  return (
    <>
      <BasicUsage isOpen={isOpen} onClose={onClose} title="" size="4xl">
        <br />
        <br />

        <Heading textAlign="center">Dziękujemy za dokonanie płatności!</Heading>
        <br />
        <br />
        <Heading size="large" textAlign="center">
          Droga mailową otrzymasz <br />
          potwierdzenie warunków umowy. <br />
        </Heading>
        <br />
        <br />
        <Heading textAlign={"center"} size="large" color={"red.500"}>
          Pozdrawiamy Klub Sportteam
        </Heading>
      </BasicUsage>
    </>
  );
}

export default SuccessBuyModal;

import { Box, CircularProgress } from "@chakra-ui/react";

const Loading = () => {
  return (
    <Box textAlign="center" py={10} px={6} pb={12}>
      <CircularProgress isIndeterminate color="blue.500" size="120px" />
    </Box>
  );
};
export default Loading;

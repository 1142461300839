import React from "react";

export default function useTimeout(
  callback: any,
  delay: number,
  deps?: React.DependencyList | undefined
) {
  const timeoutRef = React.useRef<number | null>(null);
  const savedCallback = React.useRef(callback);
  React.useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);
  React.useEffect(() => {
    const tick = () => savedCallback.current();
    if (typeof delay === "number") {
      timeoutRef.current = window.setTimeout(tick, delay);
      return () => window.clearTimeout(timeoutRef.current as number);
    }
  }, [delay, ...(deps || [])]);
  return timeoutRef;
}

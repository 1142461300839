export const DATE_FORMAT = "dd.MM.yyyy";

export const MONTHS = [
  "Styczeń",
  "Luty",
  "Marzec",
  "Kwiecień",
  "Maj",
  "Czerwiec",
  "Lipiec",
  "Sierpień",
  "Wrzesień",
  "Październik",
  "Listopad",
  "Grudzień",
];
export const WEEKDAYS_SHORT = ["Nie", "Pon", "Wt", "Śr", "Czw", "Pt", "Sob"];

export function currentDate(dayOffset?: string) {
  const today = new Date();
  if (dayOffset) {
    today.setDate(today.getDate() + parseInt(dayOffset, 10));
  }
  const dd = String(today.getDate()).padStart(2, "0");
  const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  const yyyy = today.getFullYear();
  return yyyy + "-" + mm + "-" + dd;
}

export const commonDayPickerProps = {
  months: MONTHS,
  weekdaysShort: WEEKDAYS_SHORT,
  firstDayOfWeek: 1,
  numberOfMonths: 1,
};

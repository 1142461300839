import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button } from "@chakra-ui/react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import { AuthWrapper, Email } from "../../components/auth";
import config from "../../config";
import { useValidEmail } from "../../hooks/useAuthHooks";
import { AuthContext } from "../../utils/authContext";
const bgImgUrl = config.Background.signIn;

export default function RequestCode() {
  const { email, setEmail, emailIsValid } = useValidEmail("");
  const [error, setError] = useState("");
  const [resetSent, setResetSent] = useState(false);

  const isValid = !emailIsValid || email.length === 0;

  const navigate = useNavigate();

  const authContext = useContext(AuthContext);

  const sendCodeClicked = async () => {
    try {
      await authContext.sendCode(email);
      setResetSent(true);
    } catch (err) {
      setError("Unknown user");
    }
  };

  const emailSent = (
    <>
      <Box mt={4}>
        <Button onClick={() => navigate("/forgotpassword")} colorScheme="blue">
          Utwórz nowe hasło
        </Button>
      </Box>
    </>
  );

  const sendCode = (
    <>
      <Box width="80%" m={1}>
        <Email emailIsValid={emailIsValid} setEmail={setEmail} />
      </Box>
      <Box mt={2}>
        <Typography color="error" variant="body2">
          {error}
        </Typography>
      </Box>

      <Box mt={2}>
        <Grid container direction="row" justify="center">
          <Box m={1}>
            <Button onClick={() => navigate(-1)}>Powrót</Button>
          </Box>
          <Box m={1}>
            <Button
              disabled={isValid}
              colorScheme="blue"
              onClick={sendCodeClicked}
            >
              Wyślij kod
            </Button>
          </Box>
        </Grid>
      </Box>
    </>
  );

  const title = resetSent
    ? (`Kod wysłano na email: ${email}` as string)
    : ("Wyślij kod do zresetowania hasła" as string);

  return (
    <AuthWrapper bgImgUrl={bgImgUrl} title={title as string}>
      {resetSent ? emailSent : sendCode}
    </AuthWrapper>
  );
}

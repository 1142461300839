import { RiPercentLine } from "react-icons/ri";
import { Box, Flex, Icon, useMediaQuery } from "@chakra-ui/react";

import { Col, Row } from "../../../../components/paljs";

const DiscountRow = ({
  price,
  name,
  type = "VALUE",
}: {
  price: number;
  name?: string;
  type: "PERCENTAGE" | "VALUE";
}) => {
  const [isBigScreen] = useMediaQuery("(min-width: 1300px)");
  return (
    <Box mb={3}>
      <Row>
        <Col breakPoint={{ sm: isBigScreen ? 4 : 3 }}>
          <Flex align="center">{<Icon as={RiPercentLine} mr={2} />}</Flex>
        </Col>

        <Col breakPoint={{ sm: isBigScreen ? 6 : 7 }}>{name}</Col>

        <Col breakPoint={{ sm: 2 }} style={{ textAlign: "right" }}>
          -{price}&nbsp;{type === "VALUE" ? "zł" : "%"}
        </Col>
      </Row>
    </Box>
  );
};

export default DiscountRow;

import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box } from "@chakra-ui/react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import { AuthContext } from "../../utils/authContext";

const useStyles = makeStyles({
  root: {
    height: "100vh",
  },
});

export default function SignOut() {
  const classes = useStyles();
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    authContext.signOut();
    navigate("/");
  }, []);

  return (
    <Grid
      className={classes.root}
      container
      direction="row"
      justify="center"
      alignItems="center"
    >
      <Grid
        xs={11}
        sm={6}
        lg={4}
        container
        direction="row"
        justify="center"
        alignItems="center"
        item
      >
        <Paper style={{ width: "100%", padding: 32 }}>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
          >
            <Box m={2}>
              <Typography variant="h3">Trwa wylogowywanie</Typography>
            </Box>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}

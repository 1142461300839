import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Badge,
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Heading,
  List,
  ListItem,
  Spacer,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import { useFeature } from "@growthbook/growthbook-react";
import { CardBody } from "@paljs/ui/Card";
import { useManualQuery, useMutation, useQuery } from "graphql-hooks";
import _ from "lodash";

import {
  activitiesTermsURL,
  tripSummer2023TermsURL,
  tripWinter2022TermsURL,
} from "../../components/agreements";
import Agreements, {
  hasAllRequiredConsents,
  requiredConsents,
} from "../../components/agreements";
import {
  GenericErrorAlert,
  MissingProfileDataAlert,
} from "../../components/alerts";
import { MembershipSection as MembershipSelectSection } from "../../components/cartItem/subcomponents";
import DiscountCard from "../../components/discounts";
import { EmptyBasket } from "../../components/empty-state";
import Layout from "../../components/layout";
import Loading from "../../components/loading";
import { Card, Col, Row } from "../../components/paljs";
import { missingAccountData, TooltipWrapper } from "../../components/tooltip";
import {
  ADD_DISCOUNT_CODE,
  ADD_MEMBERSHIP_ITEM,
  PLACE_ORDER,
  REMOVE_CART_ITEM,
  REMOVE_DISCOUNT_CODE,
  UPDATE_CHILD,
  UPDATE_CUSTOM_DATA,
  UPDATE_ME,
} from "../../data/mutations";
import {
  GET_CART_PRICE,
  GET_ORDERS,
  GET_PAYMENT_OPTION_ID,
} from "../../data/queries";
import {
  AddMembershipMutation,
  CartItemGroup,
  GetOrdersQuery,
  MembershipType,
  MutationRemoveFromCartArgs,
  OrderPlacementConsentsInput,
  PlaceOrderMutation,
  PlaceOrderMutationVariables,
  UpdateCustomDataMutation,
} from "../../types";
import {
  AdditionalInfoForID,
  checkIfEverybodyHasActivityConsent,
  checkIfEverybodyHasCampConsent,
  checkIfEverybodyHasChurchConsent,
  checkIfEverybodyHasInsuranceConsent,
  checkIfEverybodyHasTransport,
  getActivitiesIDsFromItemGroups,
  getDynamicName,
  getGenericBundleId,
  getSummerCampsIDsFromItemGroups,
  getWinterCampsIDsFromItemGroups,
  getWinterLevelName,
  // transformAdditionalInfoObjectForMutation,
} from "../../utils/helpers";
import { ADD_SUMMER_CAMPS_2023_TO_CART } from "../summer2023/data";

import OrderItemActivityRow from "./components/order-item-activity-row";
import OrderItemDiscountRow from "./components/order-item-discount-row";
import OrderItemMembershipRow from "./components/order-item-membership-row";
import OrderItemSummerCampRow from "./components/order-item-summer-camp-row";
import OrderItemWinterCampRow, {
  WinterCampDisclaimer,
} from "./components/order-item-winter-camp-row";

type PaymentTypeInMeQuery = Exclude<
  GetOrdersQuery["me"]["cart"],
  null | undefined
>["paymentOptions"][number]["payments"][number];

const Cart = () => {
  const [isBigScreen] = useMediaQuery("(min-width: 1300px)");
  const dynamicName = getDynamicName(isBigScreen);
  const navigate = useNavigate();

  const [paymentIndex, setPaymentIndex] = useState<number>(0);
  const [paymentActionStarted, setPaymentActionStarted] = useState(false);

  const [isMembershipModalOpen, setIsMembershipModalOpen] = useState(false);
  const [hasRequiredConsents, setHasRequiredConsents] = useState(false);
  const [consents, setConsents] = useState<
    PlaceOrderMutationVariables["input"]["consents"] | Record<string, boolean>
  >();

  const [additionalItemsInfo, setAdditionalItemsInfo] =
    useState<AdditionalInfoForID>({});

  const { loading, data, error } = useQuery<GetOrdersQuery>(GET_ORDERS, {
    refetchAfterMutations: [
      UPDATE_ME,
      UPDATE_CHILD,
      ADD_MEMBERSHIP_ITEM,
      PLACE_ORDER,
      REMOVE_CART_ITEM,
      ADD_DISCOUNT_CODE,
      REMOVE_DISCOUNT_CODE,
      ADD_SUMMER_CAMPS_2023_TO_CART,
    ],
    useCache: false,
  });

  const [fetchPayment, manualQueryResponse] = useManualQuery<any>(
    GET_PAYMENT_OPTION_ID
  );

  const { data: cartPriceData } = useQuery<GetOrdersQuery>(GET_CART_PRICE, {
    refetchAfterMutations: [
      UPDATE_CUSTOM_DATA,
      ADD_DISCOUNT_CODE,
      REMOVE_DISCOUNT_CODE,
      ADD_SUMMER_CAMPS_2023_TO_CART,
      ADD_MEMBERSHIP_ITEM,
    ],
    useCache: false,
  });

  const [addMembership, { loading: addMembershipLoading }] =
    useMutation<AddMembershipMutation>(ADD_MEMBERSHIP_ITEM);
  const [placeOrder, { loading: placeOrderLoading, data: placeOrderData }] =
    useMutation<PlaceOrderMutation>(PLACE_ORDER);

  const [updateCustomData] =
    useMutation<UpdateCustomDataMutation>(UPDATE_CUSTOM_DATA);

  const [removeCartItem, { loading: removeCartItemLoading }] =
    useMutation<MutationRemoveFromCartArgs>(REMOVE_CART_ITEM);

  const meId = data?.me.id;
  const cart = data?.me.cart;
  const cartProblems = cart?.problems;
  const isIncomplete = data?.me.isIncomplete;
  const itemGroups = _.get(data, "me.cart.itemsGroups");
  const discounts = _.get(cart, "discounts");
  // const hasDiscountCode = _.has(data, "me.cart.discounts.0");
  const currentDiscountCode = _.get(data, "me.cart.discountCodes[0].code");
  const paymentOptions = _.get(
    cartPriceData, // notice different data
    "me.cart.paymentOptions"
  ) as unknown as Array<{
    name: string;
  }>;
  const anyCartLoading =
    loading || addMembershipLoading || removeCartItemLoading;
  const {
    EXPIRED_MEMBERSHIP: expiredMembershipProblems,
    MISSING_ACCOUNT_DATA: missingAccountDataProblems,
    MISSING_CHILD_DATA: missingChildDataProblems,
    MISSING_MEMBERSHIP: missingMembershipProblemsOld,
    ProductOutOfStock: productsOutOfStock,
  } = _.groupBy(cartProblems, "code");

  const missingNextMembershipsProblems = _.filter(cartProblems, (problem) => {
    const isMissingProduct = problem.code === "MissingProductRequirement";
    const propositionIsMembership = _.some(
      _.get(problem, "availableProducts", []),
      ({ name }) => _.startsWith(name, "Członkostwo")
    );
    return isMissingProduct && propositionIsMembership;
  });

  const missingMembershipProblems = [
    ...missingNextMembershipsProblems,
    ...(missingMembershipProblemsOld || []),
  ];

  const setInfoStateForBundle =
    (bundleId: string) => (key: string) => (value: any) => {
      const newAdditionalInfoObject = {
        ...additionalItemsInfo,
        [bundleId]: {
          ...additionalItemsInfo[bundleId],
          [key]: value,
        },
      };
      setAdditionalItemsInfo(newAdditionalInfoObject);

      updateCustomData({
        variables: {
          itemId: bundleId,
          data: { [key]: value },
        },
      });
    };

  const getAdditionalInfoByKeyForBundle = (bundle: any) => (key: string) => {
    const bundleId = bundle.id as string;
    const temporaryAdditionalItem = _.get(
      additionalItemsInfo,
      [bundleId, key],
      null
    );
    return temporaryAdditionalItem;
  };

  useEffect(() => {
    if (loading === false && data) {
      setHasRequiredConsents(hasAllRequiredConsents(data.me.consents));

      const createAdditionalInfoObject = (itemsGroups: any) => {
        return _.reduce(
          itemsGroups,
          (acc, itemGroup) => {
            const bundles = _.get(itemGroup, "bundles", []);
            _.map(bundles, (bundle) => {
              const bundleId = bundle.id as string;
              const customData = bundle.customData;
              if (!_.isEmpty(customData)) {
                acc = { ...acc, [bundleId]: customData };
              }
            });
            return acc;
          },
          {}
        );
      };

      const loadedAdditionalInfo = createAdditionalInfoObject(
        data?.me?.cart?.itemsGroups
      );

      setAdditionalItemsInfo(loadedAdditionalInfo);
    }
  }, [loading, data]);

  useEffect(() => {
    if (
      loading === false &&
      placeOrderData &&
      placeOrderData.placeOrder.payments[0].link
    ) {
      window.location.replace(`${placeOrderData.placeOrder.payments[0].link}`);
    }
  }, [placeOrderLoading, data, loading, placeOrderData]);

  useEffect(() => {
    const paymentOption = _.get(
      manualQueryResponse,
      `data.me.cart.paymentOptions.${paymentIndex}.id`
    );
    if (paymentOption) {
      const input: PlaceOrderMutationVariables["input"] = {
        paymentOption,
      };
      if (!_.isEmpty(consents) && consents) {
        input.consents = consents as OrderPlacementConsentsInput;
      }
      if (paymentActionStarted) {
        console.log("Payment action start");
        setTimeout(() => {
          // console.log("Place order id", input);
          placeOrder({ variables: { input } });
          setPaymentActionStarted(false);
        }, 500);
      }
    }
  }, [
    manualQueryResponse,
    paymentIndex,
    placeOrder,
    consents,
    paymentActionStarted,
  ]);

  const addMembershipAction = async (
    accountId: string,
    type: MembershipType,
    childId?: string
  ) => {
    await addMembership({
      variables: { accountId, childId, input: { type, start: "", end: "" } },
    });
  };

  const removeFromCart = async (id: string, bundle: any) => {
    await removeCartItem({ variables: { itemId: bundle.id } });
  };

  const PickedActivitiesSummary = (
    payment: PaymentTypeInMeQuery,
    isEditable = false
  ) => {
    const [
      membershipsBundles,
      activitiesBundles,
      winterCampBundles,
      summerCampBundles,
    ] = _.reduce(
      payment.bundles,
      (accumulator, bundle) => {
        if (bundle.__typename === "CartItemMembership")
          accumulator[0].push(bundle);
        if (bundle.__typename === "CartItemActivityBundle")
          accumulator[1].push(bundle);
        if (bundle.__typename === "CartItemWinterCamp")
          accumulator[2].push(bundle);
        if (bundle.__typename === "CartItemSummerCamp")
          accumulator[3].push(bundle);
        return accumulator;
      },
      [[], [], [], []] as [any[], any[], any[], any]
    );

    const MembershipPart = () => (
      <>
        <Box mb={3}>
          <Text as="b">Członkostwo</Text>
        </Box>
        {_.map(membershipsBundles, (bundle: any, idx) => (
          <Box key={idx}>
            <OrderItemMembershipRow
              type={bundle.membershipType as MembershipType}
              price={bundle.price.value}
              name={dynamicName(bundle)}
            />
          </Box>
        ))}
      </>
    );

    const ActivitiesPart = () => (
      <>
        <Box mb={3}>
          <Text as="b">Wybrane zajęcia</Text>
        </Box>
        {_.map(activitiesBundles, (bundle: any, idx) => {
          const bundleId = getGenericBundleId(bundle);
          const setInfoState = (key: string, value: any) => {
            setAdditionalItemsInfo({
              ...additionalItemsInfo,
              [bundleId]: {
                ...additionalItemsInfo[bundleId],
                [key]: value,
              },
            });
          };
          const getAdditionalInfo = (key: string) =>
            _.get(additionalItemsInfo, [bundleId, key], null);

          return (
            <Box key={idx}>
              <OrderItemActivityRow
                agreementName={`${bundle.sport} ${bundle.location}`}
                agreementUrl={activitiesTermsURL}
                sport={bundle.sport}
                type={bundle.type}
                price={_.get(bundle, "price.value", null)}
                location={bundle.location}
                name={dynamicName(bundle)}
                bundle={bundle} //fcuk
                setItemConsent={(consent) =>
                  setInfoState("itemConsent", consent)
                }
                itemConsent={getAdditionalInfo("itemConsent")}
                isEditable={isEditable}
              />
            </Box>
          );
        })}
      </>
    );

    const WinterCampsPart = () => (
      <>
        <Box mb={3}>
          <Text as="b">Wybrane wyjazdy zimowe</Text>
        </Box>
        {_.map(winterCampBundles, (bundle: any, idx) => {
          const bundleId = bundle.id as string;
          const discount = undefined;
          // const discount = _.find(discounts, ({ item }) => item === bundleId);
          const setInfoState = setInfoStateForBundle(bundleId);
          const getAdditionalInfoByKey =
            getAdditionalInfoByKeyForBundle(bundle);

          return (
            <Box key={idx}>
              <OrderItemWinterCampRow
                agreementUrl={tripWinter2022TermsURL}
                setCampConsent={setInfoState("campConsent")}
                campConsent={getAdditionalInfoByKey("campConsent")}
                setChurchConsent={setInfoState("churchConsent")}
                churchConsent={getAdditionalInfoByKey("churchConsent")}
                setInsuranceConsent={setInfoState("insuranceConsent")}
                insuranceConsent={getAdditionalInfoByKey("insuranceConsent")}
                setShirtSize={setInfoState("shirtSize")}
                shirtSize={getAdditionalInfoByKey("shirtSize")}
                isEditable={isEditable}
                discount={discount}
                removeFromCart={(id: string) => removeFromCart(id, bundle)}
                id={isEditable ? bundle.id : null}
                sport={bundle.sport}
                level={getWinterLevelName(bundle.level)}
                location="Zgrupowanie SKI & SNB 2023 "
                price={_.get(bundle, "price.value", null)}
                name={dynamicName(bundle)}
                periodName={bundle.name || "błąd?"}
              />
            </Box>
          );
        })}
        <WinterCampDisclaimer />
      </>
    );

    const SummerCampsPart = () => (
      <>
        <Box mb={3}>
          <Text as="b">Wybrane wyjazdy letnie</Text>
        </Box>
        {_.map(summerCampBundles, (bundle: any, idx) => {
          const bundleId = bundle.id as string;
          const discount = undefined;
          const setInfoState = setInfoStateForBundle(bundleId);
          const getAdditionalInfoByKey =
            getAdditionalInfoByKeyForBundle(bundle);
          const isOutOfStock = _.some(productsOutOfStock, (item) => {
            const outOfStockSKU = _.get(item, "product.sku");
            return outOfStockSKU === bundle.sku;
          });

          const clothKey =
            bundle.profile === "Adventure" ? "hoodieSize" : "shirtSize";
          return (
            <Box key={idx}>
              <OrderItemSummerCampRow
                isOutOfStock={isOutOfStock}
                agreementUrl={tripSummer2023TermsURL}
                isEditable={isEditable}
                discount={discount}
                id={isEditable ? bundle.id : null}
                sport={"Camp 2024"}
                level={bundle.profile}
                location="Star-Dadaj "
                price={_.get(bundle, "price.value", null)}
                name={dynamicName(bundle)}
                periodName={bundle.name || "błąd?"}
                removeFromCart={(id: string) => removeFromCart(id, bundle)}
                //
                setCampConsent={setInfoState("campConsent")}
                campConsent={getAdditionalInfoByKey("campConsent")}
                //
                setInsuranceConsent={setInfoState("insuranceConsent")}
                insuranceConsent={getAdditionalInfoByKey("insuranceConsent")}
                //
                setChurchConsent={setInfoState("churchConsent")}
                churchConsent={getAdditionalInfoByKey("churchConsent")}
                //
                setShirtSize={setInfoState(clothKey)}
                shirtSize={getAdditionalInfoByKey(clothKey)}
                //
                setDiet={setInfoState("diet")}
                diet={getAdditionalInfoByKey("diet")}
                //
                setRoommatesInfo={setInfoState("roommatesInfo")}
                roommatesInfo={getAdditionalInfoByKey("roommatesInfo")}
                //
                setHealthInfo={setInfoState("healthInfo")}
                healthInfo={getAdditionalInfoByKey("healthInfo")}
                //
                setTransport={setInfoState("transport")}
                transport={getAdditionalInfoByKey("transport")}
              />
            </Box>
          );
        })}
      </>
    );

    const DiscountsPart = () => (
      <>
        <Box mb={3}>
          <Text as="b">Zniżki</Text>
        </Box>
        {_.map(discounts, (discount, idx) => (
          <Box key={idx}>
            <OrderItemDiscountRow
              price={discount.discountValue.value}
              name={discount.message}
              type={
                discount.discountValue.__typename === "DiscountPercentage"
                  ? "PERCENTAGE"
                  : "VALUE"
              }
            />
          </Box>
        ))}
      </>
    );

    const shouldRenderMembershipsPart =
      !isEditable && _.size(membershipsBundles) > 0;
    const shouldRenderActivitesPart = _.size(activitiesBundles) > 0;
    const shouldRenderWinterCampsPart = _.size(winterCampBundles) > 0;
    const shouldRenderSummerCampsPart = _.size(summerCampBundles) > 0;
    const shouldRenderDiscounts = !isEditable && _.size(discounts) > 0;
    return (
      <>
        {shouldRenderMembershipsPart && <MembershipPart />}
        {shouldRenderMembershipsPart && <Divider mb={3} />}

        {shouldRenderActivitesPart && <ActivitiesPart />}
        {shouldRenderActivitesPart && <Divider mb={3} />}

        {shouldRenderWinterCampsPart && <WinterCampsPart />}
        {shouldRenderWinterCampsPart && <Divider mb={3} />}

        {shouldRenderSummerCampsPart && <SummerCampsPart />}
        {shouldRenderSummerCampsPart && <Divider mb={3} />}

        {shouldRenderDiscounts && <DiscountsPart />}
        {shouldRenderDiscounts && <Divider mb={3} />}
      </>
    );
  };

  const PaymentAccordion = (paymentOption: any) => {
    const winterIdsInCart = getWinterCampsIDsFromItemGroups(
      itemGroups as CartItemGroup[]
    );

    const summerIdsInCart = getSummerCampsIDsFromItemGroups(
      itemGroups as CartItemGroup[]
    );

    const campsIds = [...winterIdsInCart, ...summerIdsInCart];

    const missingTransportsForTrips = !checkIfEverybodyHasTransport(
      additionalItemsInfo,
      summerIdsInCart
    );
    // const missingShirtSizesForTrips = !checkIfEverybodyHasShirtSize(
    //   additionalItemsInfo,
    //   campsIds
    // );
    const missingInsuranceConsentsForTrips =
      !checkIfEverybodyHasInsuranceConsent(additionalItemsInfo, campsIds);
    const missingCampConsentsForTrips = !checkIfEverybodyHasCampConsent(
      additionalItemsInfo,
      campsIds
    );
    const missingChurchConsentsForTrips = !checkIfEverybodyHasChurchConsent(
      additionalItemsInfo,
      campsIds
    );

    const activitiesIdsInCart = getActivitiesIDsFromItemGroups(
      itemGroups as CartItemGroup[]
    );
    const missingItemConsentsForActivities =
      !checkIfEverybodyHasActivityConsent(
        additionalItemsInfo,
        activitiesIdsInCart
      );

    const disableBuyButtonReasons = _.compact([
      missingItemConsentsForActivities
        ? "Niektórzy uczestnicy zajęć nie mają potwierdzenia regulaminów zajęć."
        : null,
      missingCampConsentsForTrips
        ? "Niektórzy uczestnicy wyjazdów nie mają potwierdzenia regulaminów wyjazdów."
        : null,
      // missingShirtSizesForTrips
      //   ? "Niektórzy uczestnicy wyjazdów nie mają określonego rozmiaru koszulki."
      //   : null,
      missingInsuranceConsentsForTrips
        ? "Niektórzy uczestnicy wyjazdów nie mają potwierdzenia otrzymania OWU NNW."
        : null,
      missingTransportsForTrips
        ? "Niektórzy uczestnicy wyjazdów nie mają określonego sposobu transportu."
        : null,
      missingChurchConsentsForTrips
        ? "Niektórzy uczestnicy wyjazdów nie mają określonej decyzji dot. udziału w mszy."
        : null,
      expiredMembershipProblems
        ? "Niektórym uczestnikom wygasł status członkostwa."
        : null,
      missingAccountDataProblems ? "Brakuje danych opiekuna." : null,
      missingChildDataProblems ? "Brakuje danych jednego z dzieci." : null,
      _.size(missingMembershipProblems) > 0
        ? `Brakuje wybranego statusu członkostwa u jednego z uczestników`
        : null,
      _.size(productsOutOfStock) > 0
        ? "Brak miejsc na niektórych wyjazdach w koszyku"
        : null,
      !hasRequiredConsents
        ? "Brakuje wymaganych zgód i potwierdzenia znajomości regulaminów."
        : null,
    ]);
    const disableBuyButton = !_.isEmpty(disableBuyButtonReasons);

    return (
      <>
        <Accordion mt={2} defaultIndex={0}>
          {_.map(paymentOption.payments, (payment, index: number) => {
            const label = payment.name;
            return (
              <AccordionItem key={index}>
                <AccordionButton _expanded={{ bg: "#F0F0F0" }}>
                  <Box flex="1" textAlign="left">
                    <Text as="b" mb={5} size="xxl">
                      {label}
                    </Text>{" "}
                    <Badge mx={isBigScreen ? 5 : 0} px={0}>
                      {payment.dueDate}
                    </Badge>
                    {paymentOption.payments.length > 1 && index === 0 ? (
                      <Badge colorScheme="purple">Aktywna rata</Badge>
                    ) : null}
                  </Box>
                  {paymentOption.payments.length > 1 && <AccordionIcon />}
                </AccordionButton>

                <AccordionPanel pb={4} border={"1px solid #F0F0F0"}>
                  {PickedActivitiesSummary(payment)}
                  {paymentOption.payments.length > 1 && (
                    <>
                      <Row>
                        <Col breakPoint={{ sm: 10, md: 10 }}>
                          <Text as="b">
                            Kwota raty {index + 1} z{" "}
                            {paymentOption.payments.length}
                          </Text>
                        </Col>
                        <Col
                          breakPoint={{ sm: 10, md: 2 }}
                          style={{ textAlign: "end" }}
                        >
                          <Text as="b">{payment.price.value} zł</Text>
                        </Col>
                      </Row>

                      <Divider my={3} />
                    </>
                  )}
                  {paymentOption.payments.length === 1 && (
                    <>
                      <Flex>
                        <Box mb={3}>
                          <Text as="b">Kwota do zapłaty</Text>
                        </Box>
                        <Spacer />
                        <Text as="b">{paymentOption.price.value} zł</Text>
                      </Flex>
                      <Divider mb={3} />
                    </>
                  )}
                  <Button
                    isLoading={placeOrderLoading}
                    mt={2}
                    p={2}
                    colorScheme="blue"
                    style={{
                      whiteSpace: "break-spaces",
                      height: "fit-PaymentTabs",
                    }}
                    isDisabled={index !== 0 || disableBuyButton} // FIXMEEEEEE index
                    width={"100%"}
                    onClick={() => {
                      setPaymentActionStarted(true);
                      fetchPayment();
                    }}
                  >
                    Potwierdzam i kupuje z obowiązkiem zapłaty
                  </Button>

                  {disableBuyButton ? (
                    <>
                      <Center fontSize="0.7rem" fontStyle="italic">
                        Powyższy przycisk jest zablokowany, ponieważ:
                      </Center>
                      <Center fontSize="0.7rem" fontStyle="italic">
                        <List>
                          {_.map(disableBuyButtonReasons, (reason) => (
                            <ListItem fontSize={"0.7rem"} key={reason}>
                              - {reason}
                            </ListItem>
                          ))}
                        </List>
                      </Center>
                    </>
                  ) : null}
                </AccordionPanel>
              </AccordionItem>
            );
          })}
        </Accordion>
      </>
    );
  };

  const PaymentTabs = () => (
    <Tabs>
      <TabList>
        {_.map(paymentOptions, (paymentOption, idx) => (
          <Tab key={idx} onClick={() => setPaymentIndex(idx)}>
            {paymentOption.name}
          </Tab>
        ))}
      </TabList>
      <TabPanels>
        {_.map(paymentOptions, (paymentOption, idx) => {
          return (
            <TabPanel key={idx} p={2}>
              {PaymentAccordion(paymentOption)}
            </TabPanel>
          );
        })}
      </TabPanels>
    </Tabs>
  );

  const PaymentCard = () => (
    <Card>
      <CardBody>
        <Heading
          fontSize={isBigScreen ? "xl" : "lg"}
          padding={0}
          color={"gray.700"}
          mb={3}
        >
          Podsumowanie
        </Heading>
        {loading && <Loading />}
        {error && <GenericErrorAlert />}
        {cart && PaymentTabs()}
      </CardBody>
    </Card>
  );

  const AgreementCard = () => {
    if (loading || error || _.isEmpty(data)) {
      return (
        <Card>
          <CardBody>
            {loading && <Loading />}
            {error && <GenericErrorAlert />}
          </CardBody>
        </Card>
      );
    } else if (data) {
      return (
        <Card>
          <CardBody>
            <Heading
              fontSize={isBigScreen ? "xl" : "lg"}
              padding={0}
              color={"gray.700"}
              mb={3}
            >
              Zgody i regulaminy
            </Heading>
            <Agreements
              requiredConsents={requiredConsents}
              setHasRequiredConsents={setHasRequiredConsents}
              setConsents={setConsents}
              consents={data.me.consents}
            />
          </CardBody>
        </Card>
      );
    }
  };

  const ClientSection = (itemGroup: any) => {
    const missingData = _.some(
      itemGroup.problems,
      ({ code }) => code === "MissingAccountData" || code === "MissingChildData"
    );
    return (
      <Flex align="center" pb={1}>
        <Box mr={3}>
          {itemGroup.participant?.name && itemGroup.participant?.surname && (
            <Text
              as="b"
              fontSize="xl"
            >{`${itemGroup.participant?.name} ${itemGroup.participant?.surname}`}</Text>
          )}
          {(!itemGroup.participant?.name ||
            !itemGroup.participant?.surname) && (
            <Text as="i">Brak danych uczesnitka</Text>
          )}
        </Box>
        {missingData &&
          TooltipWrapper({ label: missingAccountData, isError: true })}

        <Button
          ml={3}
          size="xs"
          onClick={() => {
            navigate(
              itemGroup.participant.__typename === "Account"
                ? "/profile/parent"
                : "/profile/children"
            );
          }}
        >
          Edytuj
        </Button>
      </Flex>
    );
  };

  const SingleParticipantCard = (itemGroup: any) => {
    const currentMembershipType = _.get(
      itemGroup,
      "participant.membership.membershipType"
    );
    const hasMembership = !!currentMembershipType;
    const missingAnyMembership = _.some(
      _.get(itemGroup, "problems", []),
      (problem) => {
        const isMissingProduct = problem.code === "MissingProductRequirement";
        const propositionIsMembership = _.some(
          problem.availableProducts,
          ({ name }) => _.startsWith(name, "Członkostwo")
        );
        return isMissingProduct && propositionIsMembership;
      }
    );

    const membershipTypeInCart = _.get(
      _.find(
        itemGroup.bundles,
        ({ __typename }) => __typename === "CartItemMembership"
      ),
      "membershipType"
    );

    const hasMembershipInCart = !!membershipTypeInCart;

    const onSelectMembership = (newType: MembershipType) => {
      if (
        !!meId &&
        (!missingAnyMembership || newType !== membershipTypeInCart)
      ) {
        addMembershipAction(
          meId,
          newType,
          itemGroup.participant?.__typename === "Child"
            ? itemGroup.participant?.id
            : undefined
        );
      }
    };
    return (
      <>
        {ClientSection(itemGroup)}
        <Divider my={2} />
        {MembershipSelectSection({
          isOpen: isMembershipModalOpen,
          isLoading: addMembershipLoading,
          setIsOpen: setIsMembershipModalOpen,
          hasMembership: hasMembership,
          hasMembershipInCart,
          membershipType: currentMembershipType,
          membershipTypeInCart,
          onSelectMembership,
        })}
        <Divider my={2} />
        {PickedActivitiesSummary(itemGroup, true)}
        <Flex>
          <Box mb={3}>
            <Text as="b">Cena</Text>
          </Box>
          <Spacer />
          <Text as="b">
            {_.ceil(_.sumBy(itemGroup.bundles, "price.value"), 2)} zł
          </Text>
        </Flex>
      </>
    );
  };

  const cartIsEmpty = _.isEmpty(cart) || _.isEmpty(data?.me?.cart?.itemsGroups);
  const { on: showDiscounts } = useFeature("discount-codes");
  return (
    <Layout>
      <Center>
        <Col breakPoint={{ sm: 12, lg: 12, xl: 10, xxl: 9, xxxl: 9 }}>
          <>
            {anyCartLoading || error ? (
              <Card>
                <CardBody>
                  {anyCartLoading && <Loading />}
                  {error && <GenericErrorAlert />}
                </CardBody>
              </Card>
            ) : null}
            {!loading && cartIsEmpty && <EmptyBasket />}

            {isIncomplete && <MissingProfileDataAlert parent={true} />}

            {!loading && !cartIsEmpty && (
              <>
                {_.map(data?.me?.cart?.itemsGroups, (itemGroup, index) => {
                  return (
                    <Card key={index}>
                      <CardBody>{SingleParticipantCard(itemGroup)}</CardBody>
                    </Card>
                  );
                })}
                {AgreementCard()}
                {showDiscounts && (
                  <DiscountCard currentCode={currentDiscountCode} />
                )}
                {PaymentCard()}
              </>
            )}
          </>
        </Col>
      </Center>
    </Layout>
  );
};

export default Cart;

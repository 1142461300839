import React, { Fragment, useContext, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useFeature } from "@growthbook/growthbook-react";
import icons from "@paljs/icons";
import {
  Layout,
  LayoutColumn,
  LayoutColumns,
  LayoutContainer,
  LayoutContent,
} from "@paljs/ui/Layout";
import { Menu, MenuRefObject } from "@paljs/ui/Menu";
import { Sidebar, SidebarBody, SidebarRefObject } from "@paljs/ui/Sidebar";
import _ from "lodash";
import { ThemeProvider } from "styled-components";

import config from "../../config";
import { AuthContext, AuthStatus } from "../../utils/authContext";
import Header from "../header";

import {
  generalPricingMenuLinks,
  generalShopMenuLinks,
  profileMenuLinks,
  summerOfferMenuLinks,
  summerPricingMenuLinks,
  winterOfferMenuLinks,
  winterPricingMenuLinks,
} from "./menuItems";
import SimpleLayout from "./SimpleLayout";
import themes from "./themes";

export const winterMenuItems = [];

type LayoutProps = {
  children: any;
  noPadding?: boolean;
};
const LayoutPage: React.FC<LayoutProps> = ({ children, noPadding }) => {
  const auth = useContext(AuthContext);

  const sidebarRef = useRef<SidebarRefObject>(null);
  // const router = useRouter()
  const menuRef = useRef<MenuRefObject>(null);
  const [, setSeeHeader] = useState(true);
  const location = useLocation();

  const getStateMenuItem = (
    state?: "hidden" | "visible" | "compacted" | "expanded"
  ) => {
    setSeeHeader(state !== "compacted");
  };

  const useAuthLayout = auth.authStatus !== AuthStatus.SignedIn;

  const paddingClass = noPadding ? "no-padding" : "";
  const LayoutAny = Layout as any;

  const { enable_camps, show_summer_camp } = config.FEATURE_TOGGLE;

  const { on: showWinterCamp } = useFeature(enable_camps);
  const { on: showSummerCamp } = useFeature(show_summer_camp);
  const menuItems = _.concat(
    generalShopMenuLinks,
    showWinterCamp ? winterOfferMenuLinks : [],
    showSummerCamp ? summerOfferMenuLinks : [],
    profileMenuLinks,
    generalPricingMenuLinks,
    showWinterCamp ? winterPricingMenuLinks : [],
    showSummerCamp ? summerPricingMenuLinks : []
  );

  return (
    <Fragment>
      <ThemeProvider theme={themes()}>
        <Fragment>
          <SimpleLayout />

          <LayoutAny
            evaIcons={icons}
            dir={"ltr"}
            className={!useAuthLayout ? "auth-layout" : ""}
          >
            {!useAuthLayout && (
              <Header toggleSidebar={() => sidebarRef.current?.toggle()} />
            )}
            <LayoutContainer>
              {!useAuthLayout && (
                <Sidebar
                  getState={getStateMenuItem}
                  ref={sidebarRef}
                  property="start"
                  containerFixed
                  responsive
                  className="menu-sidebar"
                >
                  <SidebarBody>
                    <Menu
                      className="sidebar-menu"
                      Link={Link}
                      ref={menuRef}
                      items={menuItems}
                      currentPath={location.pathname}
                      toggleSidebar={() => sidebarRef.current?.hide()}
                    />
                  </SidebarBody>
                </Sidebar>
              )}
              <LayoutContent>
                <LayoutColumns>
                  <LayoutColumn className={`main-content ${paddingClass}`}>
                    {children}
                  </LayoutColumn>
                </LayoutColumns>
              </LayoutContent>
            </LayoutContainer>
          </LayoutAny>
        </Fragment>
      </ThemeProvider>
    </Fragment>
  );
};

export default LayoutPage;

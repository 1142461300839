import React from "react";
import { Image } from "@chakra-ui/react";

const sportteamLogo = "/images/logo.png";

const Logo = () => (
  <Image src={sportteamLogo} alt="sportteam logo" width={250} />
);

export default Logo;

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Divider,
  Text,
  useToast,
  UseToastOptions,
} from "@chakra-ui/react";
import { useMutation, useQuery } from "graphql-hooks";
import _ from "lodash";

import { GenericErrorAlert } from "../../components/alerts";
import Loading from "../../components/loading";
import { Col, Row } from "../../components/paljs";
import {
  selectPeriodToSeeThePrice,
  TooltipWrapper,
} from "../../components/tooltip";
import {
  ADD_CHILD,
  PLACE_ORDER,
  REMOVE_CHILD,
  UPDATE_CHILD,
} from "../../data/mutations";
import {
  GetSummerCamps2023FromChildQuery,
  MutationCartAddItemArgs,
} from "../../types";
import { readableSeatsLeft } from "../../utils/helpers";

import {
  ADD_SUMMER_CAMPS_2023_TO_CART,
  GET_SUMMER_CAMPS_2023_FROM_CHILD,
} from "./data";
import { errorToastContent, SummerSelect, toastContent } from "./subcomponents";

const nullAsString = null as unknown as string;

const SummerForm = () => {
  const navigate = useNavigate();
  const toast = useToast();

  const [participantValue, setParticipantValue] =
    useState<string>(nullAsString);
  const [periodValue, setPeriodValue] = useState<string>(nullAsString);
  const [profileValue, setProfileValue] = useState<string>(nullAsString);
  const canAddToBasket = periodValue && participantValue && profileValue;

  const { loading, data, error } = useQuery<GetSummerCamps2023FromChildQuery>(
    GET_SUMMER_CAMPS_2023_FROM_CHILD,
    {
      refetchAfterMutations: [
        UPDATE_CHILD,
        ADD_CHILD,
        REMOVE_CHILD,
        PLACE_ORDER,
      ],
      useCache: false,
    }
  );

  const [addSummerCampToCart, afterCardItemAdd] =
    useMutation<MutationCartAddItemArgs>(ADD_SUMMER_CAMPS_2023_TO_CART);

  const outOfStockError =
    _.get(afterCardItemAdd, "error.graphQLErrors.0.extensions.code") ===
    "BAD_USER_INPUT";

  useEffect(() => {
    if (outOfStockError) {
      const toastprops = errorToastContent() as UseToastOptions;
      toast(toastprops);
    }
  }, [outOfStockError, toast, navigate]);

  const selectedChild = _.find(
    data?.me.children,
    (child) => child.id === participantValue
  );

  const profiles = [] as any;
  if (!_.isEmpty(selectedChild?.adventure)) {
    profiles.push({ value: "adventure", label: "Adventure" });
  }
  if (!_.isEmpty(selectedChild?.funActive)) {
    profiles.push({ value: "funActive", label: "Fun Active" });
  }
  if (!_.isEmpty(selectedChild?.sailing)) {
    profiles.push({ value: "sailing", label: "Camp żeglarski" });
  }

  const possiblePeriods = _.get(selectedChild, profileValue);
  const possiblePeriodsOptions = _.map(possiblePeriods, (item) => {
    const seatsLeft = _.get(item, "stock.available", 0);
    return {
      value: item.sku,
      label: `${item.name} - ${readableSeatsLeft(seatsLeft)}`,
    };
  });

  const getCurrentPrice = () => {
    if (periodValue) {
      const selectedPeriod = _.find(
        possiblePeriods,
        ({ sku }) => sku === periodValue
      );
      if (selectedPeriod) {
        return _.get(selectedPeriod, "price.value", "??");
      }
    }
    return TooltipWrapper({ label: selectPeriodToSeeThePrice });
  };

  const onSelectParticipant = (id: string) => {
    if (id === "addNew") {
      navigate("/profile/children");
    } else {
      setParticipantValue(id);
      setProfileValue(nullAsString);
      setPeriodValue(nullAsString);
    }
  };

  const queryChildren = _.get(data, "me.children", []);

  const participantsOptions = [
    ..._.map(queryChildren, (child) => ({
      value: child.id,
      label: `${child.name} ${child.surname}`,
    })),
    {
      value: "addNew",
      label: ">> Dodaj nowego uczestnika << (przejdź do profili)",
    },
  ];

  const clearAllFields = () => {
    setProfileValue(nullAsString);
    setPeriodValue(nullAsString);
    setParticipantValue(nullAsString);
  };

  const handleAddToCart = () => {
    clearAllFields();
    addSummerCampToCart({
      variables: {
        sku: periodValue,
        childId: participantValue,
      },
      onSuccess: () => {
        const toastprops = toastContent(navigate) as UseToastOptions;
        toast(toastprops);
      },
    });
  };

  const anyLoading = loading || afterCardItemAdd.loading;
  const FormContent = () => {
    const colBreakPoint = { xs: 12, sm: 6 };

    return (
      <>
        <SummerSelect
          header="Dane uczestnika"
          placeholder="Wybierz uczestnika"
          isDisabled={false}
          value={participantValue}
          setValue={onSelectParticipant}
          options={participantsOptions}
        />
        <Divider my={5} />

        <Row>
          <Col breakPoint={colBreakPoint}>
            <SummerSelect
              header="Profil uczestnika"
              placeholder={!profileValue ? "Wybierz profil" : ""}
              isDisabled={!participantValue}
              value={profileValue}
              setValue={setProfileValue}
              options={profiles}
            />
          </Col>
          <Col breakPoint={colBreakPoint}>
            <SummerSelect
              header="Turnus"
              placeholder={
                !participantValue
                  ? "Najpierw wybierz uczestnika"
                  : _.isEmpty(possiblePeriodsOptions)
                  ? "Brak miejsc"
                  : "Wybierz turnus"
              }
              isDisabled={
                !participantValue || _.isEmpty(possiblePeriodsOptions)
              }
              value={periodValue}
              setValue={setPeriodValue}
              options={possiblePeriodsOptions}
            />
          </Col>
        </Row>

        <Divider my={5} />

        <Box my={2}>
          <Text as="b" mr={5}>
            Cena bazowa (bez zniżek)
          </Text>
          <Text as="b">{getCurrentPrice()}</Text>
        </Box>

        <Divider my={5} />
        <Button
          isLoading={anyLoading}
          colorScheme="gray"
          onClick={handleAddToCart}
          isDisabled={!canAddToBasket}
        >
          Dodaj zapis do koszyka
        </Button>
      </>
    );
  };
  return (
    <>
      {anyLoading && <Loading />}
      {!loading && error && <GenericErrorAlert />}
      {<FormContent />}
    </>
  );
};

export default SummerForm;

import _ from "lodash";
import { validatePolish } from "validate-polish";

import { type CartItemGroup } from "../types/graphql";

export const isParentStepDone = (order: any) => {
  const hasPesel = !!order.pesel;
  const hasAddressCity = !!order.address_city;
  const hasAddressCode = !!order.address_code;
  const hasAddressStreet = !!order.address_street;

  const hasValuesFilled =
    hasPesel && hasAddressCity && hasAddressCode && hasAddressStreet;
  return hasValuesFilled;
};

export const isStartStepDone = (order: any) => {
  return order.isStartStepDone;
};
export const isChildStepDone = (order: any) => {
  return order.isChildStepDone;
};
export const isPaymentStepDone = (order: any) => {
  return order.isPaymentStepDone;
};
export const isActivityStepDone = (order: any) => {
  return order.isActivityStepDone;
};
export const isAgreementsStepDone = (order: any) => {
  return order.isAgreementsStepDone;
};

export function validatePesel(pesel: number | string): boolean {
  return validatePolish.pesel((pesel || "").toString());
}

export function validatePostalCode(postalCode: string) {
  if (_.isEmpty(postalCode)) {
    return false;
  } else if (postalCode.match(/^\d\d-\d\d\d$/) != null) {
    return true;
  } else {
    return false;
  }
}

export function getPostalCodeFromInput(inputValue: string) {
  let newAddress_code = "";
  if (inputValue.length < 3) {
    newAddress_code = inputValue;
  }
  if (inputValue.length > 2) {
    newAddress_code = inputValue.replace("-", "");
    newAddress_code =
      newAddress_code.substring(0, 2) +
      "-" +
      newAddress_code.substring(2, newAddress_code.length);
  }
  return newAddress_code.substring(0, 6);
}

export function validateOnlyLetters(string: string) {
  if (_.isEmpty(string)) {
    return false;
  } else if (string.match(/^[A-Za-z żźćńółęąśŻŹĆĄŚĘŁÓŃ-]+$/) != null) {
    return true;
  } else {
    return false;
  }
}

export function validatePhoneNumber(phoneNumber: string) {
  if (_.isEmpty(phoneNumber)) {
    return false;
  } else if (
    phoneNumber.startsWith("+48") &&
    phoneNumber.match(/^[0-9+]{12}$/) != null
  ) {
    return true;
  } else if (
    phoneNumber.startsWith("48") &&
    phoneNumber.match(/^[0-9+]{11}$/) != null
  ) {
    return true;
  } else if (phoneNumber.match(/^[0-9+]{9}$/) != null) {
    return true;
  } else {
    return false;
  }
}

export function formatPhoneNumber(phoneNumber: string): string {
  let phone = phoneNumber.replace(/^\+?48/, "");
  phone = phone.replace("+", "").replace(" ", "");
  return `+48${phone}`;
}

export function getStepId(order: any) {
  if (isParentStepDone(order)) {
    return order.no_child ? "activityStep" : "childStep";
  }
  if (!order.no_child && isChildStepDone(order)) {
    return "activityStep";
  }
  if (isActivityStepDone(order)) {
    return "paymentStep";
  }

  return "parentStep";
}

export function getEarliestUnfinishedStepId(order: any) {
  const stepId = getStepId(order);
  return stepId;
}

interface SelectOption {
  value: string;
  label: string;
}
export function makeSelectOptions(): SelectOption[] {
  return [
    {
      value: "makeSelectOptions",
      label: "makeSelectOptions",
    },
  ];
}

export function decodePesel(pesel: string): Date {
  let rok = parseInt(pesel.substring(0, 2), 10);
  let miesiac = parseInt(pesel.substring(2, 4), 10) - 1;
  const dzien = parseInt(pesel.substring(4, 6), 10);

  if (miesiac >= 80) {
    rok += 1800;
    miesiac = miesiac - 80;
  } else if (miesiac >= 60) {
    rok += 2200;
    miesiac = miesiac - 60;
  } else if (miesiac >= 40) {
    rok += 2100;
    miesiac = miesiac - 40;
  } else if (miesiac >= 20) {
    rok += 2000;
    miesiac = miesiac - 20;
  } else {
    rok += 1900;
  }

  const dataUrodzenia = new Date();
  dataUrodzenia.setFullYear(rok, miesiac, dzien);

  return dataUrodzenia;
}

export const getGenericBundleId = (bundle: any): string =>
  `${bundle.participant.id}_${bundle.sport}_${bundle.type}_${bundle.location}`;

export const getActivitiesIDsFromItemGroups = (
  itemGroups?: CartItemGroup[]
): string[] => {
  const bundles = _.flatten(_.map(itemGroups, "bundles"));
  const items = _.filter(bundles, { __typename: "CartItemActivityBundle" });
  const ids = _.map(items, getGenericBundleId);
  return ids;
};

export const getWinterCampsIDsFromItemGroups = (
  itemGroups?: CartItemGroup[]
): string[] => {
  const bundles = _.flatten(_.map(itemGroups, "bundles"));
  const items = _.filter(bundles, { __typename: "CartItemWinterCamp" });
  const ids = _.map(items, "id");
  return ids;
};

export const getSummerCampsIDsFromItemGroups = (
  itemGroups?: CartItemGroup[]
): string[] => {
  const bundles = _.flatten(_.map(itemGroups, "bundles"));
  const items = _.filter(bundles, { __typename: "CartItemSummerCamp" });
  const ids = _.map(items, "id");
  return ids;
};

export type AdditionalInfoForID = Record<
  string,
  {
    shirtSize?: string;
    churchConsent?: boolean;
    insuranceConsent?: boolean;
  }
>;
export const checkIfEverybodyHasProperty = (
  additionalInfoObject: AdditionalInfoForID,
  idsOfInterest: string[],
  property: string,
  allowFalse: boolean
) => {
  const everybodyHasProperty = _.every(idsOfInterest, (id) => {
    const item = additionalInfoObject[id];
    const propertyValue = _.get(item, property, false);
    const isValueValid = allowFalse ? true : !!propertyValue;
    return _.has(item, property) && isValueValid;
  });
  return everybodyHasProperty;
};

export const checkIfEverybodyHasActivityConsent = (
  additionalInfoObject: AdditionalInfoForID,
  activityIdsInCart: string[]
) => {
  return checkIfEverybodyHasProperty(
    additionalInfoObject,
    activityIdsInCart,
    "itemConsent",
    false
  );
};

export const checkIfEverybodyHasShirtSize = (
  additionalInfoObject: AdditionalInfoForID,
  winterIdsInCart: string[]
) => {
  return checkIfEverybodyHasProperty(
    additionalInfoObject,
    winterIdsInCart,
    "shirtSize",
    false
  );
};

export const checkIfEverybodyHasTransport = (
  additionalInfoObject: AdditionalInfoForID,
  summerIdsInCart: string[]
) => {
  return checkIfEverybodyHasProperty(
    additionalInfoObject,
    summerIdsInCart,
    "transport",
    false
  );
};

export const checkIfEverybodyHasInsuranceConsent = (
  additionalInfoObject: AdditionalInfoForID,
  winterIdsInCart: string[]
) => {
  return checkIfEverybodyHasProperty(
    additionalInfoObject,
    winterIdsInCart,
    "insuranceConsent",
    false
  );
};

export const checkIfEverybodyHasCampConsent = (
  additionalInfoObject: AdditionalInfoForID,
  winterIdsInCart: string[]
) => {
  return checkIfEverybodyHasProperty(
    additionalInfoObject,
    winterIdsInCart,
    "campConsent",
    false
  );
};

export const checkIfEverybodyHasChurchConsent = (
  additionalInfoObject: AdditionalInfoForID,
  winterIdsInCart: string[]
) => {
  return checkIfEverybodyHasProperty(
    additionalInfoObject,
    winterIdsInCart,
    "churchConsent",
    true
  );
};

export const transformAdditionalInfoObjectForMutation = (
  additionalInfoObject: AdditionalInfoForID
) => {
  return _.map(additionalInfoObject, (properties, cartItemId) => ({
    ...properties,
    cartItemId,
  }));
};

export const readableSeatsLeft = (number: Number) => {
  if (number === 0) {
    return "Brak miejsc";
  } else if (number > 30) {
    return "Miejsca dostepne";
  } else {
    return `${number} miejsc dostepnych`;
  }
};

export const splitTurnusName = (product: any) => {
  const [label, dates] = _.split(product.name, " (");
  return [
    label,
    _.replace(dates, ")", ""),
    readableSeatsLeft(_.get(product, "stock.available", 0)),
  ];
};

export const getDynamicName = (isBigScreen: boolean) => (bundle: any) =>
  bundle.participant
    ? `${
        isBigScreen
          ? bundle.participant.name
          : `${bundle.participant.name ? bundle.participant.name[0] : ""}.`
      } ${bundle.participant.surname ? bundle.participant.surname : ""}`
    : undefined;

const levelOptions = [
  { label: "Początkujący", value: "1" },
  { label: "Średniozaawansowany", value: "2" },
  { label: "Zaawansowany", value: "3" },
];

export const getWinterLevelName = (level: string): string => {
  const option = _.find(levelOptions, (option) => option.value === level);
  return option ? option.label : "błąd";
};

import { type MenuItemType } from "@paljs/ui/types";

export const generalShopMenuLinks: MenuItemType[] = [
  {
    title: "Strona główna",
    icon: { name: "home" },
    link: { to: "/" },
  },
  {
    title: "Zapisy",
    group: true,
  },
  {
    title: "Koszyk",
    icon: { name: "shopping-cart-outline" },
    link: { to: "/cart" },
  },
  {
    title: "W trakcie opłacania",
    icon: { name: "shopping-bag-outline" },
    link: { to: "/active" },
  },
  {
    title: "Opłacone",
    icon: { name: "archive-outline" },
    link: { to: "/archive" },
  },
];

export const profileMenuLinks: MenuItemType[] = [
  {
    title: "Profil",
    group: true,
  },
  {
    title: "Opiekun",
    icon: { name: "person-outline" },
    link: { to: "/profile/parent" },
  },
  {
    title: "Dzieci",
    icon: { name: "people-outline" },
    link: { to: "/profile/children" },
  },
];

export const generalPricingMenuLinks: MenuItemType[] = [
  {
    title: "Cenniki",
    group: true,
  },
  {
    icon: { name: "file-text-outline" },
    title: "Tenis",
    link: {
      to: "/redirect#https://sportteam.pl/tenis/zapisy-i-opaty",
      target: "blank",
    },
  },
  {
    title: "Pływanie",
    icon: { name: "file-text-outline" },
    link: {
      to: "/redirect#https://sportteam.pl/plywanie/zapisy-i-opaty",
      target: "blank",
    },
  },
];

export const winterOfferMenuLinks: MenuItemType[] = [
  {
    title: "Zima 2023",
    group: true,
  },
  {
    title: "Zimowisko 2023",
    icon: { name: "star-outline" },
    link: { to: "/winter2023" },
  },
];
export const winterPricingMenuLinks: MenuItemType[] = [
  {
    title: "Zimowisko",
    icon: { name: "file-text-outline" },
    link: { to: "/redirect#pdfs/cennikZima.pdf", target: "blank" },
  },
];

export const summerOfferMenuLinks: MenuItemType[] = [
  {
    title: "Letni camp 2024",
    group: true,
  },
  {
    title: "Profil Fun & Active",
    icon: { name: "star-outline" },
    link: { to: "/summer2024/funActive" },
  },
  {
    title: "Profil Sport & Adventure",
    icon: { name: "star-outline" },
    link: { to: "/summer2024/adventure" },
  },
  {
    title: "Profil Żeglarski",
    icon: { name: "star-outline" },
    link: { to: "/summer2024/sailing" },
  },
];

export const summerPricingMenuLinks: MenuItemType[] = [
  {
    title: "Lato 2024",
    icon: { name: "file-text-outline" },
    link: {
      to: "/redirect#pdfs/cennikLato.pdf",
      target: "blank",
    },
  },
];

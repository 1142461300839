import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button } from "@chakra-ui/react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import { AuthWrapper, Password } from "../../components/auth";
import config from "../../config";
import { useValidPassword } from "../../hooks/useAuthHooks";
import { AuthContext } from "../../utils/authContext";

const bgImgUrl = config.Background.change;

export default function NewPassword() {
  const [error, setError] = useState("");
  const [reset] = useState(false);

  const {
    password: newPassword,
    setPassword: setNewPassword,
    passwordIsValid: newPasswordIsValid,
  } = useValidPassword("");

  const isValid = !newPasswordIsValid || newPassword.length === 0;

  const navigate = useNavigate();

  const authContext = useContext(AuthContext);

  const changePassword = async () => {
    try {
      await authContext.setNewPassword(newPassword);
      navigate("/");
    } catch (err: any) {
      setError(err.message);
    }
  };

  const updatePassword = (
    <>
      <Box width="80%" m={1}>
        <Password
          label="Nowe hasło"
          passwordIsValid={newPasswordIsValid}
          setPassword={setNewPassword}
        />
      </Box>
      {/* Error */}
      <Box mt={2}>
        <Typography color="error" variant="body2">
          {error}
        </Typography>
      </Box>

      {/* Buttons */}
      <Box mt={2}>
        <Grid container direction="row" justify="center">
          <Box m={1}>
            <Button onClick={() => navigate(-1)}>Wróć</Button>
          </Box>
          <Box m={1}>
            <Button
              disabled={isValid}
              colorScheme="blue"
              onClick={changePassword}
            >
              Ustal nowe hasło
            </Button>
          </Box>
        </Grid>
      </Box>
    </>
  );

  const title = reset ? `Zmieniono hasło` : "Zmień hasło";

  return (
    <AuthWrapper bgImgUrl={bgImgUrl} title={title as string}>
      {updatePassword}
    </AuthWrapper>
  );
}
